/**
 * Libs
 */
import sanitizeHtml from 'sanitize-html';

export const decodeHTMLEntities = (str = ''): string => {
    const translateRegexp = /&(nbsp|amp|quot|lt|gt);/gi;
    const translate: Record<string, string> = {
        'nbsp': ' ',
        'amp': '&',
        'quot': '"',
        'lt': '<',
        'gt': '>'
    };

    return str
        .replace(translateRegexp, (match, entity) => translate[entity.toLowerCase()])
        .replace(/&#(\d+);/gi, (match, numStr) => (
            String.fromCharCode(parseInt(numStr, 10))
        ));
};

export const transformUrls = (input = '') => {
    // eslint-disable-next-line max-len,no-useless-escape
    const regex = /((?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’])))/gm;

    return input.replace(regex, '<a href="$1" target="_blank" rel="noreferrer">$1</a>');
};

export const wipeHtml = (input = '') => sanitizeHtml(input, {
    allowedTags: [],
    disallowedTagsMode: 'discard'
});
