import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { getEuroFromCent } from 'src/utils/getEuroFromCent';

export const cartSelect = (state: RootState) => state.features.cart;

export const selectIsCartOpen = createSelector(cartSelect, (state) => state.isCartOpen);

export const selectCartItems = createSelector(cartSelect, (state) => state.cartItems);

export const selectCartTotal = createSelector(selectCartItems, (cartItems) =>
    cartItems.reduce((total, cartItem) => total + cartItem.quantity * getEuroFromCent(cartItem.art.price), 0)
);

export const selectCartCount = createSelector(selectCartItems, (cartItems) =>
    cartItems.reduce((total, cartItem) => total + Number(cartItem.quantity), 0)
);