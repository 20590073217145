import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

export const universeSelect = (state: RootState) => state.features.universe.mine;

export const selectUniverseMine = createSelector(
    universeSelect,
    (state) => state.universe
);

export const selectUniverseMineLoading = createSelector(
    universeSelect,
    (state) => state.loading || !state.loaded
);
