import { DefaultTheme, PhoenxTheme, SyndicateTheme, TechTheme, VemTheme } from 'styled-components';

export const theme: DefaultTheme = {
    name: 'default',
    colors: {
        text: '#FFFFFF',
        white: '#FFFFFF',
        textRgb: '255, 255, 255',

        red500: '#F43939',
        pink500: '#FF77FC',
        blue500: '#439EFF',
        blue600: '#1C60FA',
        orange500: '#FF9A3D',
        beige500: '#FFE0D9',
        warmBlack: '#211F1F',
        warmBlackRgb: '33, 31, 31',
        deepDarkBlue: '#05060C',
        deepDarkBlueRgb: '5, 6, 12',
        gs50: '#F9FAFB',
        gs100: '#E7E7E7',
        gs200: '#B7B7B7',
        gs300: '#595959',
        gs400: '#424242',
        gs450: '#303030',
        gs500: '#292929',
        grey600: '#575B65',
        grey900: '#161817',
        gs500Rgb: '41, 41, 41',
        gs600: '#1C1A1A',
        gs800rgb: '86, 86, 96',

        green600: '#0EA508',

        cyan50: '#F3FCFC',
        cyan500: '#25C2BD',
        cyan600: '#09A5A1',
        cyan700: '#077C79',
        cyan600rgba: 'rgba(9, 165, 161, 0.76)',
        darkThelergba: 'rgba(4, 83, 80, 0.4)',
        grey200: '#E2E7ED',
        grey300: '#A7AAB2',
        grey400: '#8A8E98',
        grey800: '#2C2E33',
        lightCyan: '#57CFCC',
        darkCyan: '#40B5B2',
        dullCyan: '#3ebdbe',
        lightGrey: '#F3F4F6',
        lightYellow: '#FFFCE6',
        darkYellow: '#AA9400',
        softYellow: '#FFF08E',
        lightRed: '#F8E8E6',
        darkRed: '#7C1306',
        softRed: '#F0CDC8',
        lightGreen: '#E7F8F8',
        darkGreen: '#077C79',
        softGreen: '#C9F0EF',
        red400: '#C23625',

        green500: '#24DA83',

        black: '#000000',

        overlay: 'rgba(13, 12, 12, 0.75)',

        placeholderGradient:
            'linear-gradient(101.63deg, #303030 15.49%, #363636 21.19%, #363636 40.8%, #303030 46.34%)',

        /* OCEAN TEMPLATE */
        blue: '#168AAD',
        softBlue: '#90E0EF',
        deepBlue: '#184E77',
        turtle: '#25A18E',
        softTurtle: '#73bfb8',
        purple: '#B5179E',
        softPurple: '#E7C6FF',
    },
    fonts: {
        text: 'DM Sans, sans-serif',
        title: 'PP Neue Machina, sans-serif',
    },
    constants: {
        headerDskHeight: 90,
        headerMbHeight: 63,
        maxZIndex: 'calc(9e999)',
    },
};

export const techTheme: TechTheme = {
    name: 'tech',
    colors: {
        text: '#FFFFFF',
        gs50: '#DFE2E1',
        gs100: '#B2B6B5',
        gs200: '#B7B7B7',
        gs300: '#595959',
        gs600: '#41444C',
        gs400: '#49504D',
        gs450: '#2F3432',
        gs500: '#292929',
        white: '#FFFFFF',
        cyan50: '#F3FCFC',
        cyan500: '#25C2BD',
        cyan600: '#09A5A1',
        cyan700: '#077C79',
        grey600: '#575B65',
        grey800: '#1D201F',
        grey900: '#161817',
        lightCyan: '#57CFCC',
        darkCyan: '#40B5B2',
        dullCyan: '#3ebdbe',
        cyan600rgba: 'rgba(9, 165, 161, 0.76)',
        darkThelergba: 'rgba(4, 83, 80, 0.4)',
        grey200: '#E2E7ED',
        grey300: '#D0D3D2',
        grey400: '#858A88',
        green600: '#0EA508',
        lightGrey: '#F5F7F6',
        lightYellow: '#FFFCE6',
        darkYellow: '#AA9400',
        softYellow: '#FFF08E',
        lightRed: '#F8E8E6',
        darkRed: '#281F12',
        softRed: '#F0CDC8',
        lightGreen: '#E7F8F8',
        darkGreen: '#077C79',
        softGreen: '#C9F0EF',
        red400: '#C23625',
        overlay: 'rgba(0, 0, 0, 0.7)',
        black: '#000000',
        warmBlack: '#161817',
    },
    fonts: {
        title: 'Acid, sans-serif',
        text: 'Manrope, sans-serif',
    },
    constants: {
        headerDskHeight: 90,
        headerMbHeight: 63,
        maxZIndex: 'calc(9e999)',
    },
};

export const vemTheme: VemTheme = {
    name: 'vem',
    colors: {
        text: '#FFFFFF',
        white: '#FFFFFF',
        textRgb: '255, 255, 255',
        red500: '#F43939',
        pink500: '#FF77FC',
        blue500: '#439EFF',
        blue600: '#1C60FA',
        orange500: '#FF9A3D',
        beige500: '#FFE0D9',
        warmBlack: '#211F1F',
        warmBlackRgb: '33, 31, 31',
        deepDarkBlue: '#05060C',
        deepDarkBlueRgb: '5, 6, 12',
        gs50: '#F9FAFB',
        gs100: '#E7E7E7',
        gs200: '#B7B7B7',
        gs300: '#595959',
        gs400: '#424242',
        gs450: '#303030',
        gs500: '#292929',
        gs500Rgb: '41, 41, 41',
        gs600: '#1C1A1A',
        gs800rgb: '86, 86, 96',
        grey800: '#2C2E33',
        grey900: '#161817',

        green600: '#0EA508',

        cyan50: '#F3FCFC',
        cyan500: '#25C2BD',
        cyan600: '#09A5A1',
        cyan700: '#077C79',
        cyan600rgba: 'rgba(9, 165, 161, 0.76)',
        darkThelergba: 'rgba(4, 83, 80, 0.4)',
        grey200: '#E2E7ED',
        grey300: '#A7AAB2',
        grey400: '#8A8E98',
        grey600: '#575B65',
        lightCyan: '#57CFCC',
        darkCyan: '#40B5B2',
        dullCyan: '#3ebdbe',
        lightGrey: '#F3F4F6',
        lightYellow: '#FFFCE6',
        darkYellow: '#AA9400',
        softYellow: '#FFF08E',
        lightRed: '#F8E8E6',
        darkRed: '#7C1306',
        softRed: '#F0CDC8',
        lightGreen: '#E7F8F8',
        darkGreen: '#077C79',
        softGreen: '#C9F0EF',
        red400: '#C23625',

        green500: '#24DA83',

        black: '#000000',

        overlay: 'rgba(13, 12, 12, 0.75)',

        placeholderGradient:
            'linear-gradient(101.63deg, #303030 15.49%, #363636 21.19%, #363636 40.8%, #303030 46.34%)',

        /* OCEAN TEMPLATE */
        blue: '#168AAD',
        softBlue: '#90E0EF',
        deepBlue: '#184E77',
        turtle: '#25A18E',
        softTurtle: '#73bfb8',
        purple: '#B5179E',
        softPurple: '#E7C6FF',
    },
    fonts: {
        text: 'Hellix, sans-serif',
        title: 'Hellix, sans-serif',
    },
    constants: {
        headerDskHeight: 90,
        headerMbHeight: 63,
        maxZIndex: 'calc(9e999)',
    },
};

export const phoenxTheme: PhoenxTheme = {
    name: 'phoenx',
    colors: {
        pink500: '#FFCF51',
        text: '#FFFFFF',
        white: '#FFFFFF',
        textRgb: '255, 255, 255',
        red500: '#F43939',
        blue500: '#439EFF',
        blue600: '#1C60FA',
        orange500: '#FF9A3D',
        beige500: '#FFE0D9',
        warmBlack: '#211F1F',
        warmBlackRgb: '33, 31, 31',
        deepDarkBlue: '#05060C',
        deepDarkBlueRgb: '5, 6, 12',
        gs50: '#F9FAFB',
        gs100: '#E7E7E7',
        gs200: '#B7B7B7',
        gs300: '#595959',
        gs400: '#424242',
        gs450: '#303030',
        gs500: '#292929',
        grey600: '#575B65',
        grey900: '#161817',
        gs500Rgb: '41, 41, 41',
        gs600: '#1C1A1A',
        gs800rgb: '86, 86, 96',
    },
    fonts: {
        text: 'DM Sans, sans-serif',
        title: 'PP Neue Machina, sans-serif',
    },
    constants: {
        headerDskHeight: 90,
        headerMbHeight: 63,
        maxZIndex: 'calc(9e999)',
    },
};

export const syndicateTheme: SyndicateTheme = {
    ...theme,
    name: 'syndicate',
    colors: {
        ...theme.colors,
        pink500: '#FFFFFF',
    },
    fonts: {
        text: 'DM Sans, sans-serif',
        title: 'PP Neue Machina, sans-serif',
    },
    constants: {
        headerDskHeight: 90,
        headerMbHeight: 63,
        maxZIndex: 'calc(9e999)',
    },
};