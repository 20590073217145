import { IConfirmUserEmail, AuthTokenCookieService } from '@tacans/shared-marketplace-layer';
import { useCallback } from 'react';
import { useAppDispatch } from 'src/hooks/app';
import { useUserLoggedIn } from '../../user/hooks/user.hooks';
import { emailConfirmThunk, setNewPasswordThunk } from '../ducks/auth/auth.thunk';

export const useEmailConfirm = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (data: IConfirmUserEmail) =>
            // @ts-ignore
            dispatch(emailConfirmThunk(data)),
        [dispatch]
    );
};

export const useAuthorized = () => {
    const loggedIn = useUserLoggedIn();
    const token = AuthTokenCookieService.getToken('access') || AuthTokenCookieService.getToken('refresh');

    return !!token && loggedIn;
};

export const useSetNewPassword = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (data) => {
            dispatch(setNewPasswordThunk(data));
        },
        [dispatch]
    );
};
