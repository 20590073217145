import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { TextInput } from 'src/ui/common/atoms/text-input';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const MobileSearchWrapper = styled(FlexBox)`
    margin: 0 5px;
`;

export const MobileInputWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.white};
    height: 240px;
    padding: 10px;
    z-index: 4;

    & svg {
        color: ${({ theme }) => theme.colors.black};
    }

    & svg:hover {
        color: ${({ theme }) => theme.colors.blue};
    }
`;

export const StyledSearchInputWrapper = styled.div`
    position: relative;
    text-align: center;
`;

export const StyledSearchIconInInput = styled.span`
    position: absolute;
    right: 30px;

    ${media.md`
        right: 17%;
        top: 30%;
  `}
`;

export const StyledSearchTitle = styled.div`
    font-size: 36px;
    text-align: center;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1em;
`;

export const StyledSearchIcon = styled(SvgIcon)<{ fixed?: boolean }>`
    position: absolute;
`;

export const StyledCloseIcon = styled(SvgIcon)`
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
`;

export const StyledSearchInput = styled(TextInput)`
    width: 100%;
    height: 40px;
    padding-left: 40px;
    padding-right: 40px;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border-radius: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.gs300};
    border-top: none;
    border-right: none;
    border-left: none;

    &:focus {
        border-color: ${({ theme }) => theme.colors.blue};
    }

    ${media.md`
        width: 70%;
        height: 50px;
        padding-left: 50px;
        padding-right: 50px;
  `}
`;

export const StyledIcon = styled(FlexBox)`
    cursor: pointer;
    width: 50px;
    height: 50px;
    transition: background-color 0.2s ease-in-out;
    margin-right: 10px;

    //icon background
    & > div {
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 0;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.blue};
        border-radius: 0;

        & svg {
            color: ${({ theme }) => theme.colors.white};
        }
    }

    & svg {
        color: ${({ theme }) => theme.colors.black};
    }
`;
