import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddressT, CommonAPI, WalletAPI } from '@tacans/shared-marketplace-layer';

export const addWalletThunk = createAsyncThunk('wallet/add-new', async (data: AddressT) => {
    try {
        const response = await WalletAPI.add(data);

        return { response };
    } catch (err: any) {
        const error = err; // cast the error for access
        if (!error.response) {
            throw err;
        }

        // We got validation errors, let's return those so we can reference in our component and set form errors
        return error.response.data;
    }
});

export const fetchWalletsThunk = createAsyncThunk('wallet/fetch', async (login: string) => {
    const response = await WalletAPI.getWallets(login);

    return response.data;
});

export const fetchBlockChainsThunk = createAsyncThunk('wallet/fetch-blockchains', async () => {
    const response = await CommonAPI.getBlockchains();

    return response.data;
});

export const fetchCoinsThunk = createAsyncThunk('wallet/fetch-coins', async () => {
    const response = await CommonAPI.getCoins();

    return { coins: response.data.coins };
});
