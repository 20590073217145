import { createAsyncThunk } from '@reduxjs/toolkit';
import { ArtAPI } from '@tacans/shared-marketplace-layer';
import { RootState } from 'src/store/store';
import { selectFilter, selectPager, selectSorting } from './explore-arts.selectors';
import { useCreateExploreArtsRequestPayload } from '../../hooks/use-create-explore-arts-request-payload';

export const fetchArtsThunk = createAsyncThunk('explore-arts/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const sort = selectSorting(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);
    const payload = useCreateExploreArtsRequestPayload({
        sort,
        filter,
        pager,
    });
    const { data } = await ArtAPI.fetchByFilter(payload);

    return data;
});
