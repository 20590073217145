import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { GetTransactionsAdapter } from './get-transactions.adapter';

export const getTransactionsSelect = (state: RootState) => state.features.transactions;

export const getTransactionsSelectors = GetTransactionsAdapter.getSelectors();

export const selectPager = createSelector(getTransactionsSelect, (state) => state.pager);

export const selectTransactionsEntities = createSelector(getTransactionsSelect, (state) =>
getTransactionsSelectors.selectEntities(state)
);

export const selectByPage = createSelector(getTransactionsSelect, (state) => state.byPage);

export const selectTotal = createSelector(getTransactionsSelect, (state) => state.total);

export const selectPages = createSelector(getTransactionsSelect, (state) => state.pages);

export const selectVisibleTotal = createSelector(getTransactionsSelect, (state) => state.visibleTotal);

export const selectLoading = createSelector(getTransactionsSelect, (state) => state.loading);