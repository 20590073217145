import { createAsyncThunk } from '@reduxjs/toolkit';
import { CollectionAPI, ICollection } from '@tacans/shared-marketplace-layer';

export const fetchCollectionsByIdsThunk = createAsyncThunk(
    'collections-list/fetch-collections-by-ids',
    async (data: ICollection['id'][]) => {
        const response = await CollectionAPI.fetchByIds(data);

        return response.data.collections;
    }
);
