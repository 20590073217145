import { useCallback } from 'react';
import { CurrencyI } from '@tacans/shared-marketplace-layer';

import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import { fetchCurrencyThunk, selectAllCurrencyEntities } from '../ducks/currency';

export const useCurrencyFetch = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(fetchCurrencyThunk());
    }, [dispatch]);
};

export const useAllCurrencyEntities = (): Record<string, CurrencyI | undefined> => {
    const entities = useAppSelector(selectAllCurrencyEntities);
    return entities;
};
