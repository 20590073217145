import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/store/store';
import { EventAPI } from '@tacans/shared-marketplace-layer';
import { useCreateFetchEventsRequestPayload } from './hooks/use-create-fetch-events-request-payload';
import { selectFilter, selectPager, selectSorting } from './calendar.selectors';


export const fetchEventsThunk = createAsyncThunk('events/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const sort = selectSorting(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);
    const payload = useCreateFetchEventsRequestPayload({
        sort,
        filter,
        pager,
    });
    const { data } = await EventAPI.fetchByFilter(payload);

    return data;
});