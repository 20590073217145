import { useCallback, useEffect, useRef } from 'react';
import { ITransaction, ITransactionStatusE } from 'src/services/transactions/types';
import transactionsRegistry from 'src/services/transactions/transactions-registry';

type Status = 'pending' | 'finished';

const AnalyticsController = () => {
    const handledSuccessTransactions = useRef<Record<string, Status>>({});

    const handleTransactions = useCallback(async (transactions: ITransaction[]) => {
        const successTransaction = transactions.find(tr => (
            ITransactionStatusE.Success === tr.status && tr.contractMethod === 'buy'
        ));

        if (successTransaction && !handledSuccessTransactions.current[successTransaction.token.id]) {
            handledSuccessTransactions.current[successTransaction.token.id] = 'pending';
            handledSuccessTransactions.current[successTransaction.token.id] = 'finished';
        }
    }, []);

    useEffect(() => {
        handleTransactions(transactionsRegistry.getAll());
        transactionsRegistry.subscribe(handleTransactions);

        return () => {
            transactionsRegistry.unsubscribe(handleTransactions);
        };
    }, [handleTransactions]);

    return null;
};

export default AnalyticsController;