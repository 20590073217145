import { createSlice } from '@reduxjs/toolkit';

import { CurrencyAdapter } from './currency.adapter';
import { fetchCurrencyThunk } from './currency.thunk';

const initialState = CurrencyAdapter.getInitialState();

export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        clearCollectible: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCurrencyThunk.fulfilled, (state, action) => {
            CurrencyAdapter.setMany(
                state,
                // @ts-ignore
                action.payload
            );
        });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = currencySlice;

export default currencySlice.reducer;
