/**
 * Libs
 */
import React, { useCallback } from 'react';
import { useHasNotifications } from 'src/features/common/notifications/hooks/notifications.hooks';

/**
 * Hooks
 */
import { useRenderNotificationsSidebar } from 'src/features/common/notifications/hooks/use-render-notifications-sidebar';
import {
    StyledHeaderNotifications,
    StyledNotificationFlag,
} from 'src/features/white-labels/ocean/app/components/header-notifications/header-notifications.styles';

/**
 * Components
 */
import { IconBox } from 'src/ui/common/atoms/icon-box';

/**
 * Styles
 */

export const HeaderNotifications = () => {
    const renderNotificationsSidebar = useRenderNotificationsSidebar();
    const hasNotifications = useHasNotifications();

    const onNotificationsClick = useCallback(() => {
        renderNotificationsSidebar();
    }, [renderNotificationsSidebar]);

    return (
        <StyledHeaderNotifications>
            <IconBox icon="bell" variant="light" onClick={onNotificationsClick} />
            {hasNotifications && <StyledNotificationFlag />}
        </StyledHeaderNotifications>
    );
};
