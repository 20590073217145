import { LaunchpadAPI, ReferralApi, UniverseAPI, UserAPI } from '@tacans/shared-marketplace-layer';
import type { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { ReactElement, ReactNode } from 'react';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { withPasswordProtect } from 'src/HOCs/next/with-password-protect';
import { RouteE } from 'src/constants/pages.constants';
import { AppLayout } from 'src/features/common/app/components/app-layout';
import Bootstrap from 'src/features/common/app/components/bootstrap';
import { actions as launchpadsActions } from 'src/features/common/explore/ducks/launchpads/launchpads.slice';
import { LaunchpadsSort } from 'src/features/common/explore/ducks/launchpads/launchpads.types';
import { useCreateLaunchpadsRequestPayload } from 'src/features/common/explore/hooks/use-create-launchpads-request-payload';
import { NotificationsContextProvider } from 'src/features/common/notifications/components/notifications-context-provider';
import { actions } from 'src/features/common/profile/ducks/profile/profile.slice';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { actions as universeActions } from 'src/features/common/universe/ducks/universe-info/universe-info.slice';
import { universeGetMineThunk } from 'src/features/common/universe/ducks/universe-mine';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { useUserLoggedIn } from 'src/features/common/user/hooks/user.hooks';
import { AppLayout as SAppLayout } from 'src/features/white-labels/nftsuperlab/app/components/app-layout';
import { AppLayout as NAppLayout } from 'src/features/white-labels/ninja/app/components/app-layout';
import { OAppLayout } from 'src/features/white-labels/ocean/app/components/app-layout';
import { AppLayout as PHAppLayout } from 'src/features/white-labels/phoenxholding/app/components/app-layout';
import { AppLayout as SYAppLayout } from 'src/features/white-labels/syndicate/app/components/app-layout';
import { AppLayout as CAppLayout } from 'src/features/white-labels/techcontemporary/app/components/app-layout';
import { AppLayout as SMAppLayout } from 'src/features/white-labels/thesportmuseum/app/components/app-layout';
import { AcceptedTermsModal } from 'src/features/white-labels/thesportmuseum/app/modals/accept-terms-modal';
import { DisclaimerModal } from 'src/features/white-labels/thesportmuseum/app/modals/disclaimer-modal';
import { AppLayout as WAppLayout } from 'src/features/white-labels/worldofartists/app/components/app-layout';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import 'src/styles/fonts.scss';
import { makeSSRApiRequest } from 'src/utils/api.utils';
import { isWebpSupported } from 'src/utils/file.utils';
import { getUrlWithPublicStorage } from 'src/utils/get-url-with-public-host';
import { IS_SSR } from 'src/utils/helpers.utils';
import { isEmptyRouterQueryString } from 'src/utils/isEmptyRouterQueryString';
import { readFromLocalStorage, removeFromLocalStorage, writeToLocalStorage } from 'src/utils/local-storage.utils';
import { wrapper } from '../store';

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const { publicRuntimeConfig } = getConfig();

const isProd = publicRuntimeConfig.NEXT_PUBLIC_ENV === 'production';

if (typeof window !== 'undefined') {
    (window as any).SWITCHERE_CONFIG = {
        urlBase: publicRuntimeConfig.NEXT_PUBLIC_SWITCHERE_HOST,
    };
    window.isWebpSupport = isWebpSupported();
}

const REFERRAL_ID = 'referralID';
const DISCLAIMER = 'disclaimer';
export const USER_PASS = 'userPass';

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
    const { isUniverse, project, projectName, baseUrl, metaTitle, metaDescription, favicon, ogImage, id } =
        useUniverse();
    const {
        isNinjaTemplate,
        isOceanTemplate,
        isNftSuperlab,
        isTechContemporary,
        isSportsMuseumTemplate,
        isWorldOfArtists,
        isSyndicate,
        isPhoenxholding,
    } = useGetUniverseTemplate();
    const router = useRouter();
    const userLoggedIn = useUserLoggedIn();
    const profile = useProfile();
    const referralLink: string | null = readFromLocalStorage(REFERRAL_ID);
    const disclaimer: string | null = readFromLocalStorage(DISCLAIMER);
    const TechMetaDescription =
        'Discover the captivating world of digital art and immerse yourself in a realm where pixels come alive, emotions are woven into every brushstroke, and where the artwork tell the stories of innovation.';

    const getLayout =
        Component.getLayout ??
        ((page) => {
            if (isNinjaTemplate) {
                return <NAppLayout>{page}</NAppLayout>;
            }
            if (isOceanTemplate) {
                return <OAppLayout>{page}</OAppLayout>;
            }
            if (isNftSuperlab) {
                return <SAppLayout>{page}</SAppLayout>;
            }
            if (isTechContemporary) {
                return <CAppLayout>{page}</CAppLayout>;
            }
            if (isWorldOfArtists) {
                return <WAppLayout>{page}</WAppLayout>;
            }
            if (isSportsMuseumTemplate) {
                return <SMAppLayout>{page}</SMAppLayout>;
            }
            if (isPhoenxholding) {
                return <PHAppLayout>{page}</PHAppLayout>;
            }
            if (isSyndicate) {
                return <SYAppLayout>{page}</SYAppLayout>;
            }

            return <AppLayout>{page}</AppLayout>;
        });

    const universeFavicon = favicon ? getUrlWithPublicStorage(favicon) : '/default-favicon.webp';
    const favIcon = isUniverse ? universeFavicon : '/favicon.svg';
    const ogImg = isUniverse ? getUrlWithPublicStorage(ogImage) : `${baseUrl}/og-image.png?v=2`;

    useEffect(() => {
        const { query } = router;
        if (!userLoggedIn && isUniverse && !isEmptyRouterQueryString(query.referralID)) {
            writeToLocalStorage(REFERRAL_ID, query.referralID);
        }
        if (userLoggedIn && isSportsMuseumTemplate) {
            if ((!!referralLink || query.referralID) && id) {
                ReferralApi.registerReferralLink({
                    universeId: id,
                    // @ts-ignore
                    referralLink: referralLink || query.referralID,
                }).then(() => {
                    removeFromLocalStorage(REFERRAL_ID);
                });
            }
        }
    }, [userLoggedIn]);

    useEffect(() => {
        if (isSportsMuseumTemplate && !disclaimer && router.pathname !== RouteE.LOGIN) {
            DisclaimerModal.open({ preventEasyClose: true });
        }

        if (isSportsMuseumTemplate && userLoggedIn && !profile?.user.smTermsAccepted) {
            AcceptedTermsModal.open({ preventEasyClose: true });
        }
    });

    // @ts-ignore
    return (
        <div className="App">
            <Head>
                {isUniverse && (
                    <>
                        <title key="metaTitle">{metaTitle}</title>
                        <meta
                            property="description"
                            content={isTechContemporary ? TechMetaDescription : metaDescription}
                        />
                    </>
                )}
                <meta
                    name="viewport"
                    content="user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1"
                />
                <meta charSet="utf-8" />
                <link rel="icon" href={favIcon} sizes="any" />
                <link
                    rel="preload"
                    href="/fonts/PPNeueMachina/PPNeueMachina-PlainRegular.woff2"
                    as="font"
                    crossOrigin=""
                    type="font/woff2"
                />
                <link
                    rel="preload"
                    href="/fonts/PPNeueMachina/PPNeueMachina-PlainMedium.woff2"
                    as="font"
                    crossOrigin=""
                    type="font/woff2"
                />
                <link
                    rel="apple-touch-icon"
                    href={`${baseUrl}/${isUniverse ? `${project}/` : ''}apple-touch-icon.png`}
                />
                {/* 180×180 */}

                <link rel="stylesheet" href="https://use.typekit.net/fym1hxp.css" />

                {!isUniverse && <link key="webmanifest" rel="manifest" href={`${baseUrl}/manifest.webmanifest`} />}
                <meta property="og:title" key="metaOgTitle" content={metaTitle} />
                <meta property="og:description" content={isTechContemporary ? TechMetaDescription : metaDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={baseUrl} />
                <meta property="og:image" content={ogImg} key="metaOgImage" />
                {/* @ts-ignore */}
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                {/* <!-- Google Tag Manager --> */}
                {isProd && <script type="text/plain" data-cookieconsent="analytics" src="/gtm.js" />}
                {/* <!-- End Google Tag Manager --> */}
                {isProd && <script type="text/plain" data-cookieconsent="analytics" src="/hotjar.js" />}
                {isProd && !isUniverse && (
                    <script
                        type="text/plain"
                        data-cookieconsent="analytics"
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=G-CJH8QPVL8P"
                    />
                )}
                {isProd && !isUniverse && <script type="text/plain" data-cookieconsent="analytics" src="/ga-init.js" />}
                {!isUniverse && (
                    <>
                        <link key="manifest" rel="manifest" href="/manifest.json" />
                        <meta name="application-name" content={`${projectName} PWA`} />
                        <meta name="apple-mobile-web-app-capable" content="yes" />
                        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                        <meta name="apple-mobile-web-app-title" content={`${projectName} PWA`} />
                        <meta name="format-detection" content="telephone=no" />
                        <meta name="mobile-web-app-capable" content="yes" />
                        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
                        <meta name="msapplication-TileColor" content="#211F1F" />
                        <meta name="msapplication-tap-highlight" content="no" />
                        <meta name="theme-color" content="#211F1F" />
                    </>
                )}
            </Head>
            {/* <!-- Google Tag Manager (noscript) --> */}
            <noscript>
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-5LDR2QK"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}
                />
            </noscript>
            {/* <!-- End Google Tag Manager (noscript) --> */}
            <div id="main-modal" />
            <NotificationsContextProvider>
                {/* @ts-ignore */}
                <Bootstrap>{getLayout(<Component {...pageProps} />)}</Bootstrap>
            </NotificationsContextProvider>
            {isProd && <script async src="/gdpr-cookie.js" />}
            {typeof window !== 'undefined' && isMobileOnly && (
                <a
                    style={{
                        visibility: 'hidden',
                        opacity: 0,
                    }}
                    id="metamask-trigger"
                    rel="noreferrer"
                    href={`https://metamask.app.link/dapp/${window.location.host}`}
                    target="_blank"
                >
                    mm
                </a>
            )}
        </div>
    );
};

MyApp.getInitialProps = wrapper.getInitialAppProps((store) => async (appContext: AppContext) => {
    const { data } = await makeSSRApiRequest(
        {
            req: appContext?.ctx?.req!,
            res: appContext?.ctx?.res!,
        },
        async () => UniverseAPI.getInfo()
    );

    store.dispatch(universeActions.update({ data }));
    const payload = useCreateLaunchpadsRequestPayload({
        sort: LaunchpadsSort.LATEST,
        filter: {},
        pager: {},
    });
    const res = await LaunchpadAPI.getByFilter(payload);
    // @ts-ignore
    store.dispatch(launchpadsActions.setExploreTotal(+res.data.pager.totalCount ?? 0));

    if (IS_SSR) {
        appContext.ctx?.res?.setHeader('Cache-Control', 'no-store, no-cache, must-revalidate');
        // @ts-ignore
        await store.dispatch(universeGetMineThunk());
        try {
            const userData = await makeSSRApiRequest(
                {
                    req: appContext?.ctx?.req!,
                    res: appContext?.ctx?.res!,
                    getAccess: true,
                },
                async ({ access }) =>
                    UserAPI.getUserProfile(
                        access
                            ? {
                                  Authorization: access,
                              }
                            : {}
                    )
            );

            if (userData.data.profile) {
                store.dispatch(actions.setSSRState(userData.data.profile));
            }
            // eslint-disable-next-line no-empty
        } catch (err) {}
    }
    const appProps = await App.getInitialProps(appContext);

    return { ...appProps };
});

const ProtectedApp = process.env.PASSWORD_PROTECT
    ? withPasswordProtect(MyApp, {
          // Options go here (optional)
          loginApiUrl: '/login',
      })
    : MyApp;

export default appWithTranslation(wrapper.withRedux(ProtectedApp));