import { useTranslation } from 'next-i18next';
import React from 'react';
import {
    DesktopInputWrapper,
    DesktopSearchWrapper,
    StyledCloseIcon,
    StyledSearchIcon,
    StyledSearchInput,
} from './header-search.styles';
import { IChildProps } from './header-search.types';

export const DesktopSearch: React.VFC<IChildProps> = ({ onSubmit, searchValue, onChange, onClear }) => {
    const { t } = useTranslation('components');

    return (
        <DesktopSearchWrapper>
            <DesktopInputWrapper>
                <StyledSearchIcon variant="regular" icon="search" width={24} height={24} />
                <form onSubmit={onSubmit}>
                    <StyledSearchInput value={searchValue} onChange={onChange} placeholder={t('search.placeholder')} />
                </form>
                {!!searchValue && <StyledCloseIcon variant="regular" icon="close" onClick={onClear} />}
            </DesktopInputWrapper>
        </DesktopSearchWrapper>
    );
};