import Link from 'next/link';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { LandingPageLayout } from 'src/features/white-labels/ocean/app/components/app-layout/app-layout.styles';
import { DesktopLinks } from 'src/features/white-labels/ocean/app/components/header/desktop-links';
import {
    StyledHeader,
    StyledLogo,
    StyledWrapper,
} from 'src/features/white-labels/ocean/app/components/header/header.style';
import { UserProfile } from 'src/features/white-labels/ocean/app/components/header/user-profile';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { getUrlWithPublicStorage } from 'src/utils/get-url-with-public-host';

export const Header = () => {
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);
    const { logo, isUniverse } = useUniverse();
    const logoLink = isUniverse ? getUrlWithPublicStorage(logo) : '/logo.svg';

    // handle scroll event
    const handleScroll = useCallback((elTopOffset, elHeight) => {
        if (window.pageYOffset > elTopOffset + elHeight) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    }, []);

    // add/remove scroll event listener
    useEffect(() => {
        // @ts-ignore
        const header = headerRef?.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height);
        };
        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    return (
        <StyledWrapper sticky={sticky.isSticky} ref={headerRef}>
            <LandingPageLayout>
                <FlexBox justifyContent="space-between" alignItems="center">
                    <StyledHeader alignItems="center" data-test-id="header">
                        <StyledLogo alignItems="center" justifyContent="center">
                            <Link href="/" prefetch={false}>
                                <a>
                                    <img
                                        src={logoLink}
                                        width={isUniverse ? undefined : 27}
                                        height={isUniverse ? undefined : 34}
                                        alt="logo"
                                    />
                                </a>
                            </Link>
                        </StyledLogo>
                    </StyledHeader>
                    <DesktopLinks />
                    <UserProfile />
                </FlexBox>
            </LandingPageLayout>
        </StyledWrapper>
    );
};
