import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { CollectionAdapter } from 'src/features/common/collection/ducks/list/list.adapter';
import { fetchCollectionsByIdsThunk } from './list.thunk';

export const listSlice = createSlice({
    name: 'collections-list',
    initialState: CollectionAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.collections.list,
            }))
            .addCase(fetchCollectionsByIdsThunk.fulfilled, (state, action) => {
                CollectionAdapter.addMany(state, action.payload ?? []);
            });

    },
});

export const { actions } = listSlice;

export default listSlice.reducer;
