import { useEffect, useState } from 'react';
import { LotAPI } from '@tacans/shared-marketplace-layer';

import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useIsMounted } from 'src/hooks/useIsMounted';

export const useActiveAuctionsHooks = () => {
    const isAuthorized = useAuthorized();
    const isMounted = useIsMounted();
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (isAuthorized) {
            LotAPI.fetchActiveAuctionsCount()
                .then(({ data }) => isMounted(() => setCount(data?.count ?? 0)));
        }
    }, [isAuthorized, isMounted]);

    return {
        count
    };
};