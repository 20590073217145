import { HYDRATE } from 'next-redux-wrapper';
import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '@tacans/shared-marketplace-layer';

import { fetchPublicProfileThunk } from './public-profile.thunk';

interface IState {
    profile: IUser | null
}

const INITIAL_STATE: IState = {
    profile: null
};

export const publicProfileSlice = createSlice({
    name: 'public-profile',
    initialState: INITIAL_STATE,
    reducers: {
        clearProfile: () => INITIAL_STATE
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => {
                // @ts-ignore
                const { profile } = action.payload.features.profile.publicProfile;
                if (profile) {
                    state.profile = profile;
                }
            })
            .addCase(fetchPublicProfileThunk.fulfilled, (state, action: any) => {
                state.profile = action.payload.data.user;
            });
    },
});

export const { actions } = publicProfileSlice;

export default publicProfileSlice.reducer;
