/**
 * EXAMPLE
 *
 * styled.div`
 *   ${media.md`
       background-color: aqua;
     }
 * `
 */
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { breakpoints, BreakPointT } from 'src/ui/common/config/breakpoints';

export const media = Object.keys(breakpoints).reduce(
    (accumulator, label ) => {
        accumulator[label as BreakPointT] = (...args) => {
            const myTuple: [TemplateStringsArray] = args as any;
            return css`
          @media ( min-width: ${breakpoints[label as BreakPointT]} ) {
            ${css(...myTuple)};
          }
        `;
        };
        return accumulator;
    },
    {} as Record<BreakPointT, (...args: any[]) => FlattenSimpleInterpolation>
);
