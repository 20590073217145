import { ICollectionsListFilter, ICollectionsListSorting } from '@tacans/shared-marketplace-layer';
import { CollectionsSort } from 'src/features/common/explore/ducks/collections/collections.types';

export const createCollectionsFilterFieldsPayload = (meta: ICollectionsListFilter): ICollectionsListFilter => {
    const fields: ICollectionsListFilter = {};

    meta = meta || {};

    if (meta.ownerIds) {
        fields.ownerIds = Array.isArray(meta.ownerIds) ? meta.ownerIds : [meta.ownerIds];
    }

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    return fields;
};

export const mapCollectionsSorting = (sort: CollectionsSort): ICollectionsListSorting => {
    switch (sort) {
        case CollectionsSort.LATEST:
            return {
                sortBy: 'Date',
                ascending: false,
            };
        case CollectionsSort.OLDEST:
            return {
                sortBy: 'Date',
                ascending: true,
            };
        case CollectionsSort.NAME_ASC:
            return {
                sortBy: 'Name',
                ascending: true,
            };
        case CollectionsSort.NAME_DESC:
            return {
                sortBy: 'Name',
                ascending: false,
            };
        default:
            return {
                sortBy: 'Date',
                ascending: false,
            };
    }
};
