import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Typography } from 'src/ui/common/atoms/typography';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledFooter = styled.div`
    position: relative;
    background: ${({ theme }) => theme.colors.grey900};
    border-top: 1px solid rgba(255, 255, 255, 0.06);
    bottom: 0;
    width: 100%;
    padding: 16px;

    ${media.md`
        padding: 48px 0 62px;
    `}
    a {
        margin: 0;
        padding: 0;
        text-align: unset;
    }
`;

export const StyledFooterContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    ${media.md`
         max-width: 1440px;
         padding: 0 48px;
         margin: 0 auto;
    `}
`;

export const StyledFooterTitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey400};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    align-self: baseline;
`;

export const StyledFooterLink = styled.a`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.lightGrey};
    padding-bottom: 8px !important;
    display: flex;
    gap: 5px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const StyledFooterText = styled(Typography)`
    color: ${({ theme }) => theme.colors.gs100};
    font-size: 12px;
`;

export const StyledLinksWrapper = styled(FlexBox)`
    padding-bottom: 40px;

    ${media.sm`
        padding-top: 0;
    `}
`;