import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { CurrencyAdapter } from './currency.adapter';

export const currencySelect = (state: RootState) => state.global.currency;
export const currencySelectors = CurrencyAdapter.getSelectors();
export const selectAllCurrencyEntities = createSelector(
    currencySelect,
    (state) => currencySelectors.selectEntities(state)
);
