import { createAsyncThunk } from '@reduxjs/toolkit';
import { LotAPI, LotI, NFTI } from '@tacans/shared-marketplace-layer';
import { NftSaleDetails } from 'src/features/common/collectible/collectible.typing';
import { calculateNftSaleDetails } from '../../collectible.helpers';

export const getNftSalesThunk = createAsyncThunk(
    'collectible/create-lot',
    async (nftIds: NFTI['id'][]) => {
        const response = await LotAPI.fetchLotsByNfts({
            nftIds,
            activeOnly: true
        });
        const lots = response.data.lots ?? [];
        const res: NftSaleDetails[] = [];
        const lotsByNft: Record<NFTI['id'], LotI[]> = {};

        for(const lot of lots) {
            if (!lotsByNft[lot.nftId]) {
                lotsByNft[lot.nftId] = [];
            }
            lotsByNft[lot.nftId].push(lot);
        }

        // eslint-disable-next-line guard-for-in
        for(const nftId in lotsByNft) {
            res.push({
                ...calculateNftSaleDetails(lotsByNft[nftId]),
                nftId,
            });
        }

        return res;
    },
);
