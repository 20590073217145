import Router from 'next/router';
import React, { useCallback, useMemo, useRef } from 'react';
import { RouteE } from 'src/constants/pages.constants';
import {
    DesktopLink,
    StyledDesktopLinks,
    StyledLinksVisible,
} from 'src/features/common/app/components/header/header.styles';
import { InfoModal } from 'src/features/white-labels/nftsuperlab/user/modals/info-modal';

export const LandingLinks = () => {
    const initedRef = useRef(false);

    const initData = useCallback(() => {
        if (initedRef.current) {
            return;
        }
        initedRef.current = true;
    }, []);

    const goToAboutUs = useCallback(() => {
        Router.pathname.includes(RouteE.MARKETPLACE)
            ? document.querySelector('[data-component-id="about-us"]')?.scrollIntoView()
            : Router.push({ pathname: RouteE.MARKETPLACE, query: { scrollTo: 'about-us' } });
    }, []);

    const goToCollections = useCallback(() => {
        Router.pathname.includes(RouteE.MARKETPLACE)
            ? document.querySelector('[data-component-id="the-collection"]')?.scrollIntoView()
            : Router.push({ pathname: RouteE.MARKETPLACE, query: { scrollTo: 'the-collection' } });
    }, []);

    const goToFaq = useCallback(() => {
        Router.pathname.includes(RouteE.MARKETPLACE)
            ? document.querySelector('[data-component-id="faq"]')?.scrollIntoView()
            : Router.push({ pathname: RouteE.MARKETPLACE, query: { scrollTo: 'faq' } });
    }, []);

    const navigationLinks = useMemo(
        () => [
            { title: 'Om kollektionen', onClick: goToAboutUs },
            { title: 'Universet', onClick: goToCollections },
            { title: 'FAQ', onClick: goToFaq },
        ],
        []
    );

    const howToBuyModal = useCallback(() => {
        InfoModal.open({ type: 'Info' });
    }, []);

    return (
        <>
            <StyledLinksVisible>
                <StyledDesktopLinks alignItems="center" onMouseEnter={initData}>
                    {navigationLinks.map((link) => (
                        <DesktopLink key={link.title} onClick={link.onClick}>
                            {link.title}
                        </DesktopLink>
                    ))}
                    <DesktopLink as="div" onClick={howToBuyModal}>
                        <span>Guide til at købe</span>
                    </DesktopLink>
                </StyledDesktopLinks>
            </StyledLinksVisible>
        </>
    );
};
