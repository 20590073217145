import { createAsyncThunk } from '@reduxjs/toolkit';
import { AddressesApi, IAddAddressPayload, IUserAddressPayload } from '@tacans/shared-marketplace-layer';

export const addressesAddThunk = createAsyncThunk('addresses/add', async (data: IAddAddressPayload) => {
    const response = await AddressesApi.addAddress(data);

    return { response };
});

export const addressesUpdateThunk = createAsyncThunk('addresses/update', async (data: IAddAddressPayload) => {
    const response = await AddressesApi.updateAddress(data);

    return { response };
});

export const fetchByUserAddressesThunk = createAsyncThunk(
    'addresses/fetch',
    async (data: IUserAddressPayload, { rejectWithValue }) => {
        const response = await AddressesApi.getAddressByUser(data);
        const { error } = response.data.responseMeta;
        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);