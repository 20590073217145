import React, { useEffect, useState } from 'react';
import { IRegistryNotification } from 'src/services/notifications/types';
import NotificationComponent from 'src/services/notifications/notification-component';
import { useIsMounted } from 'src/hooks/useIsMounted';
import { StyledContainer } from 'src/services/notifications/styles';
import notificationsRegistry from './notifications-registry';

const NotificationContainer = () => {
    const [
        notifications,
        setNotifications
    ] = useState<IRegistryNotification[]>([]);
    const isMounted = useIsMounted();

    useEffect(() => {
        const setNotificationsCallback = (items: IRegistryNotification[]) => {
            isMounted(() => setNotifications(items));
        };
        notificationsRegistry.subscribe(setNotificationsCallback);

        return () => {
            notificationsRegistry.unsubscribe(setNotificationsCallback);
        };
    }, [isMounted]);

    return (
        <StyledContainer data-test-id="notifications-container">
            {notifications.map(notification => (
                <NotificationComponent
                    key={notification.id}
                    notification={notification}
                />
            ))}
        </StyledContainer>
    );
};

export default NotificationContainer;