import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReferralApi } from '@tacans/shared-marketplace-layer';
import {
    selectFilter,
    selectPager,
} from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.selectors';
import { useGetAllReferralsRequestPayload } from 'src/features/common/affiliate-program/hooks/use-get-all-referrals-request-payload';
import { selectUniverseInfo } from 'src/features/common/universe/ducks/universe-info/universe-info.selectors';
import { RootState } from 'src/store';

export const fetchAllReferralsThunk = createAsyncThunk('get-all-referrals/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const info = selectUniverseInfo(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);

    const payload = useGetAllReferralsRequestPayload({
        // @ts-ignore
        id: info?.id,
        pager: {
            page: pager?.page ?? 0,
            limit: pager?.limit ?? 10,
        },
        filter,
    });
    const { data } = await ReferralApi.fetchReferrals(payload);
    return data;
});