import { RequestPagerI } from '@tacans/shared-marketplace-layer';
import { createFilterFieldsPayload, mapSorting } from '../explore-api.utils';
import { ExploreSort, IExploreFilters } from '../ducks/explore-nfts/explore-nfts.types';

export const useCreateExploreNftsRequestPayload = ({ sort, filter, pager }: {
    sort: ExploreSort | null,
    filter: IExploreFilters,
    pager?: RequestPagerI,
}) => ({
    sorting: mapSorting(sort),
    filter: createFilterFieldsPayload(filter),
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});
