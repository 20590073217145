import { RequestPagerI, ICollectionsListFilter, ICollection } from '@tacans/shared-marketplace-layer';

export interface ICollectionsFilter {
    filter: ICollectionsListFilter;
    sorting: CollectionsSort;
    pager: RequestPagerI;
}

export enum CollectionsSort {
    LATEST = 'latest',
    OLDEST = 'oldest',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
}


export interface ICollectionsState extends ICollectionsFilter {
    loading: boolean;
    total: number;
    pages: number[];
    byPage: Record<number, ICollection['id'][]>
}
