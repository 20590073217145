import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { LandingLinks } from 'src/features/white-labels/nftsuperlab/app/components/header/landing-header/landing-links';
import { UserProfile } from 'src/features/white-labels/nftsuperlab/app/components/header/user-profile';
import { getUrlWithPublicStorage } from 'src/utils/get-url-with-public-host';
import { StyledHeader, StyledLogo } from '../header.styles';

export const LandingHeader = () => {
    const { logo, isUniverse } = useUniverse();
    const router = useRouter();
    const isFaqPage = router.pathname === '/faq';

    const logoLink = isUniverse ? getUrlWithPublicStorage(logo) : '/logo.svg';

    return (
        <StyledHeader alignItems="center" data-test-id="header">
            <StyledLogo alignItems="center" justifyContent="center">
                <Link href="/" prefetch={false}>
                    <a>
                        <img
                            src={logoLink}
                            width={isUniverse ? undefined : 27}
                            height={isUniverse ? undefined : 34}
                            alt="logo"
                        />
                    </a>
                </Link>
            </StyledLogo>
            {!isFaqPage && <LandingLinks />}
            <UserProfile />
        </StyledHeader>
    );
};
