import styled from 'styled-components';

export const StyledWrapper = styled.div`
    background: ${({ theme }) => theme.colors.white};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const LandingPageLayout = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 1440px;
`;
