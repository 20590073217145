import { speed } from 'src/ui/common/config/animations';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const StyledContent = styled.div`
  background-color: ${({ theme }) => theme.colors.gs500};
  padding: 10px;
  animation: ${speed.content} ${fadeIn} ease-in;
  margin-top: 1px;
  border-radius: 5px;
  overflow-y: auto;
`;
