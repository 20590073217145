import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI } from '@tacans/shared-marketplace-layer';
import { selectFilter, selectPager, selectSorting } from 'src/features/common/explore/ducks/users/users.selectors';
import { RootState } from 'src/store/store';
import { useCreateUsersRequestPayload } from '../../hooks/use-create-users-request-payload';

export const fetchUsersThunk = createAsyncThunk('users/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const sort = selectSorting(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);
    const payload = useCreateUsersRequestPayload({
        sort,
        filter,
        pager,
    });
    const { data } = await UserAPI.fetchUsersByFilter(payload);

    return data;
});
