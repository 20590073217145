export const IS_SSR = typeof window === 'undefined';

export function isEmptyObject(value: Record<any, any> | null): boolean {
    if (!value) {
        return true;
    }
    return value.constructor === Object && Object.keys(value).length === 0;
}

export const debounce = (fn: Function, ms = 300) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function debounceClosure(this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};

export const throttle = <F extends Function>(callback: F) => {
    let reqID: number | null = null;

    const exec = (args: F['arguments']) => () => {
        callback(...args);
        reqID = null;
    };

    return (...args: F['arguments']) => {
        if (!reqID) {
            reqID = requestAnimationFrame(exec(args));
        }
    };
};

export type GetFieldType<Obj, Path> = Path extends `${infer Left}.${infer Right}`
    ? Left extends keyof Obj
        ? GetFieldType<Exclude<Obj[Left], undefined>, Right> | Extract<Obj[Left], undefined>
        : undefined
    : Path extends keyof Obj
        ? Obj[Path]
        : undefined

export function getValue<
    TData,
    TPath extends string,
    TDefault = GetFieldType<TData, TPath>
    >(
    data: TData,
    path: TPath,
    defaultValue?: TDefault
): GetFieldType<TData, TPath> | TDefault {
    const value = path
        .split('.')
        .reduce<GetFieldType<TData, TPath>>(
            (value, key) => (value as any)?.[key],
            data as any
        );

    return value !== undefined ? value : (defaultValue as TDefault);
}

export function toBlob(canvas: HTMLCanvasElement, extension = 'image/jpeg' ): Promise<Blob> {
    const type = extension === 'image/png' ? 'image/png' : 'image/jpeg';
    return new Promise((resolve) => {
        // @ts-ignore
        canvas.toBlob(resolve, type);
    });
}

export const getRandomInt = (min: number, max: number) =>
    Math.round(Math.random() * (max - min) + min);

export const delay = (ms: number) => new Promise( resolve => setTimeout(resolve, ms));
