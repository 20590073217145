import { FlexBox } from 'src/ui/common/atoms/flex-box';
import styled from 'styled-components';
import { SizeT, VariantT } from './avatar.types';

const FONT_SIZE: Record<SizeT, string> = {
    20: '13px',
    30: '14px',
    40: '16px',
    50: '25px',
    60: '31px',
    70: '33px',
    80: '34px',
    90: '35px',
    100: '36px',
    200: '48px',
};

export const StyledAvatar = styled(FlexBox)<{ variant: VariantT; size: SizeT; borderRadius?: string }>`
    cursor: pointer;
    display: inline-flex;
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    background-color: ${({ theme }) => theme.colors.gs500};
    flex: ${({ size }) => `0 0 ${size}px`};
`;

export const StyledName = styled.span<{ size: SizeT }>`
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.gs200};
    font-weight: 400;
    font-size: ${({ size }) => FONT_SIZE[size]};
`;