import { UserAPI } from '@tacans/shared-marketplace-layer';
import { useCallback } from 'react';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';

export const useSetGoogleClientId = () => {
    const isAuthorized = useAuthorized();
    const { user } = useProfile() || {};

    return useCallback(() => {
        if (!isAuthorized) {
            return;
        }

        let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
        const raw = match ? decodeURIComponent(match[1]) : null;
        if (raw) {
            match = raw.match(/(\d+\.\d+)$/);
        }
        const gacid = match ? match[1] : null;
        if (user?.id && gacid && user?.gaClientId !== gacid) {
            UserAPI.setGoogleClientId(gacid);
        }
    }, [isAuthorized, user]);
};
