import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { MyArtsAdapter } from './my-arts.adapter';

export const myArtsSelect = (state: RootState) => state.features.myArts;

export const myArtsSelectors = MyArtsAdapter.getSelectors();

export const selectArts = createSelector(myArtsSelect, (state) => myArtsSelectors.selectAll(state));

export const selectArtsEntities = createSelector(myArtsSelect, (state) => myArtsSelectors.selectEntities(state));

export const selectPager = createSelector(myArtsSelect, (state) => state.pager);

export const selectPages = createSelector(myArtsSelect, (state) => state.pages);

export const selectByPage = createSelector(myArtsSelect, (state) => state.byPage);

export const selectTotal = createSelector(myArtsSelect, (state) => state.total);

export const selectVisibleTotal = createSelector(myArtsSelect, (state) => state.visibleTotal);

export const selectLoading = createSelector(myArtsSelect, (state) => state.loading);