import { CartApi } from '@tacans/shared-marketplace-layer';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actions as CartActions } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.slice';
import { CartItem } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.types';
import { useNotifications } from 'src/services/notifications/use-notifications';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';
import { getEuroFromCent } from 'src/utils/getEuroFromCent';
import { fetchCartThunk } from '../../ducks/cart.thunk';
import {
    StyledArtElement,
    StyledCartBadge,
    StyledLeftContainer,
    StyledMainInfoContainer,
    StyledPreviewWrapper,
    StyledRightContainer,
} from './cart-dropdown.styles';

interface IProps {
    cartItem: CartItem;
}

export const CartItemComponent: FC<IProps> = ({ cartItem }) => {
    const { t } = useTranslation('tech-contemporary');
    const dispatch = useDispatch();
    const { success, error } = useNotifications();

    const successNotification = useCallback(() => {
        success({
            text: `${cartItem.art.name} deleted from the cart`,
        });
    }, [success]);

    const errorNotification = useCallback(
        (e?: string) => {
            error({
                text: `${e}? ${e} : ${cartItem.art.name} not deleted from the cart`,
            });
        },
        [error]
    );

    const locale = useMemo(
        () => ({
            quantity: t('artPage.quantity', {
                ns: 'tech-contemporary',
            }),
            price: t('artPage.price', {
                ns: 'tech-contemporary',
            }),
            inStock: t('artPage.inStock', {
                ns: 'tech-contemporary',
            }),
            buyNow: t('actions.buyNow', {
                ns: 'tech-contemporary',
            }),
            physicalArt: t('artPage.physicalArt', {
                ns: 'tech-contemporary',
            }),
            digitalArt: t('artPage.digitalArt', {
                ns: 'tech-contemporary',
            }),
        }),
        [t]
    );

    const deleteItemFromCart = async () => {
        dispatch(CartActions.removeItemFromCart(cartItem));

        try {
            const res = await CartApi.removeItemFromCart({ digitalArtID: cartItem.digitalArtLotId });
            if (res.data.responseMeta.status === 'OK') {
                await dispatch(fetchCartThunk());
                successNotification();

                return;
            }
            if (res.data.responseMeta.error) {
                errorNotification(res.data.responseMeta.error.messages[0]);
                return;
            }
        } catch (e: any) {
            errorNotification(e);
        }
    };

    const getItemTotal = () => getEuroFromCent(Number(cartItem.art.price)) * Number(cartItem.quantity);

    return (
        <StyledArtElement justifyContent="space-between">
            <StyledLeftContainer>
                <StyledPreviewWrapper previewUri={cartItem.art.previewUri} />
                <StyledMainInfoContainer flexDirection="column" justifyContent="space-between">
                    <div>
                        <Typography size="sm" overflowLines={1} variant="title" color="white">
                            {cartItem.art.name}
                        </Typography>
                        <Typography size="xs" color="gs100">
                            Quantity: {cartItem.quantity}
                        </Typography>
                    </div>
                    <Typography size="sm" variant="title" color="white">
                        €{getItemTotal()}
                    </Typography>
                </StyledMainInfoContainer>
            </StyledLeftContainer>
            <StyledRightContainer alignItems="flex-start">
                <StyledCartBadge>{cartItem.art.isPhysical ? locale.physicalArt : locale.digitalArt}</StyledCartBadge>
                <Spacing mt="4px">
                    <SvgIcon variant="regular" icon="trash" onClick={deleteItemFromCart} />
                </Spacing>
            </StyledRightContainer>
        </StyledArtElement>
    );
};