import { RequestPagerI } from '@tacans/shared-marketplace-layer';

export interface EVENTI {
    name: string;
    location: string,
    dateFrom: string,
    dateTo: string
}

export enum EventsSort {
    DATE = 'date',
    LOCATION = 'location',
    NAME = 'name',
}

export interface IEventsFilters {
    names?: string[],
    locations?: string[],
    dateFrom?: string,
    dateTo?: string,
}

export interface IFetchEventsFilter {
    filter: IEventsFilters;
    sorting: EventsSort;
    pager: RequestPagerI;
}

export interface IExploreEventsState extends IFetchEventsFilter {
    loading: boolean;
    total: number;
    /**
     *  when infinite scroll disabled - prevent loading new, set limited total
     */
    visibleTotal: number;
    pages: number[];
    byPage: Record<number, EVENTI['name'][]>
}