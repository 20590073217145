import React from 'react';
import { ISpacing } from './spacing.types';
import { StyledSpacing } from './spacing.styles';

export const Spacing = React.forwardRef<HTMLDivElement, ISpacing>((props : ISpacing, ref) => (
    <StyledSpacing
        ref={ref}
        {...props}
    >
        {props.children}
    </StyledSpacing>
));
