import { createSlice } from '@reduxjs/toolkit';
import { fetchLotBidsThunk } from './bids.thunk';
import { BidsAdapter } from './bids.adapter';

const initialState = BidsAdapter.getInitialState({
    page: 0,
    totalCount: 0,
});

export const collectibleBidsSlice = createSlice({
    name: 'collectible-bids',
    initialState,
    reducers: {
        clear: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLotBidsThunk.fulfilled, (state, action) => {
                state.page = +(action.payload.pager?.page ?? 0);
                state.totalCount = +(action.payload.pager?.totalCount ?? 0);

                if (state.page === 0) {
                    BidsAdapter.setMany(state, action.payload.bids ?? []);
                } else {
                    BidsAdapter.addMany(state, action.payload.bids ?? []);
                }
            });
    },
});

export const { actions } = collectibleBidsSlice;

export default collectibleBidsSlice.reducer;
