import { IUsersListFilter, IUsersListSorting } from '@tacans/shared-marketplace-layer';

import { UsersSort } from './ducks/users/users.types';

export const createUsersFilterFieldsPayload = (meta: IUsersListFilter): IUsersListFilter => {
    const fields: IUsersListFilter  = {};

    meta = meta || {};

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    return fields;
};

export const mapUsersSorting = (sort: UsersSort): IUsersListSorting => {
    switch(sort) {
        case UsersSort.LATEST:
            return {
                sortBy: 'Date',
                ascending: false
            };
        case UsersSort.OLDEST:
            return {
                sortBy: 'Date',
                ascending: true
            };
        case UsersSort.NAME_ASC:
            return {
                sortBy: 'Name',
                ascending: true
            };
        case UsersSort.NAME_DESC:
            return {
                sortBy: 'Name',
                ascending: false
            };
        default:
            return {
                sortBy: 'Date',
                ascending: false
            };
    }
};