import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { MyArtsAdapter } from './my-arts.adapter';
import { INITIAL_STATE } from './my-arts.constants';
import { fetchMyArtsThunk } from './my-arts.thunk';
import { IMyArtsState } from './my-arts.types';

const getInitialState = () => MyArtsAdapter.getInitialState<IMyArtsState>(INITIAL_STATE);

export const myArtsSlice = createSlice({
    name: 'my-arts',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            MyArtsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_STATE.pager };
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setItems: (state, { payload }) => {
            state.entities = payload ?? [];
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyArtsThunk.fulfilled, (state, action) => {
                const { pager, list = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (list.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                list.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        newByPage[page].push(item.id);
                    }
                });

                MyArtsAdapter.addMany(state, list || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.myArts,
            }));
    },
});

export const { actions } = myArtsSlice;

export default myArtsSlice.reducer;