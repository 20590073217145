import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { LaunchpadsAdapter } from './launchpads.adapter';

export const launchpadsSelect = (state: RootState) => state.features.launchpads.explore;

export const launchpadsSelectors = LaunchpadsAdapter.getSelectors();

export const selectLaunchpads = createSelector(launchpadsSelect, (state) => launchpadsSelectors.selectAll(state));

export const selectLaunchpadsSelectEntities = createSelector(launchpadsSelect, (state) =>
    launchpadsSelectors.selectEntities(state)
);

export const selectFilter = createSelector(launchpadsSelect, (state) => state.filter);

export const selectPager = createSelector(launchpadsSelect, (state) => state.pager);

export const selectSorting = createSelector(launchpadsSelect, (state) => state.sorting);

export const selectPages = createSelector(launchpadsSelect, (state) => state.pages);

export const selectByPage = createSelector(launchpadsSelect, (state) => state.byPage);

export const selectTotal = createSelector(launchpadsSelect, (state) => state.total);

export const selectExploreTotal = createSelector(launchpadsSelect, (state) => state.exploreTotal);

export const selectLoading = createSelector(launchpadsSelect, (state) => state.loading);