import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGetWallet, IUser, UserAPI } from '@tacans/shared-marketplace-layer';

export const fetchUsersByIdsThunk = createAsyncThunk(
    'user-list/fetch-users-by-ids',
    async (data: IUser['id'][]) => {
        const response = await UserAPI.fetchUsersByIds(data);

        return response.data.users;
    }
);

export const fetchUsersByWalletsThunk = createAsyncThunk(
    'user-list/fetch-users-by-wallets',
    async (data: IGetWallet[]) => {
        const response = await UserAPI.fetchUsersByWallets(data);
        return response.data.items;
    }
);
