import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { CollectionAdapter } from './collection.adapter';
import {
    collectionCreateThunk,
    collectionFetchThunk,
} from './collection.thunk';

const getInitialState = () => CollectionAdapter.getInitialState();

export const collectionSlice = createSlice({
    name: 'collections/collection',
    initialState: getInitialState(),
    reducers: {
        clearCollection: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.collections.collection,
            }))
            .addCase(collectionFetchThunk.fulfilled, (state, action) => {
                CollectionAdapter.setOne(state, action.payload.collection);
            })
            .addCase(collectionCreateThunk.fulfilled, (state, action) => {
                // @ts-ignore
                if (action.payload.data.errors) {
                    // @ts-ignore
                    state.error = Object.values(action.payload.data.responseMeta.error.messages).join(', ');

                    return;
                }

                const id = state.ids[0];
                if (id) {
                    CollectionAdapter.updateOne(state, {
                        id,
                        changes: action.payload.data.collection,
                    });
                }
            });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = collectionSlice;

export default collectionSlice.reducer;
