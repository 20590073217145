import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { WalletAdapter } from './wallet.adapter';

export const walletSelect = (state: RootState) => state.features.wallet;

export const walletSelectors = WalletAdapter.getSelectors();

export const selectWallets = createSelector(walletSelect, (state) =>
    walletSelectors.selectAll(state)
);
export const selectWalletsHasNew = createSelector(
    walletSelect,
    (wallet) => wallet.hasNew
);

export const selectWalletsHasError = createSelector(
    walletSelect,
    (wallet) => wallet.error
);

export const selectWalletsLoaded = createSelector(
    walletSelect,
    (wallet) => wallet.loaded
);

export const selectBlockChains = createSelector(
    walletSelect,
    (wallet) => wallet.blockchains
);

export const selectCoins = createSelector(
    walletSelect,
    (wallet) => wallet.coins
);
