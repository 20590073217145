import {
    apiClient,
    AUTH_TOKENS,
    AuthTokenCookieService,
    TOKENS_LIFETIME,
    UserAPI,
} from '@tacans/shared-marketplace-layer';
import { getCookie, setCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import getConfig from 'next/config';
import { IS_SSR } from 'src/utils/helpers.utils';

const { publicRuntimeConfig } = getConfig();
const isLocal = publicRuntimeConfig.NEXT_PUBLIC_ENV === 'local';

interface ApiCbPayload {
    access?: string;
}

interface Args {
    req: IncomingMessage;
    res: ServerResponse;
    getAccess?: boolean;
}

export const makeSSRApiRequest = async (
    { req, res, getAccess }: Args,
    apiCb: (payload: ApiCbPayload) => void
): Promise<any> => {
    if (!isLocal && req?.headers.host && IS_SSR) {
        // @ts-ignore
        apiClient.defaults.headers.host = req?.headers.host;
    }

    const payloadToCb: ApiCbPayload = {};

    if (IS_SSR && getAccess) {
        const token = getCookie(AUTH_TOKENS.access, { req, res }) as string;
        const refreshToken = getCookie(AUTH_TOKENS.refresh, { req, res }) as string;
        if (!token && refreshToken) {
            try {
                const resRefresh = await UserAPI.refresh({ refresh: refreshToken });
                const { access, refresh } = resRefresh.data.tokens;
                payloadToCb.access = access;

                const domain = AuthTokenCookieService.getCookieDomain(req?.headers.host);

                setCookie(AUTH_TOKENS.access, access, {
                    req,
                    res,
                    domain,
                    path: '/',
                    maxAge: TOKENS_LIFETIME.access,
                });
                setCookie(AUTH_TOKENS.refresh, refresh, {
                    req,
                    res,
                    domain,
                    path: '/',
                    maxAge: TOKENS_LIFETIME.refresh,
                });
            } catch (e) {
                console.log('error cached from SSR refresh', e);
            }
        } else if (token) {
            payloadToCb.access = token;
        }
    }
    const result = await apiCb(payloadToCb);
    return result;
};