import React, { FC, useCallback } from 'react';
import {
    StyledButtonsWrapper,
    StyledCartTotal,
    StyledIconButton,
} from 'src/features/white-labels/techcontemporary/app/components/header/header.styles';
import { UserMenuModal } from 'src/features/white-labels/techcontemporary/app/components/profile-modal';
import { CartModal } from 'src/features/white-labels/techcontemporary/cart/modals/cart-modal';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Button } from 'src/ui/white-labels/techcontemporary/atoms/button';

interface IProps {
    init: boolean;
    authorized: boolean;
    isOwnerTechContemporary: boolean;
    goToCreateArtHandler: () => void;
    cartCount: number;
}

export const UserProfileMobile: FC<IProps> = ({
    init,
    authorized = false,
    isOwnerTechContemporary,
    goToCreateArtHandler,
    cartCount,
}) => {
    const handleUserModalOpen = useCallback((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        UserMenuModal.open({});
    }, []);

    const handleCartModalOpen = useCallback(() => {
        if (init && authorized) {
            CartModal.open({});
        }
    }, [init, authorized]);

    return (
        <StyledButtonsWrapper>
            {init && authorized && isOwnerTechContemporary && (
                <Button variant="white" size="sm" onClick={goToCreateArtHandler}>
                    Create Art
                </Button>
            )}
            {init && authorized && !isOwnerTechContemporary && (
                <StyledIconButton isIcon size="md" type="button" variant="text" onClick={handleCartModalOpen}>
                    <SvgIcon variant="regular" icon="cart" />
                    {cartCount > 0 && init && authorized && <StyledCartTotal />}
                </StyledIconButton>
            )}

            <StyledIconButton variant="text" onClick={handleUserModalOpen} isIcon>
                <SvgIcon variant="regular" icon="hamburger" />
            </StyledIconButton>
        </StyledButtonsWrapper>
    );
};