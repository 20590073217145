import { useEffect, useState } from 'react';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useCategoriesFetch } from 'src/features/common/category/hooks/category.hooks';
import { useCurrencyFetch } from 'src/features/common/currency/hooks/currency.hooks';
import { useAddressesFetch } from 'src/features/common/profile/hooks/addresses.hooks';
import { useProfile, useProfileFetch, useProfileLoaded } from 'src/features/common/profile/hooks/profile.hooks';
import { useSetGoogleClientId } from 'src/features/common/user/hooks/use-set-google-client-id';
import { useFetchBlockChains, useFetchCoins, useWalletsFetch } from 'src/features/common/wallet/hooks/wallet.hooks';
import { broadcastUser } from 'src/services/analytics';

export const useInitialFetch = (): boolean => {
    const [dataLoaded, setDataLoaded] = useState(true);
    const fetchCurrency = useCurrencyFetch();
    const fetchProfile = useProfileFetch();
    const fetchBlockChains = useFetchBlockChains();
    const isAuthorized = useAuthorized();
    const fetchWallets = useWalletsFetch();
    const fetchCategories = useCategoriesFetch();
    const userLoaded = useProfileLoaded();
    const fetchCoins = useFetchCoins();
    const { user } = useProfile() || {};
    const setGoogleClientId = useSetGoogleClientId();
    const fetchAddressesByUserId = useAddressesFetch();

    useEffect(() => {
        fetchBlockChains();
        fetchCoins();
        fetchCategories();
        fetchCurrency();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAuthorized) {
            fetchProfile();
            fetchAddressesByUserId({ userId: user?.id });
        }
    }, [isAuthorized, fetchProfile, fetchAddressesByUserId]);

    useEffect(() => {
        if (isAuthorized && user?.login) {
            fetchWallets(user.login);
        }
    }, [fetchWallets, isAuthorized, user?.login]);

    useEffect(() => {
        if (isAuthorized && user?.id) {
            setGoogleClientId();
        }
    }, [isAuthorized, setGoogleClientId, user?.id]);

    useEffect(() => {
        setDataLoaded(!!userLoaded || !isAuthorized);
    }, [userLoaded, isAuthorized]);

    useEffect(() => {
        if (userLoaded && isAuthorized && user) {
            broadcastUser({
                userId: user.id,
            });
        }
        if (!isAuthorized) {
            broadcastUser({});
        }
    }, [userLoaded, user, isAuthorized]);

    return dataLoaded;
};