import { SocialsT, UserCurrency, WalletProviderE } from '@tacans/shared-marketplace-layer';

export const DATE_FORMAT = 'D/MM/YY, HH:mm:ss';
export const DATE_FORMAT_SHORT = 'MMM D, YYYY';

export const FIAT_FR_DIGITS_LENGTH = 2;

export const imageExtensions = ['JPEG', 'JPG', 'PNG', 'GIF'];
export const archiveExtensions = ['zip', 'zipx'];
export const logoExtensions = ['JPEG', 'JPG', 'PNG', 'SVG'];
export const openGraphExtensions = ['JPEG', 'JPG', 'PNG'];
export const faviconExtensions = ['ICO', 'JPG', 'PNG'];
export const imageCoverExtensions = ['JPEG', 'JPG', 'PNG'];
export const imageMaxSize = '5 Mb';
export const archiveMaxSize = '1 Gb';
export const faviconMaxSize = '1 Mb';

export const collectibleExtensions = ['JPEG', 'JPG', 'PNG', 'GIF', 'WEBP', 'MP4', 'MP3', 'GLB'];
export const collectibleMaxSize = '2 Gb';
export const recommendedAvatarDimension = '300x300';
export const recommendedBannerDimension = '1400x300';

export interface SocialLinkI {
    type: SocialsT;
    identifier: string;
}

export const SOCIALS: SocialLinkI[] = [
    { type: 'Discord', identifier: 'https://discord.gg/mZ9MDdJahH' },
    { type: 'Facebook', identifier: 'https://www.facebook.com/SpaceSeven-108739658150141' },
    { type: 'Instagram', identifier: 'https://www.instagram.com/spaceseven_nft/' },
    { type: 'Twitter', identifier: 'https://twitter.com/spaceseven_nft' },
    { type: 'Telegram', identifier: 'https://t.me/spaceseven_nft' },
];

export const appLinks = {
    googlePlay: 'https://play.google.com/store/apps/details?id=com.pioneeringtechventures.wallet',
    appStore: 'https://apps.apple.com/ca/app/cryptox-wallet/id1593386457',
    metamaskInstall: 'https://metamask.io/download/',
};

export const walletIcons: Record<WalletProviderE, string> = {
    [WalletProviderE.Concordium]: '/blockchains/ccd.svg',
    [WalletProviderE.Ethereum]: '/blockchains/eth.svg',
};

export const walletCoins: Record<WalletProviderE, string> = {
    [WalletProviderE.Concordium]: 'CCD',
    [WalletProviderE.Ethereum]: 'ETH',
};

export const FEE_VALUE: Record<WalletProviderE, number> = {
    [WalletProviderE.Concordium]: 10,
    [WalletProviderE.Ethereum]: 6,
};

export const CURRENCY_SYMBOL: Record<UserCurrency, string> = {
    usd: '$',
    eur: '€',
};

export const PAGES_LOCALES = [
    'common',
    'components',
    'pages',
    'modals',
    'actions',
    'form',
    'meta',
    'delivery-address',
    'affiliate-program',
    'tech-contemporary',
];

export const SOCIALS_INFO: { [key in SocialsT]?: { link: string } } = {
    Twitter: {
        link: 'twitter.com',
    },
    Instagram: {
        link: 'instagram.com',
    },
    Linkedin: {
        link: 'linkedin.com',
    },
    Facebook: {
        link: 'facebook.com',
    },
    Youtube: {
        link: 'youtube.com',
    },
    Discord: {
        link: 'discord.com',
    },
    Pinterest: {
        link: 'pinterest.com',
    },
    Behance: {
        link: 'behance.net',
    },
    Medium: {
        link: 'medium.com',
    },
    Telegram: {
        link: 't.me',
    },
};