import { techTheme } from 'src/features/common/app/components/theme-provider/theme';
import styled from 'styled-components';
import { ButtonProps } from './button.types';

export const StyledButton = styled.button<ButtonProps>`
    font-family: Manrope, sans-serif;
    user-select: none;
    cursor: pointer;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 74px;
    border: none;
    transition: background 400ms;
    overflow: hidden;
    width: fit-content;

    ${(props) => props.stretch && 'width: 100%;'};

    ${(props) =>
        props.size === 'sm' &&
        `
        height: 32px;
        padding: 6px 16px;
    `};

    ${(props) =>
        props.size === 'md' &&
        `
        height: 40px;
        padding: 10px 24px;
    `};

    ${(props) =>
        props.size === 'lg' &&
        `
        height: 48px;
        padding: 12px 24px;
    `};

    ${(props) =>
        props.disabled &&
        `
        cursor: not-allowed;
        pointer-events: none;
    `};

    ${(props) =>
        props.isIcon &&
        props.size === 'sm' &&
        `
        width: 32px;
        height: 32px;
        padding: 12px;
    `};

    ${(props) =>
        props.isIcon &&
        props.size === 'md' &&
        `
        width: 40px;
        height: 40px;
        padding: 12px;
    `};

    ${(props) =>
        props.variant === 'white' &&
        `
        background:  ${techTheme.colors.white};
        border: 3px solid transparent;
        color: ${techTheme.colors.black};
     
        &:hover {
            cursor: pointer;
            background: ${techTheme.colors.gs50};
        }
          
        &:focus {
           border: 3px solid ${techTheme.colors.black};
           background:  ${techTheme.colors.white};
           box-shadow: 0px 0px 0px 1px ${techTheme.colors.white};
           outline: none;
        }
        
        &:active {
            background:  ${techTheme.colors.gs50};
            border: 3px solid transparent;
        }
            
        &:disabled {
            cursor: not-allowed;
            color: ${techTheme.colors.gs100};
        }
    `};

    ${(props) =>
        props.variant === 'tonal' &&
        `
        border: 1px solid rgba(255, 255, 255, 0.15);
        outline: 2px solid transparent;
        color: ${techTheme.colors.white};
        background: transparent;
        
        &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.06);
            border: 1px solid transparent;
        }
          
        &:focus {
            box-shadow: 0px 0px 0px 2px ${techTheme.colors.white};
            border: 1px solid rgba(255, 255, 255, 0.15);
            outline: none;
        }
        
        &:active {
            border: 3spx solid transparent;
        }
            
        &:disabled {
            cursor: not-allowed;
            color: ${techTheme.colors.gs100};
            background: rgba(255, 255, 255, 0.06);
            border: none;
        }
    `};

    ${(props) =>
        props.variant === 'text' &&
        `
        background: none;
        border: 1px solid transparent;
        color: ${techTheme.colors.white};
        
        &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.15);
        }
          
        &:focus {
           border: 1px solid ${techTheme.colors.white};
           background: rgba(255, 255, 255, 0.06);
           outline: none;
        }
        
        &:active {
            background: rgba(255, 255, 255, 0.06);
            border: 1px solid transparent;
        }
            
        &:disabled {
            cursor: not-allowed;
            color: ${techTheme.colors.gs100};
        }
    `};
`;