import { useMemo } from 'react';
import type { IUser } from '@tacans/shared-marketplace-layer';
import { useIsWebpSupported } from 'src/hooks/use-is-webp-supported';

interface ReturnType {
    src?: string;
    retina?: string;
}

export const useAvatarAssets = ({ profile } : { profile?: IUser }): ReturnType => {
    const isWebp = useIsWebpSupported();

    return useMemo(() => {
        const { avatarAssets: assets } = profile || {};

        if (!assets?.webps?.smallUrl || !isWebp) {
            return {
                src: profile?.avatarAssets?.originalUrl,
                retina: profile?.avatarAssets?.originalUrl
            };
        }
        return {
            src: assets?.webps?.mediumUrl,
            retina: assets?.webps?.largeUrl,
        };
    }, [isWebp, profile]);
};
