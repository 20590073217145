import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategoryAPI } from '@tacans/shared-marketplace-layer';

// First, create the thunk
export const categoryFetchThunk = createAsyncThunk(
  'category/fetch',
  async () => {
    const response = await CategoryAPI.fetchTree();

    return response;
  }
);
