import { ExploreSort, IExploreArtsState } from './explore-arts.types';

export const INITIAL_FILTER_STATE: IExploreArtsState = {
    filter: {
        keywords: '',
        artistIds: [],
        ownerIds: [],
        priceRange: { from: '', to: '' },
    },
    sorting: ExploreSort.LATEST,
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {},
};