import { createSlice } from '@reduxjs/toolkit';

import { UniverseAdapter } from './universe.adapter';
import {
    universesThunk,
} from './universe.thunk';

const initialState = UniverseAdapter.getInitialState();

export const universeSlice = createSlice({
    name: 'universe',
    initialState,
    reducers: {
        clearUniverse: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(universesThunk.fulfilled, (state, action) => {
                UniverseAdapter.setAll(
                    state,
                    action.payload.data.universes?.filter(u => u.uiSettings?.visible) ?? []
                );
            });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = universeSlice;

export default universeSlice.reducer;
