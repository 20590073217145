import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { IChildProps } from 'src/features/common/app/components/header-search/header-search.types';
import {
    MobileInputWrapper,
    StyledCloseIcon,
    StyledIcon,
    StyledSearchIcon,
    StyledSearchIconInInput,
    StyledSearchInput,
    StyledSearchInputWrapper,
    StyledSearchTitle,
} from './header-search.styles';

interface ISearchInputLocale {
    placeholder: string;
}

export const Search: React.VFC<IChildProps> = ({ searchValue, onSubmit, onChange, onClear }) => {
    const inputRef = useRef<HTMLInputElement>();
    const { t } = useTranslation('pages');
    const locale: ISearchInputLocale = { placeholder: t('search.title', { returnObjects: true }) };
    const [opened, setOpened] = useState(false);

    const toggle = useCallback((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setOpened((s) => !s);
    }, []);

    useEffect(() => {
        if (opened) {
            inputRef.current?.focus();
        }
    }, [opened]);

    const onCloseClick = useCallback(
        (e) => {
            onClear();
            toggle(e);
        },
        [onClear, toggle]
    );

    return (
        <>
            {opened && (
                <OutsideClickHandler onOutsideClick={toggle}>
                    <MobileInputWrapper>
                        <StyledSearchTitle>Start typing and hit Enter</StyledSearchTitle>
                        <StyledSearchInputWrapper>
                            <StyledSearchIconInInput>
                                <StyledSearchIcon variant="regular" icon="search" fixed={opened} />
                            </StyledSearchIconInInput>

                            <form onSubmit={onSubmit}>
                                <StyledSearchInput
                                    ref={inputRef as any}
                                    value={searchValue}
                                    onChange={onChange}
                                    autoFocus={opened}
                                    placeholder={opened ? locale.placeholder : ''}
                                />
                            </form>
                        </StyledSearchInputWrapper>
                        <StyledCloseIcon variant="regular" icon="close" onClick={onCloseClick} />
                    </MobileInputWrapper>
                </OutsideClickHandler>
            )}
            <StyledIcon justifyContent="center" alignItems="center">
                <StyledSearchIcon variant="regular" icon="search" onClick={toggle} />
            </StyledIcon>
        </>
    );
};