import React, { useCallback } from 'react';
import { StyledButton } from './button.styles';
import { ButtonProps } from './button.types';

export const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            stretch = false,
            variant = 'white',
            size = 'md',
            children,
            testId,
            onMouseLeave,
            onMouseEnter,
            onClick,
            ...rest
        },
        ref
    ) => {
        const handleMouseLeave = useCallback(
            (e) => {
                onMouseLeave?.(e);
            },
            [onMouseLeave]
        );

        const handleMouseEnter = useCallback(
            (e) => {
                onMouseEnter?.(e);
            },
            [onMouseEnter]
        );

        const handleOnClick = useCallback(
            (e) => {
                onClick?.(e);
            },
            [onClick]
        );

        return (
            <StyledButton
                data-test-id={testId}
                ref={ref}
                stretch={stretch}
                size={size}
                variant={variant}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onClick={handleOnClick}
                {...rest}
            >
                {children}
            </StyledButton>
        );
    }
);

Button.displayName = 'Button';