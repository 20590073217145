import { combineReducers } from 'redux';
import bannerNfts from 'src/features/common/collectible/ducks/banner-nfts/banner-nfts.slice';
import collectibleBids from 'src/features/common/collectible/ducks/bids/bids.slice';
import trendingNfts from 'src/features/common/collectible/ducks/trending-nfts/trending-nfts.slice';
import highlightedCollections from 'src/features/common/collection/ducks/highlighted-collections/highlighted-collections.slice';
import collectionsList from 'src/features/common/collection/ducks/list/list.slice';
import collectionsStatistics from 'src/features/common/collection/ducks/statistics/statistics.slice';
import topCollections from 'src/features/common/collection/ducks/top-collections/top-collections.slice';
import universes from 'src/features/common/explore/ducks/universes/universes.slice';
import referrals from '../features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.slice';
import transactions from '../features/common/affiliate-program/ducks/get-transactions/get-transactions.slice';
import artist from '../features/common/artist/ducks/artist.slice';
import category from '../features/common/category/ducks/category/category.slice';
import collectible from '../features/common/collectible/ducks/collectible/collectible.slice';
import nftList from '../features/common/collectible/ducks/nft-list/nft-list.slice';
import nftSales from '../features/common/collectible/ducks/nft-sales/nft-sales.slice';
import collection from '../features/common/collection/ducks/collection/collection.slice';
import currency from '../features/common/currency/ducks/currency/currency.slice';
import exploreCollections from '../features/common/explore/ducks/collections/collections.slice';
import exploreLaunchpadNfts from '../features/common/explore/ducks/explore-launchpad-nfts/explore-launchpad-nfts.slice';
import exploreNfts from '../features/common/explore/ducks/explore-nfts/explore-nfts.slice';
import launchpads from '../features/common/explore/ducks/launchpads/launchpads.slice';
import users from '../features/common/explore/ducks/users/users.slice';
import influencerRefferals from '../features/common/influencer-affiliate-program/ducks/get-influencers-referrals/get-influencers-referrals.slice';
import launchpad from '../features/common/launchpad/ducks/launchpad/launchpad.slice';
import addresses from '../features/common/profile/ducks/addresses/addresses.slice';
import profile from '../features/common/profile/ducks/profile/profile.slice';
import publicProfile from '../features/common/profile/ducks/public-profile/public-profile.slice';
import search from '../features/common/search/ducks/search/search.slice';
import universeInfo from '../features/common/universe/ducks/universe-info/universe-info.slice';
import universeMine from '../features/common/universe/ducks/universe-mine/universe-mine.slice';
import universe from '../features/common/universe/ducks/universe/universe.slice';
import userList from '../features/common/user/ducks/user-list/user-list.slice';
import user from '../features/common/user/ducks/user/user.slice';
import wallet from '../features/common/wallet/ducks/wallet/wallet.slice';
import cart from '../features/white-labels/techcontemporary/cart/ducks/cart.slice';
import sales from '../features/white-labels/techcontemporary/profile/components/modals/sales-table-modal/ducks/sales.slice';
import artCollectible from '../features/white-labels/techcontemporary/collectible/ducks/collectible/collectible.slice';
import exploreArts from '../features/white-labels/techcontemporary/explore/ducks/explore-arts/explore-arts.slice';
import myArts from '../features/white-labels/techcontemporary/profile/containers/my-art-container/ducks/my-arts.slice';
import events from '../features/white-labels/techcontemporary/calendar/ducks/calendar.slice';
import referralUsers from '../features/common/affiliate-program/ducks/get-users/get-users.slice';

export default combineReducers({
    global: combineReducers({
        search,
        user,
        userList,
        nftList,
        currency,
        nftSales,
    }),
    features: combineReducers({
        collections: combineReducers({
            collection,
            list: collectionsList,
            highlightedCollections,
            topCollections,
            explore: exploreCollections,
            statistics: collectionsStatistics,
        }),
        users: combineReducers({
            list: users,
        }),
        artists: combineReducers({
            artist,
        }),
        exploreNfts,
        exploreLaunchpadNfts,
        profile: combineReducers({
            own: profile,
            publicProfile,
            addresses,
        }),
        nfts: combineReducers({
            nft: collectible,
            bids: collectibleBids,
            trendingNfts,
            bannerNfts,
        }),
        wallet,
        category,
        universe: combineReducers({
            list: universe,
            explore: universes,
            mine: universeMine,
            info: universeInfo,
        }),
        launchpads: combineReducers({
            launchpad,
            explore: launchpads,
        }),
        referrals,
        referralUsers,
        influencerRefferals,
        transactions,
        exploreArts,
        myArts,
        arts: combineReducers({
            art: artCollectible,
        }),
        cart,
        sales,
        events,
    }),
});