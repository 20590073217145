import { createSlice } from '@reduxjs/toolkit';

const SALES_INITIAL_STATE = {
    filter: 'price',
};

export const selesSlice = createSlice({
    name: 'salse',
    initialState: SALES_INITIAL_STATE,
    reducers: {
        setFilter(state, action) {
            state.filter = action.payload;
        },
    
    }
});

export const { actions } = selesSlice;
export default selesSlice.reducer;