import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { LaunchpadAdapter } from './launchpad.adapter';
import { fetchLaunchpadThunk } from './launchpad.thunk';

const getInitialState = () => LaunchpadAdapter.getInitialState();

export const launchpadSlice = createSlice({
    name: 'launchpads/launchpad',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        set: (state, action) => {
            LaunchpadAdapter.setOne(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.launchpads.launchpad,
            }))
            .addCase(fetchLaunchpadThunk.fulfilled, (state, action) => {
                if (action.payload.data.launchpad) {
                    LaunchpadAdapter.setOne(state, action.payload.data.launchpad);
                }
            });
    },
});

export const { actions } = launchpadSlice;

export default launchpadSlice.reducer;
