import { Draft, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { IGetLaunchpadsByFilterResponse, ILaunchpad } from '@tacans/shared-marketplace-layer';
import { LaunchpadsAdapter } from 'src/features/common/explore/ducks/launchpads/launchpads.adapter';
import { ILaunchpadsState } from 'src/features/common/explore/ducks/launchpads/launchpads.types';

export const setManyLaunchpads = (
    state: Draft<EntityState<ILaunchpad> & ILaunchpadsState>,
    action: PayloadAction<IGetLaunchpadsByFilterResponse>
) => {
    const { pager, launchpads = [] } = action.payload || {};
    const total = +(pager?.totalCount || 0);
    const page = +(pager?.page ?? 0);

    const newByPage = { ...state.byPage };
    const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

    if (launchpads.length) {
        if (!newByPage[page]) {
            newByPage[page] = [];
        }
    }

    launchpads.forEach((item) => {
        if (!(item.id in state.entities)) {
            newByPage[page].push(item.id);
        }
    });

    LaunchpadsAdapter.addMany(state, launchpads || []);
    state.total = total;
    state.byPage = newByPage;
    state.pages = newPages;
};
