import styled from 'styled-components';
import { speed } from 'src/ui/common/config/animations';

export const StyledWrapper = styled.div<{ active: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: visibility ${speed.content}, opacity ${speed.content};
  
  ${({ active }) => active && `
    visibility: visible;
    opacity: 1;
  `};
`;

export const StyledOverlay = styled.div`
  background: ${({ theme }) => theme.colors.overlay};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;