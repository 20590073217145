import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI, IRequestSetPassword, IConfirmUserEmail } from '@tacans/shared-marketplace-layer';

export const emailConfirmThunk = createAsyncThunk(
    'users/email-confirm',
    async (data: IConfirmUserEmail) => {
        const response = await UserAPI.confirmUserEmail(data);

        return { response };
    }
);

export const setNewPasswordThunk = createAsyncThunk(
    'users/reset-password-confirmation',
    async (data: IRequestSetPassword, { rejectWithValue }) => {
        const response = await UserAPI.setPassword(data);
        const { error } = response.data.responseMeta;
        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);