import { ThemeTypes } from 'src/features/common/app/components/theme-provider/theme.types';
import { NotificationTypeEnum } from 'src/services/notifications/types';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: fixed;
    right: 0px;
    bottom: 20px;
    z-index: 99999999;
    width: 100%;

    ${media.sm`
        right: 20px;
        width: auto;
  `}
`;

export const StyledNotification = styled.div<{ mounted: boolean; type: NotificationTypeEnum }>`
    position: relative;
    transform: translateX(200%);
    padding: 20px 35px 20px 20px;
    text-align: center;
    transition: transform ${speed.content} ease-in-out;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.gs500};
    border: 1px solid ${({ theme }) => (theme.name === ThemeTypes.LIGHT ? theme.colors.grey200 : theme.colors.gs400)};
    box-shadow: 0 24px 28px -10px ${({ theme }) => (theme.name === 'light' ? theme.colors.gs100 : 'rgba(5, 5, 5, 0.2)')};
    border-radius: 5px;
    width: 100%;
    max-width: 100vw;
    min-width: 200px;
    overflow: hidden;
    ${({ theme }) => theme.name === ThemeTypes.LIGHT && 'background: #ffffff'};

    ${media.sm`
    width: 430px;
  `}
    ${({ mounted }) =>
        mounted &&
        `
    transform: translateX(0);
  `}
  span {
        text-align: left;
        padding-right: 10px;
    }
`;

export const StyledCloseIcon = styled(SvgIcon)`
    position: absolute;
    top: 20px;
    right: 20px;
    user-select: none;
`;

export const StyledTypeIcon = styled.div<{ type: NotificationTypeEnum }>`
    flex: 0 0 24px;
    height: 24px;
    margin-right: 15px;
    border-radius: 50%;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(36, 218, 131, 0.15);

    & svg {
        color: ${({ theme }) => theme.colors.green500};
    }

    ${({ type, theme }) =>
        type === NotificationTypeEnum.error &&
        `
    background: rgba(255, 119, 252, 0.15);
    
    & svg {
      color: ${theme.colors.pink500}; 
    }
  `}
    ${({ type, theme }) =>
        type === NotificationTypeEnum.info &&
        `
    background: rgba(27, 186, 255, 0.15);
        
    & svg {
      color: ${theme.colors.orange500};
    }
  `}
  ${({ type, theme }) =>
        type === NotificationTypeEnum.warning &&
        `
    background: rgba(233, 179, 18, 0.15);
        
    & svg {
      color: ${theme.colors.pink500};
    }
  `}
`;

export const StyledProgress = styled.div<{ loaded: number }>`
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background: white;
    width: ${({ loaded }) => 100 - loaded}%;
`;

export const StyledText = styled(Typography)`
    margin-top: 10px;
`;