import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { GetTransactionsAdapter } from './get-transactions.adapter';
import { INITIAL_STATE } from './get-transctions.constants';
import { IGetTransactionsState } from './get-transactions.types';
import { fetchTransactionsThunk } from './get-transactions.thunk';

const getInitialState = () => GetTransactionsAdapter.getInitialState<IGetTransactionsState>(INITIAL_STATE);

export const transactionsSlice = createSlice({
    name: 'get-transactions',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            GetTransactionsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_STATE.pager };
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactionsThunk.fulfilled, (state, action) => {
                const { pager, referralTransactions = [] } = action.payload || {}; 
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (referralTransactions.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                referralTransactions.forEach((item) => {
                    if (item.txHash && !(item.txHash in state.entities)) {
                      // @ts-ignore
                      newByPage[page].push(item.txHash);
                    }
                  });

                GetTransactionsAdapter.addMany(state, referralTransactions || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.transactions,
            }));
    },
});

export const { actions } = transactionsSlice;
export default transactionsSlice.reducer;