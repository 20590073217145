import dynamic from 'next/dynamic';
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';

const AuthModalComponent = dynamic(() => import('./auth-modal'), {
    ssr: false,
});

export const AuthModal = modalRegistry.register({
    id: 'AuthModalComponent',
    component: AuthModalComponent,
});