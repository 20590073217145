/**
 * NanoPrice format is for Backend = value * 1e9
 * MicroCTU format is for CTU blockchain = value * 1e6
 */

export const makeId = (max: number = 1e5) => `${Math.floor(Math.random() * max)}`;
export const makeTokenId = (val: number, base = 1000000) => base + val;
export const makeNanoPrice = (val: number, nano = 1e9) => Math.round(val * nano);
export const makePriceByNano = (val: number, nano = 1e9) => val / nano;
export const makNanoPercentage = (val: number, nano = 1) => val * nano;
export const makRoyalties = (percent: number) => percent * 100;
export const makeMicroGTU = (val: number, isNano?: boolean) => (
    Math.round(isNano ? val/1000 : val*1e6)
);
export const makeNanoPriceFromMicroGTU = (val: number) => (
    Math.round(val*1e3)
);
export const makePriceInCents = (val: number) => Math.round(val * 100);

export const formatNumberToNumGroup = (num: number, digits: number, skipThousand = false) => {
    if (num < 1) {
        return `${num}`;
    }
    const skipValue = { value: 1, symbol: '' };
    const numGroup = [
        skipValue,
        skipThousand ? skipValue : { value: 1e3, symbol: 'K' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const item = numGroup.slice().reverse().find((item) => num >= item.value);
    return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

const formatPriceToNumber = (price: string | number) => {
    if(typeof price === 'number') {
        return price;
    }
    return +(price || '0').replace(/[^\d.-]/g, '');
};

export const formatToMillionGroup = (price: string | number) => {
    const formattedToNumber = formatPriceToNumber(price);
    if(formattedToNumber > 1e6) {
        const formatter = Intl.NumberFormat('en', { notation: 'compact' } );
        return formatter.format(formattedToNumber);
    }
    return price;
};

export const makePriceFromCents = (val: number) => val / 100;

export const secondsToMilliseconds = (val:number|string|undefined) => {
    const number = Number(val);
    return number ? number * 1000 : 0;
};

export const formatCurrency = (value?: string | number, fractionDigits = 8, symbol?: string) => {
    let [number, rest] = Number(value || 0).toFixed(10).toString().split('.');
    number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (rest) {
        rest = rest.substr(0, fractionDigits).replace(/[0]+$/g, '');
    }
    const res = rest ? `${number}.${rest}` : number;
    return symbol ? `${symbol} ${res}` :res;
};

export function calculateFee(amount: number | string, fee: number) {
    return (+amount * fee) / 100;
}