import { createSelector } from '@reduxjs/toolkit';
import { GetAllReferralsAdapter } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.adapter';
import { RootState } from 'src/store';

export const getAllReferralsSelect = (state: RootState) => state.features.referrals;

export const getAllReferralsSelectors = GetAllReferralsAdapter.getSelectors();

export const selectFilter = createSelector(getAllReferralsSelect, (state) => state.filter);

export const selectPager = createSelector(getAllReferralsSelect, (state) => state.pager);

export const selectReferralsEntities = createSelector(getAllReferralsSelect, (state) =>
    getAllReferralsSelectors.selectEntities(state)
);

export const selectByPage = createSelector(getAllReferralsSelect, (state) => state.byPage);

export const selectTotal = createSelector(getAllReferralsSelect, (state) => state.total);

export const selectPages = createSelector(getAllReferralsSelect, (state) => state.pages);

export const selectVisibleTotal = createSelector(getAllReferralsSelect, (state) => state.visibleTotal);

export const selectLoading = createSelector(getAllReferralsSelect, (state) => state.loading);