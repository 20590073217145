import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteE } from 'src/constants/pages.constants';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { CartItemComponent } from 'src/features/white-labels/techcontemporary/cart/components/cart-dropdown/cart-item-component';
import { selectCartTotal } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.selectors';
import { actions as CartActions } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.slice';
import { CartItem } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.types';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { Typography } from 'src/ui/common/atoms/typography';
import { Button } from 'src/ui/white-labels/techcontemporary/atoms/button';
import {
    CartDropDownMenu,
    CartDropDownTitle,
    StyledScrollWrapper,
    StyledTotal,
    StyledTotalNumber,
} from './cart-dropdown.styles';

interface Props {
    locale: Record<string, string>;
    cartOpened: boolean;
    cartItems: CartItem[];
}

export const CartDropDown: React.FC<Props> = ({ locale, cartOpened, cartItems }) => {
    const cartTotal = useSelector(selectCartTotal);
    const dispatch = useDispatch();
    const isCartEmpty = cartItems.length === 0;
    const router = useRouter();
    const authorized = useAuthorized();

    const checkoutHandler = async () => {
        if (authorized) {
            await router.push(RouteE.CHECKOUT);
            dispatch(CartActions.setIsCartOpen(false));
        }
    };

    return (
        <CartDropDownMenu opened={cartOpened}>
            <Spacing mb="32px">
                <FlexBox alignItems="center">
                    <CartDropDownTitle variant="title" color="text">
                        {locale.shoppingCart} ( {cartItems.length} )
                    </CartDropDownTitle>
                </FlexBox>
            </Spacing>

            {!isCartEmpty && (
                <StyledScrollWrapper gap="24" flexDirection="column">
                    {cartItems.map((item) => (
                        <CartItemComponent cartItem={item} key={item.digitalArtLotId} />
                    ))}
                </StyledScrollWrapper>
            )}

            {!isCartEmpty && (
                <>
                    <StyledTotal justifyContent="space-between" alignItems="center">
                        <Typography color="lightGrey" size="sm" medium>
                            {locale.subtotal}
                        </Typography>
                        <StyledTotalNumber medium color="white" variant="title">
                            €{cartTotal}
                        </StyledTotalNumber>
                    </StyledTotal>
                    <Spacing mt="32px">
                        <Button stretch onClick={checkoutHandler} disabled={!authorized}>
                            {locale.checkout}
                        </Button>
                    </Spacing>
                </>
            )}
            {isCartEmpty && (
                <Spacing padding="0 16px 16px 16px">
                    <Typography color="grey300" size="sm" variant="title">
                        Shopping cart is empty
                    </Typography>
                </Spacing>
            )}
        </CartDropDownMenu>
    );
};