import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { GetAllUsersAdapter } from './get-users.adapter';

export const getAllUsersSelect = (state: RootState) => state.features.referralUsers;

export const getAllUsersSelectors = GetAllUsersAdapter.getSelectors();

export const selectFilter = createSelector(getAllUsersSelect, (state) => state.filter);

export const selectPager = createSelector(getAllUsersSelect, (state) => state.pager);

export const selectUsersEntities = createSelector(getAllUsersSelect, (state) =>
getAllUsersSelectors.selectEntities(state)
);

export const selectByPage = createSelector(getAllUsersSelect, (state) => state.byPage);

export const selectTotal = createSelector(getAllUsersSelect, (state) => state.total);

export const selectPages = createSelector(getAllUsersSelect, (state) => state.pages);

export const selectVisibleTotal = createSelector(getAllUsersSelect, (state) => state.visibleTotal);

export const selectLoading = createSelector(getAllUsersSelect, (state) => state.loading);