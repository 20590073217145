import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ResponseMetaStatusE,
    IGetWallets,
    IBlockchain,
    WalletProviderE,
    ICoin,
} from '@tacans/shared-marketplace-layer';

import { WalletAdapter } from './wallet.adapter';
import {
    addWalletThunk, fetchBlockChainsThunk, fetchWalletsThunk, fetchCoinsThunk
} from './wallet.thunk';


type IState = {
    hasNew: boolean;
    error: string;
    loaded: boolean;
    blockchains: IBlockchain[];
    coins: Record<IBlockchain['id'], ICoin[]>;
}

const initialState = WalletAdapter.getInitialState({
    hasNew: false,
    error: '',
    loaded: false,
    blockchains: [] as IBlockchain[],
    coins: {},
} as IState);

export const walletSlice = createSlice({
    name: 'wallets',
    initialState,
    reducers: {
        clear: () => initialState,
        hasNewClear: (state) => {
            state.hasNew = false;
        },
        clearError: (state) => {
            state.error = '';
        },
        clearWallets: (state) => {
            WalletAdapter.removeAll(state);
            state.loaded = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchBlockChainsThunk.fulfilled,
            (state, action) => {
                state.blockchains = action.payload.blockchains;
            }
        );
        builder.addCase(
            fetchCoinsThunk.fulfilled,
            (state, action) => {
                action.payload.coins?.forEach(coin => {
                    if (!state.coins[+coin.blockchainId]) {
                        state.coins[+coin.blockchainId] = [];
                    }
                    state.coins[+coin.blockchainId].push(coin);
                });
            }
        );
        builder.addCase(
            fetchWalletsThunk.fulfilled,
            (state, action: PayloadAction<IGetWallets>) => {
                action.payload.wallets &&
                    WalletAdapter.setAll(state, action.payload.wallets);
                state.loaded = true;
            }
        );
        builder.addCase(addWalletThunk.fulfilled, (state, action) => {
            const responseMeta = action.payload.response.data?.responseMeta;
            // @ts-ignore
            if (responseMeta?.status !== ResponseMetaStatusE.OK) {
                // @ts-ignore
                state.error = responseMeta?.error?.messages?.[0] || responseMeta?.status;
            } else {
                state.hasNew = true;
            }
        });
        builder.addCase(addWalletThunk.rejected, (state, action) => {
            state.error = action.payload as any;
        });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = walletSlice;

export default walletSlice.reducer;
