import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useNotifications } from 'src/services/notifications/use-notifications';
import { useTranslation } from 'next-i18next';

export const useInputSearch = () => {
    const router = useRouter();
    const { t } = useTranslation(['common']);
    const { error: errorNotification } = useNotifications();

    return useCallback(
        async (query: string) => {
            if (query.trim().length < 2) {
                errorNotification({
                    text: t('messages.minimumSearchQuery', { min: 2 }),
                });

                return;
            }

            await router.push(`/search?query=${query}`);
        },
        [router, errorNotification, t]
    );
};

export const useClearSearchInput = () => {
    const router = useRouter();
    return useCallback(
        async () => {
            await router.push('/search?query=');
        },
        [router]
    );
};
