import { useEffect, useCallback, useRef } from 'react';

export const useIsMounted = (): (callback?: () => void) => boolean => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  
  return useCallback((callback): boolean => {
    mountedRef.current && callback?.();
    return mountedRef.current;
  }, []);
};

// const isMounted = useIsMounted();
// isMounted(() => {do something}) or if(isMounted()) { do something }
