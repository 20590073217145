import { createSlice } from '@reduxjs/toolkit';

import { TopCollectionsAdapter } from './top-collections.adapter';
import { topCollectionsFetchThunk } from './top-collections.thunk';

const getInitialState = () => TopCollectionsAdapter.getInitialState({
    isLoading: false,
});

export const topCollectionsSlice = createSlice({
    name: 'collections/topCollections',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(topCollectionsFetchThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(topCollectionsFetchThunk.fulfilled, (state, action) => {
                TopCollectionsAdapter.setMany(state, action.payload.collections || []);
                state.isLoading = false;
            })
            .addCase(topCollectionsFetchThunk.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { actions } = topCollectionsSlice;

export default topCollectionsSlice.reducer;
