import { RequestPagerI, NFTI } from '@tacans/shared-marketplace-layer';

export interface IPriceRange {
    from: string | null;
    to: string | null;
}

export enum ExploreSort {
    LATEST = 'latest',
    OLDEST = 'oldest',
    PRICE_ASC = 'price_asc',
    PRICE_DESC = 'price_desc',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
    QUANTITY_DESC = 'quantity_desc',
    QUANTITY_ASC = 'quantity_asc',
}

export interface IExploreFilters {
    categoryId?: string[];
    collectionId?: string[];
    ownerIds?: string[];
    artistCountry?: string[];
    artistId?: string[];
    blockchainId?: number[];
    ownerId?: string | string[];
    authorId?: string | string[];
    sellType?: string[];
    standard?: NFTI['standard'][];
    keywords?: string;
    type?: string;
    priceRange?: IPriceRange;
    isPhysical?: boolean[];
}

export interface IExploreNftsFilter {
    filter: IExploreFilters;
    sorting: ExploreSort;
    pager: RequestPagerI;
}

export interface IExploreNftsState extends IExploreNftsFilter {
    loading: boolean;
    total: number;
    /**
     *  when infinite scroll disabled - prevent loading new, set limited total
     */
    visibleTotal: number;
    pages: number[];
    byPage: Record<number, NFTI['id'][]>
}
