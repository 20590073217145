import { Button } from 'src/ui/common/atoms/button';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import styled, { DefaultTheme } from 'styled-components';

export const StyledHeader = styled(FlexBox)<{ isDefault: boolean }>`
    position: relative;
    z-index: 9;
    height: ${({ theme }) => theme.constants.headerMbHeight}px;
    background: ${({ isDefault, theme }) => (isDefault ? theme.colors.warmBlack : 'rgba(255, 255, 255, 0.03)')};
    backdrop-filter: blur(15px);
    justify-content: space-between;

    ${({ isDefault, theme }) => isDefault && ` border-bottom: 1px solid ${theme.colors.gs400};`}

    ${media.md`
        height: ${({ theme }: { theme: DefaultTheme }) => theme.constants.headerDskHeight}px;
    `}
`;

export const StyledLogo = styled(FlexBox)<{ isDefault: boolean }>`
    width: 70px;
    height: 100%;
    user-select: none;

    ${({ isDefault, theme }) => isDefault && ` border-right: 1px solid ${theme.colors.gs400};`}
    & img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        max-width: calc(100% - 10px);
        max-height: calc(100% - 10px);
    }

    & a {
        display: block;
        height: 100%;
        width: 100%;
        font-size: 0;
        position: relative;
    }

    ${media.md`
    width: 100px;
  `}
`;

export const StyledIcon = styled(FlexBox)`
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.gs500};
    border-radius: 5px;
    user-select: none;
`;

export const UserDropDownIcon = styled(StyledIcon)`
    background-color: ${({ theme }) => theme.colors.warmBlack};
    border: 1px solid ${({ theme }) => theme.colors.gs300};

    ${media.md`
    display: none;
  `};
`;

export const StyledLoginButton = styled(Button)`
    margin: 0 5px 0 0;

    ${media.md`
    margin: 0 10px 0 0;
  `}
`;

export const UserMenuWrapper = styled(FlexBox)`
    margin-right: 10px;

    ${media.md`
    margin-left: auto;
    
    & button {
      height: 50px;
      padding: 0 20px;
    }
  `}
`;

export const StyledDropDown = styled.div<{ opened: boolean }>`
    position: absolute;
    padding: 20px;
    right: 0;
    top: ${({ theme }) => theme.constants.headerMbHeight}px;
    bottom: 0;
    z-index: 9;
    width: 100vw;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.gs500};
    transform: ${({ opened }) => (opened ? 'translateY(0)' : 'translateY(-10%)')};
    opacity: ${({ opened }) => (opened ? '1' : '0')};
    visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
    transition: transform ${speed.content} cubic-bezier(0.4, 0, 0.6, 1) 0ms, opacity ${speed.content} ease-in,
        visibility ${speed.content} ease-in;

    ${media.md`
    padding: 15px;
    top: ${({ theme }: { theme: DefaultTheme }) => theme.constants.headerDskHeight}px;
    width: 295px;
    bottom: auto;
  `}
`;

export const StyledLinksVisible = styled.div`
    height: 100%;
    display: none;

    ${media.md`
        display: block;
  `}
`;

export const DesktopLink = styled.a<{ active?: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 14px;
    user-select: none;
    transition: all ${speed.content} ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
        color: white !important;
    }

    & + & {
        margin-left: 20px;
    }

    ${({ active, theme }) => active && `border-bottom: 2px solid ${theme.colors.pink500};`}
`;

export const StyledDesktopLinks = styled(FlexBox)`
    margin: 0 20px 0 40px;
    height: 100%;

    &:hover ${DesktopLink} {
        color: ${({ theme }) => theme.colors.gs200};
    }
`;

export const StyledSocialLink = styled.a`
    color: white;
    display: block;

    & > div {
        min-width: 150px;
        border-radius: 5px;
        height: 40px;
        padding: 0 5px 0 10px;
        transition: background-color ${speed.content} ease-in-out;
    }

    & > div:hover {
        background-color: ${({ theme }) => theme.colors.gs450};

        svg {
            color: white;
        }
    }

    & svg {
        color: ${({ theme }) => theme.colors.gs400};
        transition: color ${speed.content} ease-in-out;
        margin-left: 20px;
    }
`;

export const StyledDivider = styled.div`
    margin-right: 5px;

    ${media.sm`
        margin-right: 20px;
    `};

    &:before {
        content: '';
        display: block;
        width: 1px;
        background-color: ${({ theme }) => theme.colors.gs200};
        height: 40px;
        opacity: 0.3;
    }
`;