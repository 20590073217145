import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import { ModalRegistryEntityItem } from '../../modal.types';
import { ModalContext } from '../../providers/modal.context';
import { modalRegistry } from '../../providers/modal.registry';
import ModalComponent from '../modal-component';
import { StyledOverlay, StyledWrapper } from './styles';

export const ModalManagerProvider: React.FC = ({ children }) => {
    const [modals, setModals] = useState<ModalRegistryEntityItem[]>([]);

    const isActive = useMemo(() => modals.find((modal) => modal.opened), [modals]);

    const contextValue = useMemo(
        () => ({
            openedModals: modals,
        }),
        [modals]
    );

    const preventDefault = useCallback((e) => {
        if (!e.target.closest?.('[data-element="modals-wrapper"]')) {
            e.preventDefault();
        }
    }, []);

    useLayoutEffect(() => {
        modalRegistry.subscribe(setModals);

        return () => {
            modalRegistry.unsubscribe(setModals);
        };
    }, []);

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.addEventListener('touchstart', preventDefault);
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
            document.body.removeEventListener('touchstart', preventDefault);
        }
    }, [isActive, preventDefault]);

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            {!!modals.length &&
                ReactDOM.createPortal(
                    <StyledWrapper active={!!isActive} data-element="modals-wrapper">
                        <StyledOverlay id="overlay" />
                        {modals.map((modal) => (
                            <ModalComponent modal={modal} key={modal.id} />
                        ))}
                    </StyledWrapper>,
                    document.body
                )}
        </ModalContext.Provider>
    );
};