export enum RouteE {
    HOME = '/',
    EXPLORE = '/explore',
    FAQ = '/faq',
    MARKETPLACE = '/marketplace',
    LOGIN = '/login',

    COLLECTIBLE_CREATE = '/nft/create',
    COLLECTIBLE_CREATE_BY_TYPE = '/nft/create/:createType',

    COLLECTIBLE_DETAILS = '/nft/:id',
    COLLECTIBLE_EDIT = '/nft/:id/edit',

    COLLECTION_CREATE = '/collection/create',
    COLLECTION_EDIT = '/collection/edit/:id',
    COLLECTIONS_LIST = '/collections',
    COLLECTION_VIEW = '/collections/:id',

    LAUNCHPAD_CREATE = '/launchpad/create',
    LAUNCHPAD_EDIT = '/launchpad/:id/edit',

    AUCTION_APPROVALS = '/auctions-approvals',

    PLACEHOLDER = '#',

    PRIVACY = '/privacy',

    PROFILE = '/profile/:login',
    PROFILE_EDIT = '/profile/edit',
    PROFILE_PERSONAL_INFORMATION = '/profile/profile-information',
    PROFILE_SOCIAL = '/profile/social',
    PROFILE_NOTIFICATIONS = '/profile/notifications',
    PROFILE_MANAGE_WALLET = '/profile/manage-wallet',
    PROFILE_PASSWORD = '/profile/secure',
    AFFILIATE_PROGRAM = '/profile/affiliate-program',

    LAUNCHPADS = '/launchpads',
    LAUNCHPAD_DETAILS = '/launchpad/:id',

    SEARCH = '/search?query=:query',

    TERMS = '/terms',

    USER_EDIT = '/account-settings',

    UNIVERSES = '/nftstores',
    UNIVERSE_WIZARD = '/nftstore/creator',
    UNIVERSE_SETTINGS = '/nftstore/settings',
    UNIVERSE_CREATORS = '/nftstore/creators',
    UNIVERSE_CREATORS_EDIT = '/creators/:id/edit',
    CREATOR_PROFILE = '/creator/:id',
    CREATOR_CREATE = '/creators/create',
    MANAGE_AFFILIATES = '/affiliate-program/manage-affiliates',
    AFFILIATES_STATISTIC = '/affiliate-program/affiliates-statistic',
    CHECKOUT = '/checkout',
    ART_DETAILS = '/art/:id',
    EDIT_ART = '/art/:id/edit',
    MY_ART = '/profile/my-art/:login',
    SECURITY = '/profile/security',
    ART_CREATE = '/art/create',
    ARTISTS = '/artists',
    CALENDAR = '/calendar',
    ABOUT = '/about',
    BILLING_DETAILS = '/profile/billing-details',
    SUCCESS = '/success',
    CANCEL = '/cancel',
    IMPRINT = '/imprint',
}

/**
 * Universal route generator.
 * This method creates URI string based on provided params
 * @param route URI string to be modified.
 * Params should be provided with semicolon prefix.
 * Example: "https://example.com/:id"
 * @param params params object, where key is param name, and value is param value.
 * Example: {firstName: "John", lastName: "Doe"}
 */
export const generatePath = (route: string, params: { [key: string]: string | number }) =>
    Object.keys(params).reduce((acum, paramKey) => acum.replace(`:${paramKey}`, String(params[paramKey])), route);
