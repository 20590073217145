import { createAsyncThunk } from '@reduxjs/toolkit';
import { LaunchpadAPI } from '@tacans/shared-marketplace-layer';
import { useCreateLaunchpadsRequestPayload } from 'src/features/common/explore/hooks/use-create-launchpads-request-payload';
import { RootState } from 'src/store/store';
import { selectFilter, selectPager, selectSorting } from './launchpads.selectors';

export const fetchLaunchpadsThunk = createAsyncThunk('launchpads/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const sort = selectSorting(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);
    const payload = useCreateLaunchpadsRequestPayload({
        sort,
        filter,
        pager,
    });
    const { data } = await LaunchpadAPI.getByFilter(payload);
    return data;
});