import { useCallback, useContext, useState } from 'react';
import {
    NotificationI,
    NotificationAPI,
    PagerI,
    PagerRequestI,
    LotI,
    IBid,
    LotAPI
} from '@tacans/shared-marketplace-layer';
import { useFetchNftList, useNftListEntities } from 'src/features/common/collectible/hooks/nft-list.hooks';
import { useIsMounted } from 'src/hooks/useIsMounted';
import { NotificationsContext } from '../notifications.context';

const defaultPagerRequest = {
    page: 0,
    limit: 10
};

export const useNotificationsContext = () => useContext(NotificationsContext);

export const useFetchNotifications = () => {
    const [notifications, setNotifications] = useState<NotificationI[]>([]);
    const [pager, setPager] = useState<PagerI>({ totalCount: '0', page: '0', limit: '0' });
    const [error, setError] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    const isMounted = useIsMounted();
    const fetchNftList = useFetchNftList();
    const nftListEntities = useNftListEntities();

    const fetch = useCallback(async (pager?: PagerRequestI) => {
        let activeAuctions = [] as LotI[];
        const lastBidsByLot = [] as IBid[];
        setLoading(true);
        try {
            await NotificationAPI.fetchMyActiveAuctions(pager || defaultPagerRequest)
                .then((response) => {
                    isMounted(() => {
                        activeAuctions = [...response?.data?.lots];
                        setPager(response?.data?.pager);
                    });
                });

            await Promise.all(activeAuctions.map(lot => LotAPI.fetchBidsByLot({
                lotId: lot.id,
                pager: { page: 0, limit: 100 }
            }))).then((results) => {
                results.forEach(result => {
                    const { data: { bids } } = result;
                    bids && lastBidsByLot.push(bids[0]);
                });
            });

            await fetchNftList(activeAuctions.map(lot => lot.nftId));

            // @ts-ignore
            !activeAuctions.some(lot => !nftListEntities[lot.nftId]) && setNotifications(activeAuctions.map(lot => ({
                lotNft: { lot, nft: nftListEntities[lot.nftId] },
                bid: lastBidsByLot.find(bid => bid.lotId === lot.id)
            })));

            setLoading(false);

        } catch (e) {
            isMounted(() => {
                setError(e);
                setLoading(false);
            });
        }

    }, [isMounted, fetchNftList, nftListEntities]);

    return { notifications, fetch, error, pager, isLoading };
};

export const useHasNotifications = () => {
    const { activeAuctionsCount } = useNotificationsContext();

    return +activeAuctionsCount > 0;
};
