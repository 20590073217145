import { RequestPagerI, ILaunchpadsFilter, ILaunchpad } from '@tacans/shared-marketplace-layer';

export interface LaunchpadsFilter {
    filter: ILaunchpadsFilter;
    sorting: LaunchpadsSort;
    pager: RequestPagerI;
}

export enum LaunchpadsSort {
    LATEST = 'latest',
    OLDEST = 'oldest',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
}

export enum LaunchpadNftsSort {
    LATEST = 'latest',
    OLDEST = 'oldest',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
    PRICE_TO_HIGH = 'price_asc',
    PRICE_TO_LOW = 'price_desc',
}

export enum ProfileLaunchpadsSort {
    PRICE_TO_HIGH = 'price_asc',
    PRICE_TO_LOW = 'price_desc',
    LATEST = 'latest',
    OLDEST = 'oldest'
}

export interface ILaunchpadsState extends LaunchpadsFilter {
    loading: boolean;
    total: number;
    exploreTotal: number;
    pages: number[];
    byPage: Record<number, ILaunchpad['id'][]>
}
