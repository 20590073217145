import { RequestPagerI } from '@tacans/shared-marketplace-layer';
import { IReferralFilters } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.types';
import { createFilterFieldsPayload } from 'src/features/common/affiliate-program/get-all-referrals-api.utils';

export const useGetAllReferralsRequestPayload = ({
    id,
    pager,
    filter,
}: {
    id: string;
    filter?: IReferralFilters;
    pager?: RequestPagerI;
}) => ({
    id,
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
    filter: createFilterFieldsPayload(filter || {}),
});