import dynamic from 'next/dynamic';
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';

const DisclaimerModalComponent = dynamic(
    () => import('./disclaimer-modal-component').then((d) => d.DisclaimerModalComponent) as any,
    {
        ssr: true,
    }
);

export const DisclaimerModal = modalRegistry.register({
    id: 'DisclaimerModal',
    component: DisclaimerModalComponent,
});