import { useCallback } from 'react';
import { NFTI } from '@tacans/shared-marketplace-layer';

import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import {
    selectNftList,
    fetchNftByIdsThunk,
    selectNftListEntities
} from 'src/features/common/collectible/ducks/nft-list';

export const useFetchNftList = () => {
    const dispatch = useAppDispatch();
    const nftListEntities = useNftListEntities();

    return useCallback(async (ids: NFTI['id'][]) => {
        const filtered = ids.filter(id => id && !nftListEntities[id]);
        if (filtered.length) {
            await dispatch(fetchNftByIdsThunk([...new Set(filtered)]));
        }
    }, [dispatch, nftListEntities]);
};

export const useFetchNft = () => {
    const fetchNftList = useFetchNftList();

    return useCallback(async (ids: NFTI['id']) => {
        await fetchNftList([ids]);
    }, [fetchNftList]);
};

export const useNftListEntities = () => useAppSelector(selectNftListEntities);
export const useNftList = () => useAppSelector(selectNftList);