/**
 * Libs
 */
import dynamic from 'next/dynamic';

/**
 * Features
 */
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';

/**
 * Types
 */
import { InfoModalProps } from './info-modal.types';

const InfoModalComponent = dynamic<InfoModalProps>(
    () => import('./info-modal-component').then((d) => d.InfoModalComponent) as any,
    {
        ssr: false,
    }
);

export const InfoModal = modalRegistry.register({
    id: 'Info',
    component: InfoModalComponent,
});
