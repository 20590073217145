import React from 'react';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { StyledWrapper } from 'src/ui/common/atoms/icon-box/styles';
import { IIconBoxProps } from 'src/ui/common/atoms/icon-box/types';

export const IconBox: React.VFC<IIconBoxProps> = ({
    variant = 'regular',
    icon,
    onClick,
    size = 40,
    sizeSm,
    sizeMd,
    iconSize = 18
}) => (
    <StyledWrapper
        alignItems='center'
        justifyContent='center'
        onClick={onClick}
        size={size}
        sizeSm={sizeSm}
        sizeMd={sizeMd}
    >
        {/* @ts-ignore */}
        <SvgIcon variant={variant} icon={icon} width={iconSize} />
    </StyledWrapper>
);
