import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReferralsFilters } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';
import { GetAllUsersAdapter } from './get-users.adapter';
import { INITIAL_FILTER_STATE } from './get-users.constants';
import { IGetAllUsersState } from './get-users.types';
import { fetchAllUsersThunk } from './get-users.thunk';

const getInitialState = () => GetAllUsersAdapter.getInitialState<IGetAllUsersState>(INITIAL_FILTER_STATE);

export const refferalUsersSlice = createSlice({
    name: 'get-all-users-referrals',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            GetAllUsersAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<IReferralsFilters>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<IReferralsFilters>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUsersThunk.fulfilled, (state, action) => {
                const { pager, users = [] } = action.payload || {};
               
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (users.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                users.forEach((item) => {
                     // @ts-ignore
                    if (!(item.id in state.entities)) {
                        // @ts-ignore
                        newByPage[page].push(item.id);
                    }
                });

                GetAllUsersAdapter.addMany(state, users || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.referralUsers,
            }));
    },
});

export const { actions } = refferalUsersSlice;
export default refferalUsersSlice.reducer;