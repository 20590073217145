import { useCallback } from 'react';
import {
    universeGetMineThunk,
    selectUniverseMine,
    selectUniverseMineLoading,
} from 'src/features/common/universe/ducks/universe-mine';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';

export const useUniverseGetMineFetch = () => {
    const dispatch = useAppDispatch();
    const universeMine = useUniverseMine();

    return useCallback(async () => {
        if (!universeMine) {
           await dispatch(universeGetMineThunk());
        }
    }, [dispatch, universeMine]);
};

export const useUniverseMine = () => useAppSelector(selectUniverseMine);
export const useUniverseMineLoading = () => useAppSelector(selectUniverseMineLoading);
