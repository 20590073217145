export const FONT_SIZE = {
    '8xl': '186px',
    '7xl': '149px',
    '6xl': '119px',
    '5xl': '95px',
    '4xl': '76px',
    '3xl': '61px',
    '2xl': '49px',
    'xl': '39px',
    'lg': '31px',
    'xmd': '28px',
    'md': '25px',
    'base': '20px',
    'sm': '16px',
    'xs': '14px',
    'xxs': '12px'
};
