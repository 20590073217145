import { RequestPagerI, UniversesItem } from '@tacans/shared-marketplace-layer';

export interface IUniversesFilter {
    filter: {
        keywords?: string
    };
    sorting: UniversesSort;
    pager: RequestPagerI;
}

export enum UniversesSort {
    LATEST = 'latest',
    OLDEST = 'oldest',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
}


export interface IUniversesState extends IUniversesFilter {
    loading: boolean;
    total: number;
    pages: number[];
    byPage: Record<number, UniversesItem['id'][]>
}
