import getConfig from 'next/config';
import { useMemo } from 'react';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { getProjectKey } from 'src/services/universe/universe-utils';
import { useUniverseInfo } from './universe-info.hooks';

const { publicRuntimeConfig } = getConfig();

const isLocal = publicRuntimeConfig.NEXT_PUBLIC_ENV === 'local';

export const useUniverse = () => {
    const { name, baseUrl, ownerId, settings, taxPercent, id, affiliateProgram } = useUniverseInfo() || {};
    const { user } = useProfile() ?? {};

    const projectKey = useMemo(() => getProjectKey(name), [name]);

    const isUniverse = projectKey !== 'spaceseven';
    const projectName = isUniverse ? name || '' : 'Spaceseven';
    const defaultMetaDescription = `${projectName} is a multi-chain NFT platform that empowers you to create, sell, and buy NFTs. Explore and create your own NFTs today!`;

    return {
        id,
        isUniverse,
        ownerId,
        taxPercent: taxPercent ?? '600',
        isUniverseOwner: user?.id && ownerId && user?.id === ownerId,
        projectName,
        project: projectKey || 'spaceseven',
        baseUrl: isLocal ? 'http://localhost:8888' : baseUrl,
        logo: settings?.logo,
        ogImage: settings?.ogImage,
        domain: settings?.domain,
        socialNetworks: settings?.socialNetworks,
        favicon: settings?.favicon,
        affiliateProgram,
        // @ts-ignore
        metaDescription: defaultMetaDescription,
        bridgeConnectionKey: settings?.bridgeConnectionKey,
        visible: settings?.visible,
        settings,
        metaTitle: projectName,
    };
};