import { IGetAllUsersState } from './get-users.types';

export const INITIAL_FILTER_STATE: IGetAllUsersState = {
    filter: {
        keywords: '',
        blockchainId: [],
        status: 0,
    },
    pager: {
        page: 0,
        limit: 100,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {},
};