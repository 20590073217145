import dayjs from 'dayjs';
import { LotTypeE, StandardTypeE, PurchaseTypeE } from '@tacans/shared-marketplace-layer';

export const getLotType = (type: LotTypeE) => ({
    isSingle: type === LotTypeE.FIXED || type === LotTypeE.AUCTION,
    isMultiple: type === LotTypeE.MULTIPLE ||
        type === LotTypeE.MULTIPLE_AUCTION,
    isFixedPrice: type === LotTypeE.FIXED || type === LotTypeE.MULTIPLE,
    isAuction: [
        LotTypeE.FAUCTION,
        LotTypeE.AUCTION,
        LotTypeE.MULTIPLE_AUCTION
    ].includes(type),
});

export const getDetailedLotType = (type: string) => ({
    isSingleFixed: type === LotTypeE.FIXED,
    isMultipleFixed: type === LotTypeE.MULTIPLE,
    isSingleAuction: type === LotTypeE.AUCTION,
    isMultipleAuction: type === LotTypeE.MULTIPLE_AUCTION,
});

export const getLotTypeByPurchaseType = (
    isSingle: boolean,
    purchaseType: PurchaseTypeE,
): LotTypeE => {
    let type = LotTypeE.FIXED;

    if (isSingle && purchaseType === PurchaseTypeE.TIMED_AUCTION) {
        type = LotTypeE.AUCTION;
    }

    if (!isSingle) {
        type =
            purchaseType === PurchaseTypeE.TIMED_AUCTION
                ? LotTypeE.MULTIPLE_AUCTION
                : LotTypeE.MULTIPLE;
    }
    return type;
};

export const getNftTypeByStandard = (standard: StandardTypeE) => ({
    isSingle: StandardTypeE.SINGLE === standard,
    isMultiple: StandardTypeE.MULTIPLE === standard
});

export const getAuctionStatus = (
    startDate: string | undefined, endDate: string | undefined
) => {
    if (!startDate || !endDate) {
        return {
            isAuctionStarted: false,
            isAuctionFinished: false,
            isAuctionNotEnded: false,
            isAuctionInProgress: false,
        };
    }

    const isAuctionStarted =  dayjs().isAfter(startDate);
    const isAuctionNotEnded = dayjs().isBefore(endDate);

    const isAuctionInProgress = isAuctionStarted && isAuctionNotEnded;
    const isAuctionFinished = isAuctionStarted && !isAuctionNotEnded;

    return {
        isAuctionStarted,
        isAuctionNotEnded,
        isAuctionInProgress,
        isAuctionFinished
    };
};
