import { useCallback } from 'react';
import { INotification, NotificationTypeEnum } from 'src/services/notifications/types';
import notificationsRegistry from 'src/services/notifications/notifications-registry';

export const useNotifications = () => {
    const show = useCallback((notification: INotification) => notificationsRegistry.add(notification), []);

    const remove = useCallback((id: number) => {
        notificationsRegistry.remove(id);
    }, []);

    const success = useCallback((notification: INotification) => show({
        ...notification,
        type: NotificationTypeEnum.success
    }), [show]);

    const error = useCallback((notification: INotification) => show({
        ...notification,
        type: NotificationTypeEnum.error
    }), [show]);

    const warning = useCallback((notification: INotification) => show({
        ...notification,
        type: NotificationTypeEnum.warning
    }), [show]);

    const info = useCallback((notification: INotification) => show({
        ...notification,
        type: NotificationTypeEnum.info
    }), [show]);

    return {
        remove,
        show,
        success,
        error,
        warning,
        info
    };
};
