import { createAsyncThunk } from '@reduxjs/toolkit';
import { CollectionAPI } from '@tacans/shared-marketplace-layer';
import {
    selectFilter,
    selectPager,
    selectSorting,
} from 'src/features/common/explore/ducks/collections/collections.selectors';
import { useCreateCollectionsRequestPayload } from 'src/features/common/explore/hooks/use-create-collections-request-payload';
import { RootState } from 'src/store/store';

export const fetchCollectionsThunk = createAsyncThunk('collections/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const sort = selectSorting(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);
    const payload = useCreateCollectionsRequestPayload({
        sort,
        filter,
        pager,
    });
    const { data } = await CollectionAPI.fetchByFilter(payload);

    return data;
});
