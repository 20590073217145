import React, { useEffect, useState } from 'react';
import { useAccordionContext } from './accordion.provider';
import { StyledAccordionContent, StyledContentWrapper } from './accordion.styles';
import { IAccordionItem } from './accordion.types';

export const AccordionItemContent = ({ children, itemId }: IAccordionItem) => {
    const { openedItemId } = useAccordionContext();
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setOpened(itemId === openedItemId);
    }, [openedItemId, itemId]);

    if (typeof children === 'function') {
        return children({
            opened
        });
    }

    return (
        <StyledAccordionContent isOpened={opened}>
            <StyledContentWrapper>
                <div data-scrollable>
                    <div>
                        {children}
                    </div>
                </div>
            </StyledContentWrapper>
        </StyledAccordionContent>
    );
};
