import { createSlice } from '@reduxjs/toolkit';
import { AuthTokenCookieService } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';
import { IS_SSR } from 'src/utils/helpers.utils';
import { ProfileAdapter } from './profile.adapter';
import {
    changePasswordThunk,
    profileAvatarChangeThunk,
    profileBannerChangeThunk,
    profileEditThunk,
    profileFetchThunk,
    updateEmailThunk,
} from './profile.thunk';

const UserProfileString = IS_SSR ? '' : localStorage.getItem('UserProfile');

const persistedProfile = UserProfileString ? JSON.parse(UserProfileString) : {};

const emptyInitialState = ProfileAdapter.getInitialState({
    profileLoaded: !AuthTokenCookieService.getToken('access'),
    justUpdated: false,
    avatarLoading: false,
    bannerLoading: false,
    uploadedAvatar: {
        smallUrl: '',
        mediumUrl: '',
        largeUrl: '',
    },
    uploadedBannerUrl: '',
    emailSent: false,
    passwordChanged: false,
    error: '',
});

const initialState = ProfileAdapter.upsertMany(
    {
        ...emptyInitialState,
    },
    persistedProfile
);

export const profileSlice = createSlice({
    name: 'own-profile',
    initialState,
    reducers: {
        clearProfile: () => emptyInitialState,
        changeEmail: (state, { payload }) => {
            const userProfile = state.entities[state.ids[0]];
            if (userProfile) {
                userProfile.personalInfo.email = payload;
            }
        },
        setSSRState: (state, { payload }) => {
            ProfileAdapter.setOne(state, payload);
            state.profileLoaded = true;
        },
        justUpdatedClear: (state) => {
            state.justUpdated = false;
        },
        errorClear: (state) => {
            state.error = '';
            state.emailSent = false;
            state.passwordChanged = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(profileEditThunk.fulfilled, (state, action) => {
                const { error } = action.payload.response.data.responseMeta;
                if (error) {
                    // @ts-ignore
                    state.error = error.messages.join(', ');

                    return;
                }

                state.justUpdated = true;
            })
            .addCase(profileFetchThunk.fulfilled, (state, action: any) => {
                const { profile } = action.payload.data;
                ProfileAdapter.setOne(state, profile);
                state.profileLoaded = true;
            })
            .addCase(profileFetchThunk.rejected, (state) => {
                state.profileLoaded = true;
            })
            .addCase(profileAvatarChangeThunk.pending, (state) => {
                state.avatarLoading = true;
            })
            .addCase(profileAvatarChangeThunk.fulfilled, (state, action) => {
                // @ts-ignore
                const { avatar } = action.payload.data;

                state.uploadedAvatar = avatar;
                state.avatarLoading = false;
            })
            .addCase(profileAvatarChangeThunk.rejected, (state, { payload }: { payload: any }) => {
                state.error = payload as string;
                state.avatarLoading = false;
            })
            .addCase(profileBannerChangeThunk.pending, (state) => {
                state.bannerLoading = true;
            })
            .addCase(profileBannerChangeThunk.fulfilled, (state, action) => {
                // @ts-ignore
                const { bannerUrl } = action.payload.data;
                // @ts-ignore
                state.uploadedBannerUrl = bannerUrl;
                state.bannerLoading = false;
            })
            .addCase(profileBannerChangeThunk.rejected, (state, { payload }: { payload: any }) => {
                state.error = payload as string;
                state.bannerLoading = false;
            })
            .addCase(updateEmailThunk.fulfilled, (state, action) => {
                state.emailSent = true;
            })
            .addCase(changePasswordThunk.fulfilled, (state, action) => {
                state.passwordChanged = true;
            })
            .addCase(HYDRATE, (state, action) => {
                // @ts-ignore
                if (action.payload.features.profile.own) {
                    return {
                        // @ts-ignore
                        ...action.payload.features.profile.own,
                        profileLoaded: true,
                    };
                }
                return state;
            });
    },
});

export const { actions } = profileSlice;

export default profileSlice.reducer;
