import { RequestPagerI } from '@tacans/shared-marketplace-layer';

export const useGetTransactionsRequestPayload = ({
    referralLink,
    pager,
}: {
    referralLink: string;
    pager?: RequestPagerI;
}) => ({
    referralLink,
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});