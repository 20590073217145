import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { TextInput } from 'src/ui/common/atoms/text-input';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const DesktopSearchWrapper = styled(FlexBox)`
    margin: 0 20px;
    display: none !important;
    max-width: 400px;

    ${media.md`
    display: unset !important;
    flex: 1 !important;
  `}
`;

export const MobileSearchWrapper = styled(FlexBox)`
    margin: 0 5px;

    ${media.md`
    display: none !important;
  `}
`;

export const MobileInputWrapper = styled.div`
  position: fixed;
  left: 70px;
  right: 0;
  top: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.warmBlack};
  height: ${({ theme }) => theme.constants.headerMbHeight - 1}px;
  padding: 10px;
`;

export const DesktopInputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const StyledInputWrapper = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
`;

export const StyledSearchIcon = styled(SvgIcon)<{ fixed?: boolean }>`
    position: absolute;
    top: ${({ fixed }) => (fixed ? '20px' : '10px')};
    left: ${({ fixed }) => (fixed ? '20px' : '10px')};

    ${media.md`
    top: 15px;
    left: 20px;
  `}
`;

export const StyledCloseIcon = styled(SvgIcon)`
    cursor: pointer;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: 20px;
`;

export const StyledSearchInput = styled(TextInput)`
    width: 100%;
    height: 40px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: ${({ theme }) => theme.colors.white};

    ${media.md`
        height: 50px;
        padding-left: 50px;
        padding-right: 50px;
  `}
    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const StyledIcon = styled(FlexBox)`
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.gs500};
    border-radius: 5px;
    user-select: none;
`;
