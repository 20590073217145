import { createSlice } from '@reduxjs/toolkit';
import { CategoryI } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';

import { CategoryAdapter } from './category.adapter';
import { categoryFetchThunk } from './category.thunk';

const initialState = CategoryAdapter.getInitialState({
    error: ''
});

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        clearCategory: () => initialState,
        errorClear: (state) => {
            state.error = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(categoryFetchThunk.fulfilled, (state, action) => {
                CategoryAdapter.removeAll(state);

                if (action.payload.data.responseMeta.error) {
                    state.error = action.payload.data.responseMeta.error.messages.join(', ');

                    return;
                }

                const categoryData = action.payload.data.nodes;

                if (!categoryData) throw new Error('No categories');

                CategoryAdapter.setAll(state, categoryData.reduce<CategoryI[]>((acc, curr) => {
                    acc.push({
                        ...curr.category,
                        children: curr.children
                    });

                    return acc;
                }, []));
            })
            .addCase(HYDRATE, (state, action) => {
                if (!state.ids.length) {
                    return ({
                        ...state,
                        // @ts-ignore
                        ...action.payload.features.category,
                    });
                }
                return state;
            });
    }
});

// Action creators are generated for each case reducer function
export const { actions } = categorySlice;

export default categorySlice.reducer;
