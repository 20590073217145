import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReferralApi } from '@tacans/shared-marketplace-layer';
import { RootState } from 'src/store';
import { useGetAllUsersRequestPayload } from '../../hooks/use-get-all-users-request-payload';
import { selectFilter, selectPager } from './get-users.selectors';

export const fetchAllUsersThunk = createAsyncThunk('get-all-referrals-users/fetch', async (referralLink: string, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);

    const payload = useGetAllUsersRequestPayload({
        referralLink,
        pager: {
            page: pager?.page ?? 0,
            limit: pager?.limit ?? 100,
        },
        filter,
    });
    const { data } = await ReferralApi.fetchUsers(payload);
    return data;
});