import { RequestPagerI } from '@tacans/shared-marketplace-layer';
import { IReferralFilters } from '../ducks/get-all-referrals/get-all-referrals.types';
import { createFilterFieldsPayload } from '../get-all-referrals-api.utils';


export const useGetAllUsersRequestPayload = ({
    pager,
    filter,
    referralLink,
}: {
    filter?: IReferralFilters;
    pager?: RequestPagerI;
    referralLink : string;
}) => ({
    referralLink,
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
    filter: createFilterFieldsPayload(filter || {}),
});