import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

import { UniversesAdapter } from './universes.adapter';

export const universesSelect = (state: RootState) => state.features.universe.explore;

export const universesSelectors = UniversesAdapter.getSelectors();

export const selectUniverses = createSelector(
    universesSelect,
    (state) => universesSelectors.selectAll(state)
);

export const selectUniversesEntities = createSelector(
    universesSelect,
    (state) => universesSelectors.selectEntities(state)
);

export const selectFilter = createSelector(
    universesSelect,
    (state) => state.filter
);

export const selectPager = createSelector(
    universesSelect,
    (state) => state.pager
);

export const selectSorting = createSelector(
    universesSelect,
    (state) => state.sorting
);

export const selectPages = createSelector(
    universesSelect,
    (state) => state.pages
);

export const selectByPage = createSelector(
    universesSelect,
    (state) => state.byPage
);

export const selectTotal = createSelector(
    universesSelect,
    (state) => state.total
);

export const selectLoading = createSelector(
    universesSelect,
    (state) => state.loading
);
