import { useRouter } from 'next/router';
import React from 'react';
import { RouteE } from 'src/constants/pages.constants';
import AuthGuard from 'src/features/common/auth/components/authGuard';
import { Footer } from 'src/features/white-labels/techcontemporary/app/components/footer';
import { Header } from 'src/features/white-labels/techcontemporary/app/components/header';
import { StyledWrapper } from './app-layout.styles';

const PAGES_WITHOUT_FOOTER: Record<string, boolean> = {
    '/': true,
    [RouteE.EXPLORE]: true,
    '/search': true,
    [RouteE.LAUNCHPADS]: true,
    [RouteE.UNIVERSES]: true,
    '/collections/[url]': true,
    '/collections': true,
    '/creator/[id]': true,
    '/launchpad/[id]': true,
    '/profile/[login]': true,
    '/login': true,
    '/affiliate-program/manage-affiliates': true,
    '/profile/affiliate-program': true,
};

const PAGES_WITHOUT_HEADER: Record<string, boolean> = {
    '/': true,
    '/login': true,
};

export const AppLayout: React.FC = ({ children }) => {
    const router = useRouter();
    const renderFooter = !PAGES_WITHOUT_FOOTER[router.pathname];
    const renderHeader = !PAGES_WITHOUT_HEADER[router.pathname];

    return (
        <StyledWrapper>
            {renderHeader && <Header />}
            <AuthGuard>{children}</AuthGuard>
            {renderFooter && <Footer />}
        </StyledWrapper>
    );
};