import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
    ICollectionsListFilter,
} from '@tacans/shared-marketplace-layer';

import { INITIAL_FILTER_STATE } from './universes.constants';

import { UniversesSort, IUniversesState } from './universes.types';
import { UniversesAdapter } from './universes.adapter';
import { fetchUniversesThunk } from './universes.thunk';

const getInitialState = () => UniversesAdapter.getInitialState<IUniversesState>(INITIAL_FILTER_STATE);

export const universesSlice = createSlice({
    name: 'explore-universes',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            UniversesAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ... INITIAL_FILTER_STATE.filter };
            state.pager = { ... INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<ICollectionsListFilter>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<ICollectionsListFilter>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setSorting: (state, { payload }: PayloadAction<UniversesSort>) => {
            state.sorting = payload;
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUniversesThunk.fulfilled, (state, action) => {
                const { pager, list = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([
                    ...state.pages,
                    ...state.pages.concat(page)
                ])];

                if (list.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                list.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        newByPage[page].push(item.id);
                    }
                });

                UniversesAdapter.addMany(state, list || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.universe.explore,
            }));
    },
});

export const { actions } = universesSlice;

export default universesSlice.reducer;
