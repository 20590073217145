import { useTranslation } from 'next-i18next';
import React, { useCallback, useMemo } from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { NavItem } from 'src/features/common/app/components/header-user-menu/nav-item';
import { useSocialLinks } from 'src/features/common/app/hooks/use-social-links';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useExploreTotal } from 'src/features/common/explore/hooks/use-explore-launchpads';
import { useOpenSwitchere } from 'src/features/common/order/hooks/use-open-switchere';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { useIsSeller } from 'src/features/common/profile/hooks/use-is-seller';
import { useProfileLoginUrl } from 'src/features/common/profile/hooks/use-profile-login-url';
import { useUniverseMine } from 'src/features/common/universe/hooks/universe-mine.hooks';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { useDisplayName } from 'src/features/common/user/hooks/use-display-name.hooks';
import { useUserLogout } from 'src/features/common/user/hooks/user.hooks';
import {
    AccountMenu,
    StyledAccordionContent,
    StyledButtonWrapper,
    StyledUserAvatar,
    StyledUserWrapper,
} from 'src/features/white-labels/ocean/app/components/header-user-menu/header-user-menu.styles';
import { Button } from 'src/ui/common/atoms/button';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';
import { Visible } from 'src/ui/common/layouts/visible';
import { Accordion } from 'src/ui/common/molecules/accordion';
import { decodeHTMLEntities } from 'src/utils/html.utils';

export const HeaderUserMenu: React.VFC<{ onClose: () => void }> = ({ onClose }) => {
    const { t } = useTranslation(['common']);
    const profile = useProfile();
    const authorized = useAuthorized();
    const logout = useUserLogout();
    const openSwitchhere = useOpenSwitchere();
    const universe = useUniverse();
    const universeMine = useUniverseMine();
    const isSeller = useIsSeller();
    const total = useExploreTotal();

    const userName = useDisplayName(profile?.user);
    const myProfileLink = useProfileLoginUrl(profile?.user);
    const socialLinks = useSocialLinks();

    const { isUniverseOwner, isUniverse } = universe;
    const { baseUrl } = universeMine || {};

    const onLogoutClick = useCallback(async () => {
        await logout();
        onClose();
    }, [logout, onClose]);

    const manageUniverseLink = useMemo(() => {
        if (isUniverse) {
            return {
                link: isUniverseOwner ? RouteE.UNIVERSE_SETTINGS : baseUrl,
                target: isUniverseOwner ? undefined : '_blank',
                external: !isUniverseOwner,
            };
        }
        return {
            target: '_blank',
            external: true,
            link: baseUrl,
        };
    }, [isUniverseOwner, isUniverse, baseUrl]);

    const accountMenu = (
        <>
            <NavItem link={myProfileLink} title={t('header.menu.account')} icon="user" inverse={true} />
            {!universeMine && !universe.isUniverse && (
                <NavItem
                    link={RouteE.UNIVERSE_WIZARD}
                    target="_blank"
                    title={t('header.menu.createUniverse')}
                    icon="creator"
                    inverse={true}
                />
            )}
            {universeMine && (
                <NavItem
                    link={manageUniverseLink.link}
                    external={manageUniverseLink.external}
                    target={manageUniverseLink.target}
                    title={isUniverseOwner ? t('header.menu.universeSettings') : t('header.menu.manageUniverse')}
                    icon="settings2"
                    inverse={true}
                />
            )}
            {isUniverseOwner && isSeller && (
                <NavItem
                    title={t('header.menu.manageCreators')}
                    icon="creators"
                    link={RouteE.UNIVERSE_CREATORS}
                    inverse={true}
                />
            )}
            <NavItem
                link={RouteE.PROFILE_MANAGE_WALLET}
                title={t('header.menu.manageWallets')}
                icon="wallet"
                inverse={true}
            />
            <NavItem
                link={RouteE.PROFILE_PERSONAL_INFORMATION}
                title={t('header.menu.settings')}
                icon="settings"
                inverse={true}
            />
            <NavItem title={t('header.menu.signOut')} icon="logout" onClick={onLogoutClick} inverse={true} />
            <StyledButtonWrapper>
                <Button variant="o_blue" stretch onClick={openSwitchhere}>
                    {t('header.menu.buyCCD')}
                </Button>
            </StyledButtonWrapper>
        </>
    );

    return (
        <>
            <Visible view="desktop">
                <AccountMenu>{accountMenu}</AccountMenu>
            </Visible>
            <Visible view={['mobile', 'laptop']}>
                {authorized && (
                    <AccountMenu>
                        <Accordion>
                            <Accordion.ItemHeader itemId="account">
                                {({ opened, onClick }) => (
                                    <StyledUserWrapper alignItems="center" onClick={onClick}>
                                        <StyledUserAvatar variant="square" size={40} user={profile?.user} />
                                        <Button variant="outline">
                                            <Typography overflowLines={1} size="xs">
                                                {decodeHTMLEntities(userName)}
                                            </Typography>
                                        </Button>
                                        <SvgIcon variant="regular" icon={opened ? 'chevronUp' : 'chevronDown'} />
                                    </StyledUserWrapper>
                                )}
                            </Accordion.ItemHeader>
                            <Accordion.ItemContent itemId="account">
                                {({ opened }) => (opened ? accountMenu : null)}
                            </Accordion.ItemContent>
                        </Accordion>
                    </AccountMenu>
                )}
                <NavItem link={RouteE.EXPLORE} title={t('header.menu.exploreNfts')} icon="hexagon" inverse={true} />
                <NavItem
                    link={RouteE.COLLECTIONS_LIST}
                    title={t('header.menu.collections')}
                    icon="collections"
                    inverse={true}
                />
                {(!!total || isUniverseOwner || isSeller || !universe.isUniverse) && (
                    <NavItem link={RouteE.LAUNCHPADS} title={t('header.menu.launchpad')} icon="union" inverse={true} />
                )}
                {!universe.isUniverse && (
                    <NavItem
                        link={RouteE.UNIVERSES}
                        title={t('header.menu.universes')}
                        icon="verified"
                        inverse={true}
                    />
                )}
                <Accordion>
                    {!!socialLinks.length && (
                        <Accordion.ItemHeader itemId="community">
                            {({ opened, onClick }) => (
                                <NavItem
                                    iconArrow={opened ? 'chevronDown' : 'chevronRight'}
                                    title={t('header.menu.community')}
                                    icon="community"
                                    onClick={onClick}
                                    inverse={true}
                                />
                            )}
                        </Accordion.ItemHeader>
                    )}
                    {!!socialLinks.length && (
                        <Accordion.ItemContent itemId="community">
                            {({ opened }) => (
                                <StyledAccordionContent opened={opened}>
                                    {socialLinks.map((link) => (
                                        <a
                                            key={link.identifier}
                                            href={link.identifier}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Typography size="sm" overflowLines={1}>
                                                {link.type}
                                            </Typography>
                                        </a>
                                    ))}
                                </StyledAccordionContent>
                            )}
                        </Accordion.ItemContent>
                    )}
                </Accordion>
            </Visible>
        </>
    );
};
