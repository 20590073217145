import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    UniverseAPI,
} from '@tacans/shared-marketplace-layer';

export const universeGetMineThunk = createAsyncThunk(
    'universe/get-mine',
    async () => {
        const response = await UniverseAPI.getMine();

        return response;
    }
);
