import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { canvasAnimationSpeed } from 'src/ui/common/config/animations';
import { useGradientAnimation } from 'src/ui/common/hooks/useGradientAnimation.hook';
import { StyledButton, StyledCanvas, StyledChildren } from './button.styles';
import { ButtonProps } from './button.types';
import { createRipple } from './button.utils';

export const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            stretch = false,
            variant = 'gradient',
            size = 'md',
            children,
            testId,
            onMouseLeave,
            onMouseEnter,
            onClick,
            ...rest
        },
        ref
    ) => {
        const { ref: canvasRef, entry: canvas, inView } = useInView();
        const { startAnimation, stopAnimation } = useGradientAnimation();
        const isGradient = variant === 'gradient';

        useEffect(() => {
            if (isGradient && inView) {
                startAnimation(canvas?.target as HTMLCanvasElement, canvasAnimationSpeed.slow);
            }
            if (isGradient && !inView) {
                stopAnimation();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isGradient, inView, canvas]);

        const handleMouseLeave = useCallback(
            (e) => {
                if (isGradient) {
                    startAnimation(canvas?.target as HTMLCanvasElement, canvasAnimationSpeed.slow);
                }
                onMouseLeave?.(e);
            },
            [isGradient, canvas, startAnimation, onMouseLeave]
        );

        const handleMouseEnter = useCallback(
            (e) => {
                if (isGradient) {
                    startAnimation(canvas?.target as HTMLCanvasElement, canvasAnimationSpeed.fast);
                }
                onMouseEnter?.(e);
            },
            [isGradient, canvas, startAnimation, onMouseEnter]
        );

        const handleOnClick = useCallback(
            (e) => {
                !isGradient && createRipple(e);
                onClick?.(e);
            },
            [onClick, isGradient]
        );

        return (
            <StyledButton
                data-test-id={testId}
                ref={ref}
                stretch={stretch}
                size={size}
                variant={variant}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter}
                onClick={handleOnClick}
                {...rest}
            >
                {isGradient ? (
                    <>
                        <StyledChildren>{children}</StyledChildren>
                        <StyledCanvas ref={canvasRef} width="32" height="32" />
                    </>
                ) : (
                    children
                )}
            </StyledButton>
        );
    }
);

Button.displayName = 'Button';
