import { createAsyncThunk } from '@reduxjs/toolkit';
import { IListParams, NftAPI } from '@tacans/shared-marketplace-layer';

export const bannerNftsFetchThunk = createAsyncThunk(
    'bannerNfts/fetch',
    async (request: IListParams) => {
        const response = await NftAPI.fetchByFilter(request);
        return response.data;
    }
);
