import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI, TUserIdentity } from '@tacans/shared-marketplace-layer';

export const fetchPublicProfileThunk = createAsyncThunk(
    'public-profile/fetch',
    async (identity: TUserIdentity, { rejectWithValue }) => {
        const response = await UserAPI.getUserInfo(identity);
        const { error } = response.data.responseMeta;
        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);
