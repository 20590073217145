import { useCallback } from 'react';
import { OrderAPI, IOrderRequest } from '@tacans/shared-marketplace-layer';

import { useNotifications } from 'src/services/notifications/use-notifications';

export const useCreateOrder = () => {
    const { error } = useNotifications();

    return useCallback(async (payload: IOrderRequest) => {
        try {
            const response = await OrderAPI.create(payload);

            if (response.data.responseMeta?.error || !response.data?.order) {
                throw new Error(response.data.responseMeta.error?.messages.join(', '));
            }

            return response.data.order;
        } catch (err) {
            error({
                text: 'Couldn\'t create an order',
            });

            return null;
        }
    }, [error]);
};
