import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { fetchLaunchpadNftsThunk } from './explore-launchpad-nfts.thunk';
import { INITIAL_FILTER_STATE } from './explore-launchpad-nfts.constants';
import { ExploreLaunchpadNftsAdapter } from './explore-launchpad-nfts.adapter';
import { IExploreLaunchpadNftsState } from './explore-launchpad-nfts.types';

const getInitialState = () =>
    ExploreLaunchpadNftsAdapter.getInitialState<IExploreLaunchpadNftsState>(INITIAL_FILTER_STATE);

export const exploreLaunchpadNftsSlice = createSlice({
    name: 'explore-launchpad-nfts',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            ExploreLaunchpadNftsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 })
                .map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLaunchpadNftsThunk.fulfilled, (state, action) => {
                const { pager, list = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([
                    ...state.pages,
                    ...state.pages.concat(page)
                ])];

                if (list.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                list.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        newByPage[page].push(item.id);
                    }
                });

                ExploreLaunchpadNftsAdapter.addMany(state, list || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.exploreLaunchpadNfts,
            }));
    },
});

export const { actions } = exploreLaunchpadNftsSlice;

export default exploreLaunchpadNftsSlice.reducer;
