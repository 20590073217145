// eslint-disable-next-line no-restricted-imports
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { Visible } from 'src/ui/common/layouts/visible';

export const Logo = () => (
    <Link href={RouteE.MARKETPLACE} prefetch={false}>
        <a>
            <Visible view={['desktop', 'laptop']}>
                <Image src="/techLogo.svg" height={40} width={217} />
            </Visible>
            <Visible view={['mobile']}>
                <Image src="/techLogo.svg" height={36} width={196} />
            </Visible>
        </a>
    </Link>
);