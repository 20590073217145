import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    CollectionAPI,
    ContactMessageI,
    ILotCreateRequest,
    LotAPI,
    LotI,
    NftAPI,
    PagerRequestI,
} from '@tacans/shared-marketplace-layer';

export const collectibleCreateLotThunk = createAsyncThunk('collectible/create-lot', async (data: ILotCreateRequest) => {
    const response = await LotAPI.create(data);

    return response;
});

export const nftFetchThunk = createAsyncThunk('collectible/fetch', async (id: string, { rejectWithValue }) => {
    const response = await NftAPI.fetchById(id);
    return response.data;
});

export const fetchNftCollectionThunk = createAsyncThunk('collectible/fetch-collection', async (id: string) => {
    const response = await CollectionAPI.fetchById(id);
    return response.data;
});

export const fetchNftHistoryThunk = createAsyncThunk(
    'collectible/fetch-history',
    async ({ nftId, pager }: { nftId: string; pager: PagerRequestI }) => {
        const response = await NftAPI.fetchHistory(nftId, pager);
        return response.data;
    }
);

export const fetchAllLotsThunk = createAsyncThunk('collectible/fetch-all-lots', async (id: string) => {
    const response = await LotAPI.fetchLotsByNft({
        nftId: id,
        pager: { page: 0, limit: 100 },
    });
    return response.data.lots;
});

export const fetchActiveLotsThunk = createAsyncThunk('collectible/fetch-active-lots', async (id: string) => {
    const response = await LotAPI.fetchLotsByNft({
        nftId: id,
        activeOnly: true,
        pager: { page: 0, limit: 100 },
    });
    return response.data.lots;
});

export const currentBidsFetchThunk = createAsyncThunk('collectible/fetchCurrentBids', async (lotIds: string[]) => {
    const response = await LotAPI.fetchCurrentBidsByLots(lotIds);

    return response.data.amounts;
});

export const fetchActiveOwnersThunk = createAsyncThunk(
    'collectible/fetchActiveOwners',
    async ({ nftId, pager }: { nftId: string; pager?: PagerRequestI }) => {
        const { data } = await NftAPI.fetchActiveOwners(
            nftId,
            pager || {
                page: 0,
                limit: 500,
            }
        );

        return data;
    }
);

export const auctionWinnerFetchThunk = createAsyncThunk('collectible/fetchAuctionWinner', async (lotId: string) => {
    const response = await LotAPI.fetchAuctionWinner(lotId);

    return response;
});

export const contactSellerThunk = createAsyncThunk(
    'collectible/contactSeller',
    async (data: ContactMessageI, { rejectWithValue, dispatch }) => {
        const response = await NftAPI.contactOwner(data);
        if (response.data.responseMeta?.error) {
            return rejectWithValue(response.data.responseMeta?.error);
        }
        return response;
    }
);

export const setSelectedLotThunk = createAction<LotI>('collectible/SET_SELECTED_LOT');