import { createAsyncThunk } from '@reduxjs/toolkit';
import { CollectionAPI, IHighLightsRequest } from '@tacans/shared-marketplace-layer';

export const highlightedCollectionsFetchThunk = createAsyncThunk(
    'highlightedCollections/fetch',
    async (request: IHighLightsRequest) => {
        const response = await CollectionAPI.fetchHighlights(request);
        return response.data;
    }
);
