import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Grid } from 'src/ui/common/atoms/grid';
import { Visible } from 'src/ui/common/layouts/visible';
import { media } from 'src/ui/common/mixins/media';
import styled, { css } from 'styled-components';

export const StyledFooter = styled.div`
    padding: 80px 10px 32px;
    background-color: ${({ theme }) => theme.colors.gs500};
    position: relative;

    ${media.sm`
    padding: 80px 40px 32px;
  `}
    ${media.lg`
    padding: 112px 0 32px;
  `}
  & > div:first-child {
        padding: 0 0 48px;

        ${media.lg`
      padding: 0 0 112px;
    `}
    }

    & > div:last-child {
        border-top: 1px solid ${({ theme }) => theme.colors.gs400};
        padding: 32px 0 0;
    }

    a {
        margin: 0;
        padding: 0;
        text-align: unset;
    }
`;

export const StyledFooterLogo = styled(FlexBox)`
    row-gap: 15px;

    ${media.sm`
    grid-column: 1 / 4;
  `}
    ${media.md`
    grid-column: 1 / 4;
    max-width: 387px;
    row-gap: 20px;
  `}
  h3 {
        color: ${({ theme }) => theme.colors.text};
        font-size: 35px;
    }
`;

export const StyledLinksBlock = styled(FlexBox)`
    gap: 24px;

    h3 {
        color: ${({ theme }) => theme.colors.text};
    }

    a {
        font-size: 16px;
    }

    & > div {
        gap: 12px;
    }
`;

export const StyledFooterBottom = styled(Grid)`
    span {
        grid-column: 1 / 2;
        margin: 0 0 12px;

        ${media.sm`
      margin: 0;
      grid-column: 1 / 4;
    `}
    }

    & > div {
        grid-column: 1 / 2;
        column-gap: 24px;

        ${media.sm`
      grid-column: 4 / 6;
    `}
    }
`;

export const StyledAccordionContainer = styled(Visible)`
    & > div:nth-child(2n - 1) {
        padding: 15px 0;
    }

    & > div:nth-child(2n) {
        & > div > div {
            gap: 12px;
        }

        &::after {
            display: none;
        }
    }
`;

export const StyledFooterFollow = styled(Visible)`
    ${media.lg(css`
        grid-row: 2 / 3;
        grid-column: 4 / 7;
    `)}

    a {
        display: grid;
        place-items: center;
        background-color: rgba(${({ theme }) => theme.colors.textRgb}, 0.05);
        width: 37px;
        height: 37px;
        border-radius: 5px;

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
`;
