import React, { useMemo, useState } from 'react';
import { AccordionItemContent } from './accordion-item-content';
import { AccordionItemHeader } from './accordion-item-header';
import { AccordionProvider } from './accordion.provider';
import { IAccordion, IAccordionProps } from './accordion.types';


export const Accordion: IAccordion<IAccordionProps> = ({ initialOpenedId, handleItemClick, children }) => {
    const [openedItemId, setOpenedItemId] = useState(initialOpenedId || null);

    const contextValue = useMemo(() => ({
        openedItemId,
        setOpenedItemId,
        handleItemClick,
    }), [openedItemId, setOpenedItemId, handleItemClick]);

    return (
        <AccordionProvider.Provider value={contextValue}>
            {children}
        </AccordionProvider.Provider>
    );
};

Accordion.ItemHeader = AccordionItemHeader;
Accordion.ItemContent = AccordionItemContent;