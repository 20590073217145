import { LaunchpadsSort, ILaunchpadsState } from './launchpads.types';

export const INITIAL_FILTER_STATE: ILaunchpadsState = {
    filter: {
        keywords: ''
    },
    sorting: LaunchpadsSort.LATEST,
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    exploreTotal: 0,
    loading: false,
    pages: [],
    byPage: {}
};
