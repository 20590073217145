import { ILaunchpadsFilter, RequestPagerI } from '@tacans/shared-marketplace-layer';
import { LaunchpadsSort } from 'src/features/common/explore/ducks/launchpads/launchpads.types';
import {
    useCreateLaunchpadsFilterFieldsPayload,
    mapLaunchpadsSorting,
} from 'src/features/common/explore/launchpads-api.utils';

export const useCreateLaunchpadsRequestPayload = ({
    sort,
    filter,
    pager,
}: {
    sort: LaunchpadsSort;
    filter: ILaunchpadsFilter;
    pager?: RequestPagerI;
}) => ({
    sorting: mapLaunchpadsSorting(sort),
    filter: useCreateLaunchpadsFilterFieldsPayload(filter),
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});
