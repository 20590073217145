import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { ProfileAdapter } from './profile.adapter';

export const profileSelect = (state: RootState) => state.features.profile.own;

export const profileSelectors = ProfileAdapter.getSelectors();

export const selectProfile = createSelector(
    profileSelect,
    (state) => profileSelectors.selectAll(state)[0]
);

export const selectProfileLoaded = createSelector(
    profileSelect,
    (state) => state.profileLoaded
);

export const selectProfileAvatar = createSelector(
    selectProfile,
    (profile) => profile.user.avatarAssets?.originalUrl
);

export const selectProfileAvatarLoading = createSelector(
    profileSelect,
    (profile) => profile.avatarLoading
);

export const selectProfileBanner = createSelector(
    selectProfile,
    (profile) => profile.user.bannerAssets?.originalUrl
);

export const selectProfileBannerLoading = createSelector(
    profileSelect,
    (profile) => profile.bannerLoading
);

export const selectUploadedProfileAvatar = createSelector(
    profileSelect,
    (profile) => profile.uploadedAvatar
);

export const selectUploadedProfileBanner = createSelector(
    profileSelect,
    (profile) => profile.uploadedBannerUrl
);

export const selectProfileJustUpdated = createSelector(
    profileSelect,
    (profile) => profile.justUpdated
);

export const selectProfileError = createSelector(
    profileSelect,
    (profile) => profile.error
);

export const selectEmailSent = createSelector(
    profileSelect,
    (profile) => profile.emailSent
);

export const selectPasswordChanged = createSelector(
    profileSelect,
    (profile) => profile.passwordChanged
);
