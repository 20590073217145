import { createAsyncThunk } from '@reduxjs/toolkit';
import { NftAPI, NFTI } from '@tacans/shared-marketplace-layer';

export const fetchNftByIdsThunk = createAsyncThunk(
    'nft-list/fetch-nft-by-ids',
    async (data: NFTI['id'][]) => {
        const response = await NftAPI.fetchByIds(data);

        return response.data.nfts;
    }
);