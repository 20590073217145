import { createSlice } from '@reduxjs/toolkit';
import { UniverseUIConfiguration, UniverseUISettings } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';
import { fetchUniverseInfoThunk } from './universe-info.thunk';

export interface IUniverseInfo {
    id: string;
    name: string;
    ownerId: string;
    baseUrl: string;
    taxPercent?: string;
    affiliateProgram: boolean;
    settings: UniverseUISettings;
    configurations: UniverseUIConfiguration[] | null;
}

interface IState {
    universe?: IUniverseInfo;
}

const INITIAL_STATE: IState = {};

export const universeInfoSlice = createSlice({
    name: 'universe-info',
    initialState: INITIAL_STATE,
    reducers: {
        clear: () => INITIAL_STATE,
        update: (state, action) => {
            const { universe, uiConfigurations } = action.payload.data;
            if (universe) {
                state.universe = {
                    id: universe.id,
                    ownerId: universe.ownerId || '',
                    name: universe.name,
                    taxPercent: universe.taxPercent || '',
                    baseUrl: universe.baseUrl,
                    settings: universe.uiSettings || {},
                    configurations: uiConfigurations || [],
                    affiliateProgram: !!universe.affiliateProgram,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.universe.info,
            }))
            .addCase(fetchUniverseInfoThunk.fulfilled, (state, action) => {
                const { universe, uiConfigurations } = action.payload.data;
                if (universe) {
                    state.universe = {
                        id: universe.id,
                        ownerId: universe.ownerId || '',
                        name: universe.name,
                        taxPercent: universe.taxPercent || '',
                        baseUrl: universe.baseUrl,
                        settings: universe.uiSettings || {},
                        configurations: uiConfigurations || [],
                        affiliateProgram: !!universe.affiliateProgram,
                    };
                }
            });
    },
});

export const { actions } = universeInfoSlice;

export default universeInfoSlice.reducer;