import { ILaunchpadsFilter, ILaunchpadsSorting } from '@tacans/shared-marketplace-layer';
import { LaunchpadsSort } from 'src/features/common/explore/ducks/launchpads/launchpads.types';

export const useCreateLaunchpadsFilterFieldsPayload = (meta: ILaunchpadsFilter): ILaunchpadsFilter => {
    const fields: ILaunchpadsFilter = {};

    meta = meta || {};

    if (meta.ownerId) {
        fields.ownerId = meta.ownerId;
    }

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    return fields;
};

export const mapLaunchpadsSorting = (sort?: LaunchpadsSort): ILaunchpadsSorting => {
    switch (sort) {
        case LaunchpadsSort.LATEST:
            return {
                sortBy: 'Date',
                ascending: false,
            };
        case LaunchpadsSort.OLDEST:
            return {
                sortBy: 'Date',
                ascending: true,
            };
        case LaunchpadsSort.NAME_ASC:
            return {
                sortBy: 'Name',
                ascending: true,
            };
        case LaunchpadsSort.NAME_DESC:
            return {
                sortBy: 'Name',
                ascending: false,
            };
        default:
            return {
                sortBy: 'Date',
                ascending: false,
            };
    }
};
