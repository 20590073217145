import { createAsyncThunk } from '@reduxjs/toolkit';
import { LotAPI, PagerRequestI } from '@tacans/shared-marketplace-layer';

export const fetchLotBidsThunk = createAsyncThunk(
    'collectible-bids/fetch-lot-bids',
    async ({ lotId, pager } : { lotId: string, pager: PagerRequestI }) => {
        const response = await LotAPI.fetchBidsByLot({
            lotId,
            pager
        });
        return response.data;
    }
);
