import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { RouteE } from 'src/constants/pages.constants';
import { CreateNftStoreBtn } from 'src/features/common/app/components/create-nft-store-btn';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { useUniversePermissions } from 'src/features/common/universe/hooks/use-universe-permissions';
import UserAvatar from 'src/features/common/user/components/user-avatar';
import { useDisabledWallets } from 'src/features/common/wallet/hooks/use-disabled-wallets';
import { useRenderConnectWalletSidebar } from 'src/features/common/wallet/hooks/use-render-connect-wallet-sidebar';
import { useWalletsLoaded } from 'src/features/common/wallet/hooks/wallet.hooks';
import { HeaderNotifications } from 'src/features/white-labels/ocean/app/components/header-notifications';
import { HeaderSearch } from 'src/features/white-labels/ocean/app/components/header-search';
import { HeaderUserMenu } from 'src/features/white-labels/ocean/app/components/header-user-menu';
import {
    StyledDropDown,
    StyledLoginButton,
    UserDropDownIcon,
    UserMenuWrapper,
} from 'src/features/white-labels/ocean/app/components/header/header.style';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Visible } from 'src/ui/common/layouts/visible';
import { IS_SSR } from 'src/utils/helpers.utils';

export const UserProfile = () => {
    const router = useRouter();
    const renderConnectWalletSidebar = useRenderConnectWalletSidebar();
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation(['actions']);
    const authorized = useAuthorized();
    const profile = useProfile();
    const disabledWallets = useDisabledWallets();
    const walletsLoaded = useWalletsLoaded();
    const { canCreate } = useUniversePermissions();
    const [init, setInit] = useState(false);

    const locale = useMemo(
        () => ({
            login: t('auth.login', { ns: 'actions' }),
            connect: t('common.connect', { ns: 'actions' }),
            create: t('crud.create', { ns: 'actions' }),
        }),
        [t]
    );

    const showConnectButton = useMemo(() => Object.values(disabledWallets).every((item) => !item), [disabledWallets]);

    const toggle = useCallback(() => setOpened((s) => !s), []);

    const close = useCallback((e) => {
        e?.stopPropagation?.();
        setOpened(false);
        window.removeEventListener('scroll', close);
    }, []);

    const login = useCallback(() => {
        Router.push('/login');
    }, []);

    const handleConnectClick = useCallback(() => {
        renderConnectWalletSidebar();
    }, [renderConnectWalletSidebar]);

    useEffect(() => {
        setInit(true);
        if (!isMobileOnly && opened) {
            window.addEventListener('scroll', close);
        }
        if (isMobileOnly && opened) {
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
    }, [close, opened]);

    useEffect(() => {
        router.events.on('routeChangeStart', close);

        return () => {
            router.events.off('routeChangeStart', close);
        };
    }, [router, close]);

    return (
        <UserMenuWrapper alignItems="center">
            <HeaderSearch />
            {init && !authorized && (
                <StyledLoginButton variant="o_blue" onClick={login}>
                    {locale.login}
                </StyledLoginButton>
            )}
            {init && authorized && (
                <>
                    <HeaderNotifications />
                    <Spacing mr="5px" mrMd="10px">
                        <CreateNftStoreBtn />
                    </Spacing>
                    {!showConnectButton && walletsLoaded && canCreate && (
                        <Link href={RouteE.COLLECTIBLE_CREATE} prefetch={false}>
                            <StyledLoginButton variant="o_blue">{locale.create}</StyledLoginButton>
                        </Link>
                    )}
                    {showConnectButton && walletsLoaded && (
                        <StyledLoginButton variant="o_blue" onClick={handleConnectClick}>
                            {locale.connect}
                        </StyledLoginButton>
                    )}
                    <Visible view="desktop" display="flex">
                        <UserAvatar onClick={toggle} size={50} variant="square" user={profile?.user} />
                    </Visible>
                </>
            )}
            <Visible view={['laptop', 'mobile']} display="flex">
                <UserDropDownIcon onMouseDown={toggle} alignItems="center" justifyContent="center">
                    <SvgIcon variant="regular" icon={opened ? 'close' : 'hamburger'} />
                </UserDropDownIcon>
            </Visible>
            {IS_SSR
                ? null
                : ReactDOM.createPortal(
                      <OutsideClickHandler disabled={isMobileOnly || !opened} onOutsideClick={close}>
                          <StyledDropDown opened={opened}>
                              {/* @ts-ignore */}
                              {opened && <HeaderUserMenu onClose={close} />}
                          </StyledDropDown>
                      </OutsideClickHandler>,
                      document.body
                  )}
        </UserMenuWrapper>
    );
};
