import React from 'react';
import {
    phoenxTheme,
    syndicateTheme,
    techTheme,
    theme as darkTheme,
    vemTheme,
} from 'src/features/common/app/components/theme-provider/theme';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './global-styles';

interface IProviderProps {
    children: React.ReactNode;
    theme: 'dark' | 'tech' | 'vem' | 'phoenx' | 'syndicate';
}

const AppThemeProvider = ({ children, theme }: IProviderProps) => {
    const themes = {
        dark: darkTheme,
        tech: techTheme,
        vem: vemTheme,
        phoenx: phoenxTheme,
        syndicate: syndicateTheme,
    };

    const selectedTheme = themes[theme] || darkTheme;

    return (
        <>
            {/* @ts-ignore */}
            <ThemeProvider theme={selectedTheme}>
                <GlobalStyle />
                <>{children}</>
            </ThemeProvider>
        </>
    );
};

export default AppThemeProvider;