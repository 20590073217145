import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  @supports (-webkit-touch-callout: none) {
    body {
      /* The hack for Safari */
      height: -webkit-fill-available;
    }
  }
  html,
  body {
    min-height: 100%;
    /* mobile viewport bug fix */
    //noinspection CssInvalidPropertyValue
    min-height: -webkit-fill-available;
    color: ${({ theme }) => theme.colors.gs200};
    background-color: ${({ theme }) => theme.colors.warmBlack};
    font-family: ${({ theme }) => theme.fonts.text};
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
`;
