import { useState, useEffect, useRef, useCallback } from 'react';
import { validateImage } from 'src/utils/file.utils';
import { useIsMounted } from './useIsMounted';

interface ArgumentsI {
    skip?: boolean;
    imageUrl?: string;
    fallBackUrl?: string;
}

export const useImageUrlCheck = ({ imageUrl, fallBackUrl, skip }: ArgumentsI) => {
    const [url, setUrl] = useState<string | null>(null);
    const isValidatingRef = useRef(false);
    const urlQueueRef = useRef<string[]>([]);
    const isMounted = useIsMounted();

    const runValidate = useCallback((imageUrl: string) => {
        isValidatingRef.current = true;
        validateImage({
            url: imageUrl,
            cb: (err?: any) => {
                isValidatingRef.current = false;
                if (err) {
                    isMounted(() => !!fallBackUrl && setUrl(fallBackUrl));
                } else {
                    isMounted(() => setUrl(imageUrl));
                }
                if (urlQueueRef.current.length) {
                    runValidate(urlQueueRef.current.pop() as string);
                }
            },
        });
    }, [fallBackUrl, isMounted]);

    useEffect(() => {
        if (skip) {
            return;
        }
        if (isValidatingRef.current) {
            if(imageUrl) {
                urlQueueRef.current.push(imageUrl);
            }
            return;
        }
        if (imageUrl) {
            runValidate(imageUrl);
        } else if (fallBackUrl) {
            isMounted(() => setUrl(fallBackUrl));
        } else {
            isMounted(() => setUrl(null));
        }
    }, [skip, imageUrl, fallBackUrl, isMounted, runValidate]);

    return url;
};
