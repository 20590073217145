import { UserLoginRequest } from '@tacans/shared-marketplace-layer';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { actions as ProfileActions } from 'src/features/common/profile/ducks/profile/profile.slice';
import { useProfileClear } from 'src/features/common/profile/hooks/profile.hooks';
import { actions as UniverseMineActions } from 'src/features/common/universe/ducks/universe-mine/universe-mine.slice';
import { useWalletClear } from 'src/features/common/wallet/hooks/wallet.hooks';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import { useClearNextDataCache } from 'src/hooks/use-clear-next-data-cache';
import {
    deleteUserThunk,
    loginUserThunk,
    logoutUserThunk,
    selectNewPasswordSet,
    selectUserDeleted,
    selectUserError,
    selectUserLoggedIn,
    selectUserLoggingIn,
    UserActions,
} from '../ducks/user';

export const useClearUserError = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(UserActions.clearError());
    }, [dispatch]);
};

export const useUserLogin = () => {
    const dispatch = useAppDispatch();
    const clearProfile = useProfileClear();
    const userError = useUserError();

    const userLogin = useCallback((data: UserLoginRequest) => dispatch(loginUserThunk(data)), [dispatch]);

    useEffect(
        () => () => {
            if (userError) {
                clearProfile();
            }
        },
        [userError, clearProfile]
    );

    return userLogin;
};

export const useUserLogout = () => {
    const router = useRouter();
    const clearWallets = useWalletClear();
    const dispatch = useAppDispatch();
    const clear = useClearNextDataCache();

    return useCallback(async () => {
        await dispatch(logoutUserThunk());
        clearWallets();

        if (
            [
                '/profile/manage-wallet',
                '/profile/password',
                '/profile/personal-information',
                '/profile/social',
            ].includes(router.pathname)
        ) {
            router.push('/');
        }
        dispatch(ProfileActions.clearProfile());
        dispatch(UniverseMineActions.clear());
        clear();
    }, [clearWallets, dispatch, clear, router]);
};

export const useDeleteUser = () => {
    const dispatch = useAppDispatch();
    const deleted = useUserDeleted();
    const { push } = useRouter();
    const logout = useUserLogout();

    return useCallback(
        (password: string) => {
            dispatch(deleteUserThunk(password));

            if (deleted) {
                push(RouteE.HOME);

                logout();

                // TODO redesign modals
                // closeModal();
            }
        },
        [dispatch, deleted, logout, push]
    );
};

export const useUserError = () => useAppSelector(selectUserError);
export const useUserLoggedIn = () => useAppSelector(selectUserLoggedIn);
export const useNewPasswordSet = () => useAppSelector(selectNewPasswordSet);
export const useUserDeleted = () => useAppSelector(selectUserDeleted);
export const useUserLoggingIn = () => useAppSelector(selectUserLoggingIn);
