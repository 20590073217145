import React from 'react';
import { StyledWrapper } from './flex-box.styles';
import { FlexBoxProps } from './flex-box.types';

export const FlexBox = React.forwardRef<HTMLDivElement, FlexBoxProps>(
    (
        { onClick, className, children, onMouseEnter, onMouseDown, onMouseLeave, testId, ...styles }: FlexBoxProps,
        ref
    ) => (
        <StyledWrapper
            ref={ref}
            onClick={onClick}
            className={className}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-test-id={testId}
            {...styles}
        >
            {children}
        </StyledWrapper>
    )
);
