import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

export const universeInfoSelect = (state: RootState) => state.features.universe.info;

export const selectUniverseInfo = createSelector(
    universeInfoSelect,
    (state) => state.universe
);
