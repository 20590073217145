import { FlexBox } from 'src/ui/common/atoms/flex-box';
import styled from 'styled-components';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import { IIconBoxWrapper } from './types';

export const StyledWrapper = styled(FlexBox)<IIconBoxWrapper>`
  cursor: pointer;
  flex: ${({ size }) => `0 0 ${size}px`};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  background-color: ${({ theme }) => theme.colors.gs500};
  border-radius: 5px;
  transition: background-color ${speed.content} ease-in-out;
  user-select: none;
  
  ${({ sizeSm }) => sizeSm && media.sm`
      flex: ${`0 0 ${sizeSm}px`};
      width: ${`${sizeSm}px`};
      height: ${`${sizeSm}px`};
  `}

  ${({ sizeMd }) => sizeMd && media.md`
      flex: ${`0 0 ${sizeMd}px`};
      width: ${`${sizeMd}px`};
      height: ${`${sizeMd}px`};
  `}
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.gs450};
  }
`;
