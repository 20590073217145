import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { Store } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { apiClient } from '@tacans/shared-marketplace-layer';

import { IS_SSR } from 'src/utils/helpers.utils';
import { apiClientLoginModalMiddleware } from 'src/utils/apiClientLoginModalMiddleware';
import reducer from './root.reducer';

const makeStore = () => {
    const store = configureStore({
        reducer,
        middleware: new MiddlewareArray().concat(thunk.withExtraArgument('type')),
    });

    // @ts-ignore
    apiClientLoginModalMiddleware(apiClient, store);

    if (!IS_SSR) {
        store.subscribe(() => {
            const { loggedIn } = store.getState().global.user;
            const profileState = store.getState().features.profile.own.entities;
            if (loggedIn) {
                const newProfileState = JSON.stringify(profileState);
                localStorage.setItem('UserProfile', newProfileState);
            } else {
                localStorage.removeItem('UserProfile');
            }
        });
    }

    return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const wrapper = createWrapper<Store<RootState>>(makeStore);
