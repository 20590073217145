import { CollectionsSort, ICollectionsState } from 'src/features/common/explore/ducks/collections/collections.types';

export const INITIAL_FILTER_STATE: ICollectionsState = {
    filter: {
        keywords: '',
        ownerIds: [],
    },
    sorting: CollectionsSort.LATEST,
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    loading: false,
    pages: [],
    byPage: {},
};
