import { useCallback, useRef } from 'react';
import Router from 'next/router';
import { useConnectMetamaskAction } from 'src/features/common/action-triggers/hooks/use-action-triggers';

const GET_PARAM_NAME = 'trigger-action';

export const useInitActionTriggersOnLoad = () => {
    const initRef = useRef<boolean>(false);
    const connectMetamaskAction = useConnectMetamaskAction();

    const handleAction = useCallback(() => {
        const value = sessionStorage.getItem('triggerAction');

        switch (value) {
            case 'connect-metamask': connectMetamaskAction(true);
        }
    }, [connectMetamaskAction]);

    return useCallback( () => {
        if (initRef.current) {
            return;
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params[GET_PARAM_NAME]) {
            sessionStorage.setItem('triggerAction', params[GET_PARAM_NAME]);
            delete params[GET_PARAM_NAME];
            Router.push({
                    pathname: window.location.pathname,
                    query: params
                },
                undefined, { shallow: true }
            );
            setTimeout(handleAction, 0);
        }
        initRef.current = true;
    }, [handleAction]);
};
