import dynamic from 'next/dynamic';
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';

const HeaderUserMenuModalComponent = dynamic(() => import('./profile'), {
    ssr: false,
});

export const UserMenuModal = modalRegistry.register({
    id: 'HeaderUserMenuModalComponent',
    component: HeaderUserMenuModalComponent,
});
