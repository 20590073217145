import { createSlice } from '@reduxjs/toolkit';

import { fetchNftByIdsThunk } from 'src/features/common/collectible/ducks/nft-list/nft-list.thunk';
import { NftListAdapter } from './nft-list.adapter';

export const nftListSlice = createSlice({
    name: 'nft-list',
    initialState: NftListAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNftByIdsThunk.fulfilled, (state, action) => {
                NftListAdapter.setMany(state, action.payload);
            });
    },
});

export const { actions } = nftListSlice;

export default nftListSlice.reducer;