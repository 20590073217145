import { createSlice } from '@reduxjs/toolkit';

import { HighlightedCollectionsAdapter } from './highlighted-collections.adapter';
import { highlightedCollectionsFetchThunk } from './highlighted-collections.thunk';

const getInitialState = () => HighlightedCollectionsAdapter.getInitialState({
    isLoading: false,
    pager: {},
});

export const highlightedCollectionsSlice = createSlice({
    name: 'collections/highlightedCollections',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(highlightedCollectionsFetchThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(highlightedCollectionsFetchThunk.fulfilled, (state, action) => {
                HighlightedCollectionsAdapter.setMany(state, action.payload.collections || []);
                state.isLoading = false;
                state.pager = action.payload.pager;
            })
            .addCase(highlightedCollectionsFetchThunk.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { actions } = highlightedCollectionsSlice;

export default highlightedCollectionsSlice.reducer;
