import { INotification, IRegistryNotification } from 'src/services/notifications/types';

class NotificationsRegistry {
    private notifications: IRegistryNotification[] = [];

    private listeners: any[] = [];

    add(notification: INotification) {
        const id = Date.now();
        const obj = {
            closable: true,
            ...notification,
            id,
            remove: () => this.remove(id)
        };
        this.notifications.unshift(obj);
        this.emit();
        return obj;
    }

    remove(id: number) {
        this.notifications = this.notifications.filter(item => item.id !== id);
        this.emit();
    }

    emit() {
        this.listeners.forEach((cb) => cb([...this.notifications]));
    }

    subscribe(cb: any) {
        this.listeners.push(cb);
    }

    unsubscribe(cb: any) {
        this.listeners = this.listeners.filter(listener => listener !== cb);
    }
}

const notificationsRegistry = new NotificationsRegistry();

export default notificationsRegistry;
