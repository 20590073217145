import React from 'react';
import { ISvgIcon } from 'src/ui/common/atoms/svg-icon/svg-icon.types';
import { StyledSvg } from 'src/ui/common/atoms/svg-icon/svg-icon.styles';

export const SvgIcon = React.forwardRef<SVGSVGElement, ISvgIcon>((props, ref) => {
    const { variant, icon, width = 18, height } = props;
    return (
        <StyledSvg
            {...props}
            ref={ref}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height ?? width}
        >
            <use xlinkHref={`/icons/svg-icons-sprite-${variant}.svg#${icon}`} />
        </StyledSvg>
    );
});
