/**
 * Libs
 */
import dynamic from 'next/dynamic';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Hooks
 */
import { useSidebar } from 'src/features/common/app/hooks/use-sidebar';

const ConnectWalletSidebarContent = dynamic(() => import('../containers/connect-wallet-sidebar-content'), {
    ssr: false,
});

export const useRenderConnectWalletSidebar = () => {
    const { t } = useTranslation(['components']);
    const { render } = useSidebar();

    const locale: { title: string } = useMemo(
        () => ({
            ...t('connectWalletSidebar', { ns: 'components', returnObjects: true }),
        }),
        [t]
    );

    return useCallback(() => {
        render(
            t(locale.title),
            // @ts-ignore
            <ConnectWalletSidebarContent />
        );
    }, [render, locale, t]);
};
