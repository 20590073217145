import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { ExploreNftsAdapter } from './explore-nfts.adapter';

export const exploreNftsSelect = (state: RootState) => state.features.exploreNfts;

export const exploreNftsSelectors = ExploreNftsAdapter.getSelectors();

export const selectNfts = createSelector(
    exploreNftsSelect,
    (state) => exploreNftsSelectors.selectAll(state)
);

export const selectNftsEntities = createSelector(
    exploreNftsSelect,
    (state) => exploreNftsSelectors.selectEntities(state)
);

export const selectFilter = createSelector(
    exploreNftsSelect,
    (state) => state.filter
);

export const selectPager = createSelector(
    exploreNftsSelect,
    (state) => state.pager
);

export const selectSorting = createSelector(
    exploreNftsSelect,
    (state) => state.sorting
);

export const selectPages = createSelector(
    exploreNftsSelect,
    (state) => state.pages
);

export const selectByPage = createSelector(
    exploreNftsSelect,
    (state) => state.byPage
);

export const selectTotal = createSelector(
    exploreNftsSelect,
    (state) => state.total
);

export const selectVisibleTotal = createSelector(
    exploreNftsSelect,
    (state) => state.visibleTotal
);

export const selectLoading = createSelector(
    exploreNftsSelect,
    (state) => state.loading
);
