import { LotI } from '@tacans/shared-marketplace-layer';
import { getLotType } from 'src/utils/lot.utils';
import { MinMaxNftPrices, NftSaleDetails } from 'src/features/common/collectible/collectible.typing';

export const calculateNftSaleDetails = (lots: LotI[]): Omit<NftSaleDetails, 'nftId'> => {
    const activeLots = lots.filter(lot => lot.isActive);

    const res: Omit<NftSaleDetails, 'nftId'> = {
        isOnSale: !!activeLots.length,
        activeLotsCount: activeLots.length,
        nanoBid: 0,
        nanoPrice: 0,
        copiesOnSale: 0,
        status: 'completed'
    };

    for(const lot of activeLots) {
        res.copiesOnSale += +(lot.remainCopies ?? lot.copies ?? 0);

        const type = getLotType(lot.type);
        if (type.isAuction) {
            const minBid = Number(lot.minNanoBid || 0) + Number(lot.minNanoBidStep || 0);
            if (res.nanoBid === 0 || res.nanoBid! > minBid) {
                res.nanoBid = minBid;
            }

            if (activeLots.length === 1) {
                res.isAuction = true;
                res.auctionStartAt = lot.auctionStartAt;
                res.auctionEndAt = lot.auctionEndAt;
            }
        } else {
            const price = Number(lot.nanoPrice || 0);
            if (res.nanoPrice === 0 || res.nanoPrice > price) {
                res.nanoPrice = price;
            }
        }
    }

    return res;
};

export const calculateMinMaxNftPrices = (lots: LotI[]): MinMaxNftPrices => {
    const res = {
        minNanoPrice: 0,
        maxNanoPrice: 0,
    };

    for (const lot of lots) {
        const type = getLotType(lot.type);
        const price = type.isAuction
            ? Number(lot.minNanoBid || 0) + Number(lot.minNanoBidStep || 0)
            : Number(lot.nanoPrice);

        if (res.minNanoPrice === 0 || price < res.minNanoPrice) {
            res.minNanoPrice = price;
        }
        if (price > res.maxNanoPrice) {
            res.maxNanoPrice = price;
        }
    }

    return res;
};
