export type Subscriber = (payload?: any) => void;

export enum AnalyticsType {
    BROADCAST_USER = 'BROADCAST_USER',
    NFT_CLICK = 'NFT_CLICK',
    NFT_VIEW = 'NFT_VIEW',
    NFT_BUYING = 'NFT_BUYING',
    NFT_SUCCESS_CREATE = 'NFT_SUCCESS_CREATE',
    CONTACT_SELLER = 'CONTACT_SELLER',
    LOT_PLACE_BID = 'LOT_PLACE_BID'
}

export interface IBroadCastUser {
    userId?: string;
}
