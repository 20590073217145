export const INITIAL_STATE = {
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {},
};