import { ITextInput } from 'src/ui/common/atoms/text-input/text-input.types';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledInput = styled.input<ITextInput>`
    height: 40px;
    font-family: inherit;
    font-weight: 400;
    font-size: 14px;
    padding: 0 15px;
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid #e6e6e6;
    width: ${({ stretch }) => (stretch ? '100%' : '')};
    transition: border-color ease-in-out ${speed.input}, background-color ease-in-out ${speed.input};

    &:disabled {
        opacity: 1;
        color: ${({ theme }) => theme.colors.gs200};
    }

    ::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.gs300};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button,
    &[type='number'] {
        -webkit-appearance: none;
        margin: 0;
    }

    &:hover {
        border-color: ${({ theme }) => theme.colors.gs400};
    }

    &:focus {
        border-color: ${({ theme }) => theme.colors.black};
    }

    ${media.md`
        height: 50px;
  `}
`;