import { overrideApiClientDefaults } from '@tacans/shared-marketplace-layer';
import React, { useEffect } from 'react';
import { useInitActionTriggersOnLoad } from 'src/features/common/action-triggers/hooks/use-init-action-triggers-on-load';
import AnalyticsController from 'src/features/common/analytics/conponents/analytics-controller';
import { SideBar } from 'src/features/common/app/components/sidebar';
import { SidebarContext } from 'src/features/common/app/components/sidebar/sidebar.context';
import AppThemeProvider from 'src/features/common/app/components/theme-provider';
import { useSidebarContextState } from 'src/features/common/app/hooks/use-sidebar=context-state';
import { ModalManagerProvider } from 'src/features/common/modal/components/modal-manager-provider';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import NotificationContainer from 'src/services/notifications/notification-container';
import { useInitialFetch } from './bootstrap.hooks';

overrideApiClientDefaults({
    baseURL: process.env.INTERNAL_API_URL || '/api',
});

const Bootstrap: React.FC = ({ children }) => {
    const sidebarContextValue = useSidebarContextState();
    useInitialFetch();
    const { isTechContemporary, isVemasallaueTemplate, isPhoenxholding, isSyndicate } = useGetUniverseTemplate();

    const activeTheme = () => {
        if (isTechContemporary) {
            return 'tech';
        }
        if (isVemasallaueTemplate) {
            return 'vem';
        }
        if (isPhoenxholding) {
            return 'phoenx';
        }
        if (isSyndicate) {
            return 'syndicate';
        }
        return 'dark';
    };

    const initActionTriggersOnLoad = useInitActionTriggersOnLoad();

    useEffect(() => {
        initActionTriggersOnLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppThemeProvider theme={activeTheme()}>
            <ModalManagerProvider>
                <SidebarContext.Provider value={sidebarContextValue}>
                    {children}
                    <SideBar />
                </SidebarContext.Provider>
                <NotificationContainer />
                <AnalyticsController />
            </ModalManagerProvider>
        </AppThemeProvider>
    );
};

export default Bootstrap;