import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { CalendarAdapter } from './calendar.adapter';

export const eventsSelect = (state: RootState) => state.features.events;

export const eventsSelectors = CalendarAdapter.getSelectors();

export const selectArts = createSelector(
    eventsSelect,
    (state) => eventsSelectors.selectAll(state)
);

export const selectArtsEntities = createSelector(
    eventsSelect,
    (state) => eventsSelectors.selectEntities(state)
);

export const selectFilter = createSelector(
    eventsSelect,
    (state) => state.filter
);

export const selectPager = createSelector(
    eventsSelect,
    (state) => state.pager
);

export const selectSorting = createSelector(
    eventsSelect,
    (state) => state.sorting
);

export const selectPages = createSelector(
    eventsSelect,
    (state) => state.pages
);

export const selectByPage = createSelector(
    eventsSelect,
    (state) => state.byPage
);

export const selectTotal = createSelector(
    eventsSelect,
    (state) => state.total
);

export const selectVisibleTotal = createSelector(
    eventsSelect,
    (state) => state.visibleTotal
);

export const selectLoading = createSelector(
    eventsSelect,
    (state) => state.loading
);

