import { FONT_SIZE } from 'src/ui/common/atoms/typography/typography.constants';
import { TTypographySize } from 'src/ui/common/atoms/typography/typography.types';

export const getFontWeight = ({ bold, semiBold, medium }: {bold?: boolean, semiBold?: boolean, medium?: boolean}) => {
    const weight = { fontWeight: 400 };
    if(bold) {
        weight.fontWeight = 700;
        return weight;
    }
    if(semiBold) {
        weight.fontWeight = 600;
        return weight;
    }
    if(medium) {
        weight.fontWeight = 500;
        return weight;
    }

    return weight;
};

export const getFontSize = (size?: TTypographySize) => size ? FONT_SIZE[size] : FONT_SIZE.base;
