import { createSlice } from '@reduxjs/toolkit';

import { BannerNftsAdapter } from './banner-nfts.adapter';
import { bannerNftsFetchThunk } from './banner-nfts.thunk';

const getInitialState = () => BannerNftsAdapter.getInitialState({
    isLoading: false,
    pager: {},
});

export const bannerNftsSlice = createSlice({
    name: 'nfts/bannerNfts',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(bannerNftsFetchThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(bannerNftsFetchThunk.fulfilled, (state, action) => {
                BannerNftsAdapter.setMany(state, action.payload.list || []);
                state.isLoading = false;
                state.pager = action.payload.pager;
            })
            .addCase(bannerNftsFetchThunk.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { actions } = bannerNftsSlice;

export default bannerNftsSlice.reducer;
