import { IBroadCastUser } from 'src/services/analytics/analytics.types';
import { logger } from 'src/services/analytics/analytics.config';

const broadcastUser = (payload: IBroadCastUser) => {
    let data: any = { 'logged': 'no' };
    if (payload.userId) {
        data = { 'logged': 'yes' , 'user_id': payload.userId };
    }
    (window as any).dataLayer.push(data);
    logger('broadcastUser', data);
};

const logNftClick = async (payload: any) => {
    (window as any).dataLayer.push(payload);
    logger('logNftClick', payload);
};

const logBuyFixedClick = async (payload: any) => {
    (window as any).dataLayer.push(payload);
    logger('logBuyFixedClick', payload);
};

const logPlaceBid = async (payload: any) => {
    (window as any).dataLayer.push(payload);
    logger('logLotPlaceBid', payload);
};

const logContactSeller = async () => {
    const payload = { 'event': 'contact_seller' };
    (window as any).dataLayer.push(payload)  ;
    logger('logContactSeller', payload);
};

const logSuccessCreateNft = async (payload: any) => {
    (window as any).dataLayer.push(payload)  ;
    logger('logSuccessCreateNft', payload);
};

export default {
    broadcastUser,
    logNftClick,
    logBuyFixedClick,
    logContactSeller,
    logSuccessCreateNft,
    logPlaceBid
};
