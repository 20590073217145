import { ARTI, RequestPagerI } from '@tacans/shared-marketplace-layer';

export interface IPriceRange {
    from: string | null;
    to: string | null;
}

export enum ExploreSort {
    PRICE_ASC = 'price_asc',
    PRICE_DESC = 'price_desc',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
    LATEST = 'latest',
}

export interface IExploreFilters {
    collectionIds?: string[] | number[],
    artistIds?: string[] | number[],
    ownerIds?: string[] | number[],
    artistCountries?: string[],
    categoryIds?: string[] | number[],
    priceRange?: IPriceRange;
    keywords?: string;
    isPhysical?: boolean[];
}

export interface IExploreArtsFilter {
    filter: IExploreFilters;
    sorting: ExploreSort;
    pager: RequestPagerI;
}

export interface IExploreArtsState extends IExploreArtsFilter {
    loading: boolean;
    total: number;
    /**
     *  when infinite scroll disabled - prevent loading new, set limited total
     */
    visibleTotal: number;
    pages: number[];
    byPage: Record<number, ARTI['id'][]>
}