import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { IS_SSR } from 'src/utils/helpers.utils';

const guardRoutes: { [key in RouteE]?: boolean } = {
    [RouteE.CREATOR_CREATE]: true,
    [RouteE.UNIVERSE_CREATORS]: true,
    [RouteE.UNIVERSE_CREATORS_EDIT]: true,
    [RouteE.COLLECTIBLE_CREATE]: true,
    [RouteE.COLLECTIBLE_CREATE_BY_TYPE]: true,
    [RouteE.COLLECTION_CREATE]: true,
    [RouteE.COLLECTION_EDIT]: true,
    [RouteE.PROFILE_EDIT]: true,
    [RouteE.PROFILE_PASSWORD]: true,
    [RouteE.PROFILE_PERSONAL_INFORMATION]: true,
    [RouteE.AUCTION_APPROVALS]: true,
    [RouteE.USER_EDIT]: true,
    [RouteE.PROFILE_MANAGE_WALLET]: true,
    [RouteE.UNIVERSE_WIZARD]: true,
    [RouteE.LAUNCHPAD_CREATE]: true,
    [RouteE.LAUNCHPAD_EDIT]: true,
    [RouteE.CHECKOUT]: true,
    [RouteE.PROFILE]: true,
    [RouteE.BILLING_DETAILS]: true,
    [RouteE.MY_ART]: true,
    [RouteE.SUCCESS]: true,
    [RouteE.CANCEL]: true,
};

const AuthGuard: React.FC = ({ children }) => {
    const { pathname, push } = useRouter();
    const isAuthorized = useAuthorized();
    const formattedPathname = useMemo(() => pathname.replace('[', ':').replace(']', '') as RouteE, [pathname]);
    const isGuardRoute = guardRoutes[formattedPathname];
    const isRestricted = !isAuthorized && isGuardRoute;

    useEffect(() => {
        if (isRestricted) {
            push(RouteE.HOME);
        }
    }, [isRestricted, push]);

    if (!IS_SSR && isRestricted) {
        return null;
    }

    return <>{children}</>;
};

export default AuthGuard;