import { useUniverse } from 'src/features/common/universe/hooks/use-universe';

export const useGetUniverseTemplate = () => {
    const { isUniverse, domain } = useUniverse();
    const isNinjaTemplate = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_NINJA;
    const isOceanTemplate = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_OCEAN;
    const isNftSuperlab = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_NFT_SUPERLAB;
    const isVemasallaueTemplate = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_VEMASALLAUE;
    const isSportsMuseumTemplate = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_SPORTS_MUSEUM;
    const isTechContemporary = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_TECH_CONTEMPORARY;
    const isWorldOfArtists = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_WORLD_OF_ARTISTS;
    const isSyndicate = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_SYNDICATE;
    const isPhoenxholding = isUniverse && domain === process.env.NEXT_PUBLIC_UNIVERSE_DOMAIN_PHOENXHOLDING;

    return {
        isNinjaTemplate,
        isOceanTemplate,
        isNftSuperlab,
        isVemasallaueTemplate,
        isSportsMuseumTemplate,
        isTechContemporary,
        isWorldOfArtists,
        isSyndicate,
        isPhoenxholding,
    };
};