import { IUser } from '@tacans/shared-marketplace-layer';
import React from 'react';
import { useAvatarAssets } from 'src/features/common/profile/hooks/use-avatar-assets';
import { useDisplayName } from 'src/features/common/user/hooks/use-display-name.hooks';
import { Avatar } from 'src/ui/common/atoms/avatar';
import { VariantT, SizeT } from 'src/ui/common/atoms/avatar/avatar.types';

interface IProps {
    user?: IUser;
    variant: VariantT;
    size: SizeT;
    onClick?: () => void;
    className?: string;
    borderRadius?: string;
}

const UserAvatar: React.VFC<IProps> = ({ user, size, variant, onClick, className, borderRadius }) => {
    const name = useDisplayName(user);
    const { src } = useAvatarAssets({ profile: user });

    return (
        <Avatar
            size={size}
            variant={variant}
            onClick={onClick}
            src={src}
            name={name}
            className={className}
            borderRadius={borderRadius}
        />
    );
};

export default UserAvatar;
