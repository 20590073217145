import { AuthTokenCookieService } from '@tacans/shared-marketplace-layer';
import type { AxiosError, AxiosInstance } from 'axios';
import { Store } from 'redux';
import { RootState } from 'src/store';

export const apiClientLoginModalMiddleware = (apiClient: AxiosInstance, store: Store<RootState>): AxiosInstance => {
    const responseFailureHandler = (axiosError: AxiosError): Promise<AxiosError> => {
        const refreshToken = AuthTokenCookieService.getToken('refresh');

        if (axiosError?.response?.status === 401 && !refreshToken) {
            // TODO: open login modal if not opened
            // const openedModal = store.getState().global.modalController.modal;
            // if (openedModal !== 'Auth / Login') {
                AuthTokenCookieService.removeToken('access');
                AuthTokenCookieService.removeToken('refresh');

                // TODO redesign modals
                // store.dispatch(ModalControllerActions.openModal({ modal: 'Auth / Login' }));
            // }
        }
        return Promise.reject(axiosError);
    };

    apiClient.interceptors.response.use(undefined, responseFailureHandler);

    return apiClient;
};
