import { IListFilter, ESaleType, ESortBy, StandardTypeE } from '@tacans/shared-marketplace-layer';
import { makePriceInCents } from 'src/utils/entity.utils';
import { ExploreSort, IExploreFilters } from './ducks/explore-nfts/explore-nfts.types';

export const createFilterFieldsPayload = (meta: IExploreFilters): IListFilter => {
    const fields: IListFilter  = {};

    meta = meta || {};

    const categoryValueExists = Array.isArray(meta.categoryId) ? !!meta.categoryId.length : !!meta.categoryId;

    if (categoryValueExists && meta.categoryId) {
        fields.categoryIds = meta.categoryId;
    }

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    const collectionValueExists = Array.isArray(meta.collectionId) ? !!meta.collectionId.length : !!meta.collectionId;

    if (meta.collectionId && collectionValueExists) {
        fields.collectionIds = Array.isArray(meta.collectionId) ? meta.collectionId : [meta.collectionId];
    }

    if (meta.ownerIds?.length) {
        fields.ownerIds = meta.ownerIds;
    }

    if (meta.sellType?.length === 1) {
        fields.saleType = meta.sellType[0] as ESaleType;
    }
    if (meta.sellType?.length === 2) {
        fields.saleTypes = meta.sellType as ESaleType[];
    }

    if (meta.standard?.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        fields.standard = meta.standard[0].toUpperCase() as StandardTypeE;
    }

    if (meta.priceRange?.from) {
        fields.priceFrom = { value: makePriceInCents(+meta.priceRange.from) };
    }

    if (meta.priceRange?.to) {
        fields.priceTo = { value :makePriceInCents(+meta.priceRange.to) };
    }

    if (meta.artistCountry?.length) {
        fields.artistCountries = meta.artistCountry;
    }

    if (meta.artistId?.length) {
        fields.artistIds = meta.artistId;
    }

    if (meta.blockchainId?.length) {
        fields.blockchainIds = meta.blockchainId.map(id => Number(id));
    }

    if (meta.ownerId) {
        fields.ownerIds = Array.isArray(meta.ownerId) ? meta.ownerId : [meta.ownerId];
    }

    return fields;
};

export const mapSorting = (sort: ExploreSort | null) => {
    switch(sort) {
        case ExploreSort.LATEST:
            return {
                sortBy: ESortBy.Date, 
                ascending: false
            };
        case ExploreSort.OLDEST:
            return {
                sortBy: ESortBy.Date,
                ascending: true
            };
        case ExploreSort.PRICE_ASC:
            return {
                sortBy: ESortBy.Price,
                ascending: true
            };
        case ExploreSort.PRICE_DESC:
            return {
                sortBy: ESortBy.Price, 
                ascending: false
            };
        case ExploreSort.NAME_ASC:
            return {
                sortBy: ESortBy.Name,
                ascending: true
            };
        case ExploreSort.NAME_DESC:
            return {
                sortBy: ESortBy.Name,
                ascending: false
            };
        case ExploreSort.QUANTITY_ASC:
            return {
                sortBy: ESortBy.Copies,
                ascending: true
            };
        case ExploreSort.QUANTITY_DESC:
            return {
                sortBy: ESortBy.Copies,
                ascending: false
            };
        default: 
            return {
                sortBy: ESortBy.Date, 
                ascending: false
            };
    }
};