import { techTheme } from 'src/features/common/app/components/theme-provider/theme';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { media } from 'src/ui/common/mixins/media';
import { Button } from 'src/ui/white-labels/techcontemporary/atoms/button';
import styled from 'styled-components';

export const StyledHeader = styled(FlexBox)`
    position: relative;
    z-index: 9;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    padding: 0 16px;
    height: 88px;
    align-items: center;

    ${media.md`
        margin: 16px auto;
        padding: 0 48px;
        width: 100%;
        max-width: 1440px;
        height: 64px;
        border-bottom: none;
  `}
`;

export const StyledButton = styled(Button)`
    margin: 0 5px 0 0;

    ${media.sm`
        margin: 0 10px 0 0;
    `}

    ${media.md`
        margin: 0 10px 0 0;
    `}

  ${media.lg`
        margin: 0 10px 0 0;
    `}
`;

export const StyledIconButton = styled(Button)`
    position: relative;
`;

export const StyledCartTotal = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.gs600};
    top: 11px;
    right: 8px;
`;

export const UserMenuWrapper = styled(FlexBox)`
    margin: 12px 12px 12px 0;
    ${media.sm`
        margin: 12px 18px 12px 0;
    `}

    ${media.md`
        margin: 12px 18px 12px 0;
    `}

  ${media.lg`
        margin: 12px 18px 12px 0;
    `}
`;

export const StyledLinksVisible = styled.div`
    height: 100%;
    display: none;

    ${media.md`
        display: block;
    `}
`;

export const DesktopLink = styled.a<{ active?: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({ theme }) => `${theme.colors.text}`};
    font-size: 14px;
    padding: 10px 16px;

    &:hover {
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(5px);
    }

    ${({ active, theme }) =>
        active &&
        `   
            border-radius: 24px;
            background: rgba(255, 255, 255, 0.10);
            backdrop-filter: blur(5px); 
        `}
`;

export const StyledDesktopLinks = styled(FlexBox)`
    margin: 0 20px 0 40px;
    height: 100%;
`;

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    & svg {
        margin-top: 4px;
    }
`;

export const StyledButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export const StyledProfileWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const ProfileDropdownMenu = styled.div<{ open: boolean }>`
    position: absolute;
    right: 0;
    top: 45px;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-evenly;
    gap: 4px;
    background-color: ${({ theme }) => theme.colors.grey800};
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 16px;
    z-index: 1;
    min-width: 191px;
    padding: 8px;
`;

export const ProfileDropdownOption = styled.div`
    display: flex;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 12px 10px 16px;

    &:hover {
        cursor: pointer;
        border-radius: 56px;
        background: ${({ theme }) => theme.colors.gs450};
    }

    & svg {
        margin-right: 8px;
    }
`;

export const StyledBuyScreenWrapper = styled(FlexBox)`
    margin: 0 20px 0 0;
    align-items: center;
`;

export const StyledBadge = styled.div`
    padding: 3px 10px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: default;
    justify-content: center;
    gap: 8px;
    background-color: ${techTheme.colors.gs450};
    color: ${techTheme.colors.white};
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.22px;
    text-transform: uppercase;
    font-weight: 500;
`;