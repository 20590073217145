import { IUsersState, UsersSort } from './users.types';

export const INITIAL_FILTER_STATE: IUsersState = {
    filter: {
        keywords: '',
    },
    sorting: UsersSort.LATEST,
    pager: {
        page: 0,
        limit: 12,
    },
    total: 0,
    loading: false,
    pages: [],
    byPage: {}
};
