import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withOrientationChange } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDebouncedEffect } from 'src/hooks/use-debounced-effect';
import { DropDown } from 'src/ui/common/organisms/dropdown';
import { StyledContent } from './simple-dropdown.styles';
import { IProps } from './simple-dropdown.types';

const SimpleDropDownComponent: React.FC<IProps> = ({
    horizontalBindTo,
    className,
    nodeRef,
    fitContentWidth,
    testId,
    children,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [opened, setOpened] = useState(false);
    const [contentMaxHeight, setContentMaxHeight] = useState<number | undefined>();
    const { ref, inView } = useInView({
        skip: !opened,
        threshold: 0.9,
        initialInView: true,
    });

    const calculateContentMaxHeight = useCallback(() => {
        if (dropdownRef.current) {
            const { top } = dropdownRef.current?.getBoundingClientRect();
            const { innerHeight } = window;
            setContentMaxHeight(innerHeight - top);
        }
    }, []);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpened((s) => !s);
    }, []);

    const close = useCallback(() => {
        setOpened(false);
    }, []);

    const handleOutSideClick = useCallback(
        (e) => {
            if (opened) {
                close();
            }
        },
        [close, opened]
    );

    useEffect(() => {
        nodeRef.current?.addEventListener('mousedown', handleClick);

        return () => {
            nodeRef.current?.removeEventListener('mousedown', handleClick);
        };
    }, [nodeRef, handleClick]);

    useEffect(() => {
        if (opened) {
            calculateContentMaxHeight();
        }
    }, [calculateContentMaxHeight, opened]);

    const handleNotInView = useCallback(() => {
        if (opened && !inView) {
            setOpened(false);
        }
    }, [inView, opened]);

    useDebouncedEffect(handleNotInView, 200, [handleNotInView]);

    return (
        <DropDown
            opened={opened}
            nodeRef={nodeRef}
            dropdownRef={dropdownRef}
            fitContentWidth={fitContentWidth}
            horizontalBindTo={horizontalBindTo}
        >
            <DropDown.Content>
                <OutsideClickHandler disabled={!opened} onOutsideClick={handleOutSideClick}>
                    <StyledContent
                        ref={ref}
                        className={className}
                        data-test-id={`${testId ?? 'simple'}-dropdown`}
                        style={{ maxHeight: `${contentMaxHeight}px` }}
                        onClick={close}
                    >
                        {children}
                    </StyledContent>
                </OutsideClickHandler>
            </DropDown.Content>
        </DropDown>
    );
};

export const SimpleDropDown = withOrientationChange<React.PropsWithChildren<IProps>>(SimpleDropDownComponent);