import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { GetInfluencersReferralsAdapter } from './get-influencers-referrals.adapter';

export const getAllInfluencerReferralsSelect = (state: RootState) => state.features.influencerRefferals;

export const getAllInfluencerReferralsSelectors = GetInfluencersReferralsAdapter.getSelectors();

export const selectFilter = createSelector(getAllInfluencerReferralsSelect, (state) => state.filter);

export const selectPager = createSelector(getAllInfluencerReferralsSelect, (state) => state.pager);

export const selectReferralsEntities = createSelector(getAllInfluencerReferralsSelect, (state) =>
    getAllInfluencerReferralsSelectors.selectEntities(state)
);

export const selectByPage = createSelector(getAllInfluencerReferralsSelect, (state) => state.byPage);

export const selectTotal = createSelector(getAllInfluencerReferralsSelect, (state) => state.total);

export const selectPages = createSelector(getAllInfluencerReferralsSelect, (state) => state.pages);

export const selectVisibleTotal = createSelector(getAllInfluencerReferralsSelect, (state) => state.visibleTotal);

export const selectLoading = createSelector(getAllInfluencerReferralsSelect, (state) => state.loading);

export const selectStatistic = createSelector(getAllInfluencerReferralsSelect, (state) => state.statistic);
export const selectMonthStatistic = createSelector(getAllInfluencerReferralsSelect, (state) => state.monthStatistic);