import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { HeaderSearch } from 'src/features/common/app/components/header-search';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { DesktopLinks } from 'src/features/white-labels/phoenxholding/app/components/header/desktop-links';
import { getUrlWithPublicStorage } from 'src/utils/get-url-with-public-host';
import { StyledHeader, StyledLogo } from './header.styles';
import { UserProfile } from './user-profile';

export const Header = () => {
    const { logo, isUniverse } = useUniverse();
    const { pathname } = useRouter();
    const isDefault = isUniverse || (!isUniverse && pathname !== RouteE.MARKETPLACE);

    const logoLink = isUniverse ? getUrlWithPublicStorage(logo) : '/logo.svg';

    return (
        <StyledHeader alignItems="center" data-test-id="header" isDefault={isDefault}>
            <StyledLogo alignItems="center" justifyContent="center" isDefault={isDefault}>
                <Link href="/" prefetch={false}>
                    <a>
                        <img
                            src={logoLink}
                            width={isUniverse ? undefined : 27}
                            height={isUniverse ? undefined : 34}
                            alt="logo"
                        />
                    </a>
                </Link>
            </StyledLogo>
            <HeaderSearch />
            <DesktopLinks />
            <UserProfile />
        </StyledHeader>
    );
};