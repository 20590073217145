import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { ArtistAdapter } from './artist.adapter';
import {
    artistFetchThunk
} from './artist.thunk';

const emptyInitialState = ArtistAdapter.getInitialState();

export const artistSlice = createSlice({
    name: 'artist',
    initialState: emptyInitialState,
    reducers: {
        clearArtist: () => emptyInitialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.artists.artist,
            }))
            .addCase(artistFetchThunk.fulfilled, (state, action: any) => {
                const { artist } = action.payload.response.data;
                ArtistAdapter.setOne(state, artist);
            });
    },
});

export const { actions } = artistSlice;

export default artistSlice.reducer;
