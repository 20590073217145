import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ModalRegistryEntityItem } from '../../modal.types';
import { modalRegistry } from '../../providers/modal.registry';
import { StyledWrapper } from './modal-component.styles';

interface Props {
    modal: ModalRegistryEntityItem;
}

const ModalComponent = ({ modal }: Props) => {
    const router = useRouter();
    const { id, component: Component, opened, props: modalProps } = modal;

    const closeModal = useCallback(() => {
        const modal = modalRegistry.get(id);
        modal?.close();
    }, [id]);

    const onOverlayClick = useCallback(
        (e) => {
            if (e.target.closest?.('[data-component="select"]')) {
                return;
            }
            !modalProps?.preventEasyClose && closeModal();
        },
        [modalProps, closeModal]
    );

    useEffect(() => {
        router.events.on('routeChangeStart', closeModal);

        return () => {
            router.events.off('routeChangeStart', closeModal);
        };
    }, [closeModal, router]);

    if (!Component || !opened) {
        return null;
    }

    return (
        <StyledWrapper data-component="modal-component-wrapper">
            <OutsideClickHandler onOutsideClick={onOverlayClick} display="contents">
                {/* @ts-ignore */}
                <Component {...modalProps} closeModal={closeModal} />
            </OutsideClickHandler>
        </StyledWrapper>
    );
};

export default ModalComponent;
