import { ExploreSort, IExploreNftsState } from 'src/features/common/explore/ducks/explore-nfts/explore-nfts.types';

export const INITIAL_FILTER_STATE: IExploreNftsState = {
    filter: {
        keywords: '',
        sellType: [],
        standard: [],
        ownerIds: [],
        blockchainId: [],
        priceRange: { from: '', to: '' },
        categoryId: [],
        collectionId: [],
        artistCountry: [],
    },
    sorting: ExploreSort.LATEST,
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {},
};
