import React, { useCallback } from 'react';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SeeMoreIcon } from 'src/ui/common/layouts/card-box/card-box.styles';
import { useAccordionContext } from './accordion.provider';
import { StyledAccordionHeader } from './accordion.styles';
import { IAccordionItem } from './accordion.types';

export const AccordionItemHeader = ({ itemId, children }: IAccordionItem) => {
    const { openedItemId, setOpenedItemId, handleItemClick } = useAccordionContext();
    const isOpened = itemId === openedItemId;

    const onItemClick = useCallback(() => {
        isOpened ? setOpenedItemId(null) : setOpenedItemId(itemId);
        handleItemClick?.(!isOpened, itemId);
    }, [handleItemClick, setOpenedItemId, itemId, isOpened]);

    if (typeof children === 'function') {
        return children({
            onClick: onItemClick,
            opened: isOpened
        });
    }

    return (
        <StyledAccordionHeader isOpened={isOpened} onClick={onItemClick} id={itemId}>
            <FlexBox alignItems='center' justifyContent='space-between'>
                <div>{children}</div>
                <SeeMoreIcon opened={isOpened} icon='chevronDown' variant='regular' />
            </FlexBox>
        </StyledAccordionHeader>
    );
};
