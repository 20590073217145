/**
 * Libs
 */
import styled from 'styled-components';

/**
 * Utils
 */
import { media } from 'src/ui/common/mixins/media';

export const StyledHeaderNotifications = styled.div`
  position: relative;
  margin: 0 5px 0 0;
  
  ${media.md`
    margin: 0 10px 0 0;
  `}
  
  ${media.md`
    & > div:first-child {
      width: 50px;
      height: 50px;
      margin: 0;
    }
  `}
`;

export const StyledNotificationFlag = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 13px;
  right: 22px;
  background-color: ${({ theme }) => theme.colors.pink500};

  ${media.md`
    top: 17px;
    right: 17px;
  `}
`;
