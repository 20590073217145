import { IAddArtToCartPayload, IResponseMetaStatus } from '@tacans/shared-marketplace-layer';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { addToCartThunk, fetchCartThunk } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.thunk';
import { useAppDispatch } from 'src/hooks/app';

export const useFetchCartThunk = () => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        const res = await dispatch(fetchCartThunk());
        return res;
    }, [dispatch]);
};

export const useAddToCart = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        async (data: IAddArtToCartPayload) => {
            const { payload } = await dispatch(addToCartThunk(data));
            return payload as { response: AxiosResponse<IResponseMetaStatus> };
        },
        [dispatch]
    );
};