import { createAsyncThunk } from '@reduxjs/toolkit';
import { CollectionAPI } from '@tacans/shared-marketplace-layer';

export const fetchCollectionStatisticsThunk = createAsyncThunk(
    'collection-statistics/fetch',
    async (ids: string[]) => {
        const { data } = await CollectionAPI.fetchStatistics(ids);
        return data;
    }
);
