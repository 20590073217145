import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReferralApi } from '@tacans/shared-marketplace-layer';
import { RootState } from 'src/store';
import { useCreateGetAllReferralsRequestPayload } from '../../hooks/use-create-get-influencer-referrals-request-payload';
import { selectPager } from './get-influencers-referrals.selectors';

export const fetchAllInfluencerReferralsThunk = createAsyncThunk('get-influencer-referrals/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const pager = selectPager(rootState);

    const payload = useCreateGetAllReferralsRequestPayload({    
        pager: {
            page: pager?.page ?? 0,
            limit: pager?.limit ?? 10,
        },
    });
    const { data } = await ReferralApi.fetchInfluencerReferrals(payload);
    return data;
});