import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Typography } from 'src/ui/common/atoms/typography';
import styled from 'styled-components';

export const CartDropDownMenu = styled.div<{ opened: boolean }>`
    position: absolute;
    border: 1px solid rgba(255, 255, 255, 0.06);
    box-shadow: 0 4px 100px 0 rgba(0, 0, 0, 0.05);
    right: 10px;
    top: 55px;
    gap: 10px;
    background-color: ${({ theme }) => theme.colors.grey800};
    display: ${({ opened }) => (opened ? 'block' : 'none')};
    border-radius: 16px;
    z-index: 1;
    width: 549px;
    padding: 24px;
`;

export const StyledScrollWrapper = styled(FlexBox)`
    max-height: 224px;
    overflow: auto;
    margin-bottom: 24px;
`;

export const CartDropDownTitle = styled(Typography)`
    margin-right: 4px;
    font-size: 20px;
`;

export const StyledTotal = styled(FlexBox)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    border-top: 1px solid rgba(255, 255, 255, 0.06);

    & span {
        padding: 16px;
    }
`;

export const StyledTotalNumber = styled(Typography)`
    font-size: 20px;
`;

export const StyledArtElement = styled(FlexBox)`
    gap: 8px;
`;

export const StyledLeftContainer = styled(FlexBox)`
    gap: 8px;
`;

export const StyledMainInfoContainer = styled(FlexBox)``;

export const StyledRightContainer = styled(FlexBox)`
    gap: 14px;

    & svg {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.red400};
    }
`;

export const StyledPreviewWrapper = styled.div<{ previewUri?: string }>`
    width: 88px;
    min-width: 88px;
    height: 100px;
    background-image: url(${({ previewUri }) => previewUri});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;

    & img {
        width: 100%;
    }
`;

export const StyledCartBadge = styled.div`
    color: ${({ theme }) => theme.colors.white};
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    white-space: nowrap;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    margin-top: 6px;
    position: relative;
    padding-left: 10px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 4px;
        height: 4px;
        background: white;
        border-radius: 50%;
    }
`;