import { IGetAllReferralsState } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.types';

export const INITIAL_FILTER_STATE: IGetAllReferralsState = {
    filter: {
        keywords: '',
        blockchainId: [],
        status: 0,
    },
    pager: {
        page: 0,
        limit: 10,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {},
    statistic: {},
    monthStatistic: {},
};