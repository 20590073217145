import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILaunchpadsFilter } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';
import { setManyLaunchpads } from 'src/features/common/explore/ducks/launchpads/launchpads.reducers';
import { LaunchpadsAdapter } from './launchpads.adapter';
import { INITIAL_FILTER_STATE } from './launchpads.constants';
import { fetchLaunchpadsThunk } from './launchpads.thunk';
import { ILaunchpadsState, LaunchpadsSort } from './launchpads.types';

const getInitialState = () => LaunchpadsAdapter.getInitialState<ILaunchpadsState>(INITIAL_FILTER_STATE);

export const launchpadsSlice = createSlice({
    name: 'explore-launchpads',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        set: setManyLaunchpads,
        setExploreTotal: (state, { payload }: PayloadAction<number>) => {
            state.exploreTotal = payload;
        },
        clearItems: (state) => {
            LaunchpadsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<ILaunchpadsFilter>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<ILaunchpadsFilter>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setSorting: (state, { payload }: PayloadAction<LaunchpadsSort>) => {
            state.sorting = payload;
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.launchpads.explore,
            }))
            .addCase(fetchLaunchpadsThunk.fulfilled, setManyLaunchpads);
    },
});

export const { actions } = launchpadsSlice;

export default launchpadsSlice.reducer;