import { SocialLinkI, SOCIALS } from 'src/constants/common.constants';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';

export const useSocialLinks = (): SocialLinkI[] => {
    const { isUniverse, socialNetworks } = useUniverse();

    if (!isUniverse) {
        return SOCIALS;
    }
    return socialNetworks ?? [];
};
