import { useCallback, useEffect, useRef, useState } from 'react';

export const useGradientAnimation = () => {
    const [animationFrame, setAnimationFrame] = useState<number | null>(null);
    const time = useRef(0);

    const color = useCallback(
        (canvas: HTMLCanvasElement, { x, y, r, g, b }: { x: number; y: number; r: number; g: number; b: number }) => {
            const context = canvas?.getContext('2d');
            if (context) {
                context.fillStyle = `rgb(${r}, ${g}, ${b})`;
                context.fillRect(x, y, 10, 10);
            }
        },
        []
    );

    const stopAnimation = useCallback(() => {
        if (animationFrame) {
            cancelAnimationFrame(animationFrame);
        }
    }, [animationFrame]);

    const R = useCallback(
        (x: number, y: number, t: number) => Math.floor(162 + 64 * Math.cos((x * x - y * y) / 300 + t)),
        []
    );

    const G = useCallback(
        (x: number, y: number, t: number) =>
            Math.floor(40 + 125 * Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 150)),
        []
    );

    const B = useCallback(
        (x: number, y: number, t: number) =>
            Math.floor(
                220 + 10 * Math.cos(5 * Math.sin(t / 9) + ((x - 100) * (x - 300) + (y - 100) * (y - 100)) / 1000)
            ),
        []
    );

    const startAnimation = useCallback(
        (canvas: HTMLCanvasElement, speedCoefficient: number) => {
            if (!canvas) {
                return;
            }
            const animate = () => {
                for (let x = 0; x <= 35; x++) {
                    for (let y = 0; y <= 35; y++) {
                        color(canvas, {
                            x,
                            y,
                            r: R(x, y, time.current),
                            g: G(x, y, time.current),
                            b: B(x, y, time.current),
                        });
                    }
                }
                time.current += speedCoefficient;
                const frame = window.requestAnimationFrame(animate);
                setAnimationFrame(frame);
            };
            stopAnimation();
            animate();
        },
        [stopAnimation, R, B, G, color]
    );

    useEffect(
        () => () => {
            stopAnimation();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        []
    );

    return { startAnimation, stopAnimation };
};