import { createSlice } from '@reduxjs/toolkit';
import { FetchCurrentBidsByLotsResponseI, LotI } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';
import { ICollectibleState } from 'src/features/common/collectible/ducks/collectible/collection.types';
import {
    nftFetchThunk,
    fetchAllLotsThunk,
    fetchActiveLotsThunk,
    contactSellerThunk,
    currentBidsFetchThunk,
    auctionWinnerFetchThunk,
    setSelectedLotThunk,
    fetchActiveOwnersThunk,
    fetchNftCollectionThunk,
    fetchNftHistoryThunk,
} from './collectible.thunk';

const INITIAL_STATE: ICollectibleState = {
    nft: null,
    created: false,
    error: '',
    currentBids: {} as FetchCurrentBidsByLotsResponseI['amounts'],
    auctionWinner: {},
    collection: null,
    activeLots: [] as LotI[],
    allLots: {
        lots: [] as LotI[],
    },
    history: {
        page: 0,
        totalCount: 0,
        items: [],
    },
    selectedLot: null,
    activeOwners: {
        page: 0,
        totalCount: 0,
        items: [],
    },
};

export const collectibleSlice = createSlice({
    name: 'collectible',
    initialState: INITIAL_STATE,
    reducers: {
        clearCollectible: () => INITIAL_STATE,
        errorClear: (state) => {
            state.error = '';
        },
        setCollectible: (state, action) => {
            state.nft = action.payload;
        },
        setSelectedLaunchPad: (state, action) => {
            state.selectedLaunchpadId = action.payload;
        },
        setSelectedCollection: (state, action) => {
            state.selectedCollectionId = action.payload;
        },
        resetSelectedLaunchPad: (state) => {
            state.selectedLaunchpadId = '';
        },
        resetSelectedLot: (state) => {
            state.selectedLot = null;
        },
        resetSelectedCollection: (state) => {
            state.selectedCollectionId = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.nfts.nft,
            }))
            .addCase(nftFetchThunk.fulfilled, (state, action) => {
                if (action.payload.nft) {
                    state.nft = action.payload.nft;
                }
            })
            .addCase(fetchNftCollectionThunk.fulfilled, (state, action) => {
                if (action.payload.collection) {
                    state.collection = action.payload.collection;
                }
            })
            .addCase(currentBidsFetchThunk.fulfilled, (state, action) => {
                state.currentBids = Object.keys(action.payload || {}).reduce((acum, lotId) => {
                    if (+action.payload[lotId]) {
                        acum[lotId] = action.payload[lotId];
                    }
                    return acum;
                }, {} as FetchCurrentBidsByLotsResponseI['amounts']);
            })
            .addCase(fetchActiveOwnersThunk.fulfilled, (state, action) => {
                state.activeOwners.page = +(action.payload.pager?.page ?? 0);
                state.activeOwners.totalCount = +(action.payload.pager?.totalCount ?? 0);

                if (state.activeOwners.page === 0) {
                    state.activeOwners.items = action.payload.owners ?? [];
                } else {
                    state.activeOwners.items = [...state.activeOwners.items, ...(action.payload.owners ?? [])];
                }
            })
            .addCase(fetchNftHistoryThunk.fulfilled, (state, action) => {
                state.history.page = +(action.payload.pager?.page ?? 0);
                state.history.totalCount = +(action.payload.pager?.totalCount ?? 0);

                if (state.history.page === 0) {
                    state.history.items = action.payload.transactions ?? [];
                } else {
                    state.history.items = [...state.history.items, ...(action.payload.transactions ?? [])];
                }
            })
            .addCase(auctionWinnerFetchThunk.fulfilled, (state, action) => {
                state.auctionWinner = action.payload.data;
            })
            .addCase(contactSellerThunk.fulfilled, (state) => {
                state.error = '';
            })
            .addCase(contactSellerThunk.rejected, (state, { payload }) => {
                state.error = payload as any;
            })
            .addCase(fetchAllLotsThunk.fulfilled, (state, { payload }) => {
                state.allLots.lots = payload || [];
            })
            .addCase(fetchActiveLotsThunk.fulfilled, (state, { payload }) => {
                state.activeLots = payload || [];
            })
            .addCase(setSelectedLotThunk, (state, { payload }) => {
                state.selectedLot = payload;
            });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = collectibleSlice;

export default collectibleSlice.reducer;
