// MODULES
import React, { forwardRef } from 'react';

// RESOURCES
import { StyledTypography } from 'src/ui/common/atoms/typography/typography.styles';
import { TypographyProps } from 'src/ui/common/atoms/typography/typography.types';

export const Typography: React.FC<TypographyProps> = forwardRef((
    {
        children, center, variant = 'text', tag = 'span', size = 'sm', semiBold, bold,
        medium, uppercase, capitalize, breakSpaces, color, altTitle, overflowLines, ...rest
    },
    ref,
): React.ReactElement => (
    <StyledTypography
        {...rest}
        ref={ref}
        center={center}
        as={tag}
        color={color}
        overflowLines={overflowLines}
        variant={variant}
        size={size}
        bold={bold}
        semiBold={semiBold}
        medium={medium}
        uppercase={uppercase}
        capitalize={capitalize}
        breakSpaces={breakSpaces}
        title={altTitle}
    >
        {children}
    </StyledTypography>
));

Typography.displayName = 'Typography';
