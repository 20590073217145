import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { RouteE } from 'src/constants/pages.constants';
import {
    DesktopLink,
    StyledDesktopLinks,
    StyledLinksVisible,
    StyledSocialLink,
} from 'src/features/common/app/components/header/header.styles';
import { useSocialLinks } from 'src/features/common/app/hooks/use-social-links';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useExploreTotal } from 'src/features/common/explore/hooks/use-explore-launchpads';
import { useIsSeller } from 'src/features/common/profile/hooks/use-is-seller';
import { useUniverseGetMineFetch } from 'src/features/common/universe/hooks/universe-mine.hooks';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { NavigationLinkI } from 'src/types';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';
import { SimpleDropDown } from 'src/ui/common/organisms/simple-dropdown';

interface ILocale {
    explore: string;
    launchpad: string;
    collections: string;
    creators: string;
    universes: string;
    community: string;
}

export const DesktopLinks = () => {
    const { pathname } = useRouter();
    const { isUniverse, isUniverseOwner } = useUniverse();
    const authorized = useAuthorized();
    const isSeller = useIsSeller();
    const initedRef = useRef(false);
    const total = useExploreTotal();
    const communityRef = useRef();
    const { t } = useTranslation(['common']);
    const socialLinks = useSocialLinks();
    const fetchMineUniverse = useUniverseGetMineFetch();
    const locale: ILocale = useMemo(
        () => ({
            ...t('header.menu', { returnObjects: true }),
        }),
        [t]
    );

    const navigationLinks = useMemo<NavigationLinkI[]>(() => {
        const res = [
            { title: locale.explore, href: RouteE.EXPLORE, rel: 'canonical' },
            { title: locale.collections, href: RouteE.COLLECTIONS_LIST, rel: 'canonical' },
        ];
        if (total || isSeller || isUniverseOwner || !isUniverse) {
            res.push({ title: locale.launchpad, href: RouteE.LAUNCHPADS, rel: 'canonical' });
        }
        if (!isUniverse) {
            res.push({ title: locale.universes, href: RouteE.UNIVERSES, rel: 'canonical' });
        }
        return res;
    }, [isUniverse, locale, total, isSeller, isUniverseOwner]);

    const initData = useCallback(() => {
        if (initedRef.current) {
            return;
        }
        initedRef.current = true;
    }, []);

    useEffect(() => {
        if (authorized) {
            fetchMineUniverse();
        }
    }, [authorized, fetchMineUniverse]);

    return (
        <>
            <StyledLinksVisible>
                <StyledDesktopLinks alignItems="center" onMouseEnter={initData}>
                    {navigationLinks.map((link) => (
                        <Link key={link.href} href={link.href} rel={link.rel} prefetch={false} passHref>
                            <DesktopLink rel="canonical" active={link.href === pathname}>
                                <span>{link.title}</span>
                            </DesktopLink>
                        </Link>
                    ))}
                    {!!socialLinks.length && (
                        <DesktopLink as="div" ref={communityRef as any}>
                            <span>{locale.community}</span>
                        </DesktopLink>
                    )}
                    <Link href="/tokenholder-relations" prefetch={false} passHref>
                        <DesktopLink rel="canonical" active={pathname === '/tokenholder-relations'}>
                            <span>Tokenholder Relations</span>
                        </DesktopLink>
                    </Link>
                </StyledDesktopLinks>
            </StyledLinksVisible>
            {!!socialLinks.length && (
                // @ts-ignore
                <SimpleDropDown nodeRef={communityRef}>
                    {socialLinks.map((link) => (
                        <StyledSocialLink href={link.identifier} key={link.identifier} target="_blank" rel="noreferrer">
                            <FlexBox alignItems="center" justifyContent="space-between">
                                <Typography size="sm">{link.type}</Typography>
                                <SvgIcon variant="regular" icon="chevron45D" width={16} height={16} />
                            </FlexBox>
                        </StyledSocialLink>
                    ))}
                </SimpleDropDown>
            )}
        </>
    );
};