import styled, { DefaultTheme } from 'styled-components';
import { media } from 'src/ui/common/mixins/media';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';

const calcTopOffset = (header: number, scrollTop: number) => {
  if (scrollTop > header) {
    return 0;
  }
  return header - scrollTop;
};

interface SideBarArgs {
  opened: boolean;
  topOffset: number;
  theme: DefaultTheme;
}

export const StyledOverlay = styled.div<SideBarArgs>`
  background-color: ${({ theme }) => theme.colors.overlay};
  position: fixed;
  left: 0;
  right: 0;
  top: ${({ theme, topOffset }) => calcTopOffset(theme.constants.headerMbHeight, topOffset)}px;
  bottom: 0;
  z-index: 8;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: ${({ opened }) => opened ? '1' : '0'};

  ${media.md`
    top: ${({ theme, topOffset }: SideBarArgs) => calcTopOffset(theme.constants.headerDskHeight, topOffset)}px;
  `}
`;

export const StyledWrapper = styled.div<SideBarArgs>`
  background-color: ${({ theme }) => theme.colors.gs500};
  position: fixed;
  right: 0;
  top: ${({ theme, topOffset }) => calcTopOffset(theme.constants.headerMbHeight, topOffset)}px;
  bottom: 0;
  width: 100vw;
  z-index: 9;
  transform: ${({ opened }) => opened ? 'translateY(0)' : 'translateY(100%)'};
  transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  
  ${media.sm`
    width: 460px;
    transform: ${({ opened } : SideBarArgs) => opened ? 'translateX(0)' : 'translateX(100%)'};
  `}  
  ${media.md`
    top: ${({ theme, topOffset }: SideBarArgs) => calcTopOffset(theme.constants.headerDskHeight, topOffset)}px;
  `}
`;

export const StyledTitle = styled(FlexBox)`
  height: 74px;
  padding: 20px 25px;
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gs400};
`;

export const StyledClose = styled(SvgIcon)`
  position: absolute;
  cursor: pointer;
  top: 25px;
  right: 20px;
`;

export const StyledContentWrapper = styled.div`
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 74px);
`;
