import { createAsyncThunk } from '@reduxjs/toolkit';
import { ArtAPI } from '@tacans/shared-marketplace-layer';
import { RootState } from 'src/store/store';
import { useCreateMyArtsRequestPayload } from '../hooks/use-create-my-arts-request-payload';
import { selectPager } from './my-arts.selectors';

export const fetchMyArtsThunk = createAsyncThunk('my-arts/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const pager = selectPager(rootState);
    const payload = useCreateMyArtsRequestPayload({
        pager,
    });
    // @ts-ignore
    const { data } = await ArtAPI.getMyArts(payload);

    return data;
});