import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { ICollectionStatistics } from '@tacans/shared-marketplace-layer';

import { fetchCollectionStatisticsThunk } from './statistics.thunk';

const getInitialState = () => ({
    byId: {} as Record<string, ICollectionStatistics>
});

export const statisticsSlice = createSlice({
    name: 'collections/statistics',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.collections.statistics,
            }))
            .addCase(fetchCollectionStatisticsThunk.fulfilled, (state, action) => {
                if (action.payload.records) {
                    state.byId = action.payload.records.reduce((acum, item) => {
                        acum[item.id] = item.statistics;
                        return acum;
                    }, {} as Record<string, ICollectionStatistics>);
                }
            });
    },
});

export const { actions } = statisticsSlice;

export default statisticsSlice.reducer;
