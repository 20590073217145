import { createContext, ReactNode } from 'react';

export interface ISidebarContext {
    content: ReactNode | null;
    setContent: (node: ReactNode | null) => void;
    title: ReactNode | string | null;
    setTitle: (node: ReactNode | string | null) => void;
}

export const SidebarContext = createContext<ISidebarContext>({
    content: null,
    setContent: () => {},
    title: null,
    setTitle: () => {}
});
