import { createAsyncThunk } from '@reduxjs/toolkit';
import { UniverseAPI } from '@tacans/shared-marketplace-layer';
import { useCreateUniversesRequestPayload } from 'src/features/common/explore/universes-api.utils';
import { RootState } from 'src/store/store';
import { selectSorting, selectFilter, selectPager } from './universes.selectors';

export const fetchUniversesThunk = createAsyncThunk('explore/universes/fetch', async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const sort = selectSorting(rootState);
    const filter = selectFilter(rootState);
    const pager = selectPager(rootState);
    const payload = useCreateUniversesRequestPayload({
        sort,
        filter,
        pager,
    });
    const { data } = await UniverseAPI.fetchByFilter(payload);

    return data;
});
