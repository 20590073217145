import {
    AddressT,
    IBlockchain,
    IGetWallet,
    ResponseMetaStatusE,
    WalletProviderE,
} from '@tacans/shared-marketplace-layer';
import { useCallback, useMemo } from 'react';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import { NotificationTypeEnum } from 'src/services/notifications/types';
import { useNotifications } from 'src/services/notifications/use-notifications';
import {
    selectBlockChains,
    selectCoins,
    selectWallets,
    selectWalletsHasError,
    selectWalletsHasNew,
    selectWalletsLoaded,
} from '../ducks/wallet/wallet.selectors';
import { actions as WalletActions } from '../ducks/wallet/wallet.slice';
import {
    addWalletThunk,
    fetchBlockChainsThunk,
    fetchCoinsThunk,
    fetchWalletsThunk,
} from '../ducks/wallet/wallet.thunk';
import { ConnectWalletModal } from '../modals/connect-wallet-modal';

export const useWalletClear = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(WalletActions.clearWallets());
    }, [dispatch]);
};

export const useErrorWallet = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(WalletActions.clearError());
    }, [dispatch]);
};

export const useWalletAdd = () => {
    const dispatch = useAppDispatch();
    const clearError = useErrorWallet();
    const fetchWallets = useWalletsFetch();
    const { show } = useNotifications();
    const { user } = useProfile() || {};

    return useCallback(
        async (address: AddressT) => {
            clearError();
            const res = await dispatch(addWalletThunk(address));
            const responseMeta = res.payload.response.data?.responseMeta;
            const error = responseMeta?.error?.messages?.[0] || responseMeta?.status;
            if (responseMeta?.status === ResponseMetaStatusE.OK) {
                show({
                    text: 'You have successfully connected your wallet',
                    type: NotificationTypeEnum.success,
                });
                user && fetchWallets(user.login);
                return;
            }
            show({
                text: error,
                type: NotificationTypeEnum.error,
            });
        },
        [fetchWallets, user, clearError, dispatch, show]
    );
};

export const useWalletsFetch = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (login: string) => {
            // @ts-ignore
            dispatch(fetchWalletsThunk(login));
        },
        [dispatch]
    );
};

export const useFetchBlockChains = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(fetchBlockChainsThunk());
    }, [dispatch]);
};

export const useFetchCoins = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(fetchCoinsThunk());
    }, [dispatch]);
};

export const useWallets = () => useAppSelector(selectWallets);

export const useGetDefaultWallet = () => {
    const wallets = useWalletsByBlockChainIds();
    const blockChains = useBlockChains();

    return useCallback(
        (walletProvider: WalletProviderE) => {
            const blockchain = blockChains.find((b) => b.name === walletProvider);
            if (!blockchain || !wallets[blockchain?.id] || !wallets[blockchain?.id].length) {
                return null;
            }
            return wallets[blockchain?.id!][0];
        },
        [blockChains, wallets]
    );
};

export const useWalletHasNewClear = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        // @ts-ignore
        dispatch(WalletActions.hasNewClear());
    }, [dispatch]);
};

export const useConnectWallet = () =>
    useCallback((walletProvider: WalletProviderE) => {
        ConnectWalletModal.open({ walletProvider });
    }, []);

export const useWalletsHasNew = () => useAppSelector(selectWalletsHasNew);
export const useWalletsHasError = () => useAppSelector(selectWalletsHasError);
export const useWalletsLoaded = () => useAppSelector(selectWalletsLoaded);
export const useBlockChains = () => useAppSelector(selectBlockChains);
export const useCoins = () => useAppSelector(selectCoins);

export const useBlockChainsById = () => {
    const blockChains = useBlockChains();

    return useMemo<Record<IBlockchain['id'], IBlockchain>>(
        () =>
            blockChains.reduce((acum, blockChain) => {
                acum[blockChain.id] = blockChain;
                return acum;
            }, {} as Record<IBlockchain['id'], IBlockchain>),
        [blockChains]
    );
};

export const useWalletsByBlockChainIds = () => {
    const wallets = useWallets();
    const blockChains = useBlockChains();

    return useMemo<Record<IBlockchain['id'], IGetWallet[]>>(
        () =>
            blockChains.reduce((acum, blockChain) => {
                acum[blockChain.id] = wallets.filter((w) => w.blockchainId === blockChain.id);
                return acum;
            }, {} as Record<IBlockchain['id'], IGetWallet[]>),
        [blockChains, wallets]
    );
};
