// MODULES
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI, UserLoginRequest } from '@tacans/shared-marketplace-layer';

// RESOURCES
import { actions as WalletActions } from '../../../wallet/ducks/wallet/wallet.slice';

export const loginUserThunk = createAsyncThunk(
    'users/login',
    async (data: UserLoginRequest, { rejectWithValue }) => {
        try {
            const response = await UserAPI.login(data);

            if (response.data?.responseMeta?.status === 'SystemError') {
                throw new Error('Wrong login and/or password, please try another');
            }
            if (response.data?.responseMeta?.status !== 'OK') {
                throw new Error(response.data.responseMeta.error?.messages?.[0]!);
            }

            return { response };
        } catch (err) {
            const error = err; // cast the error for access

            // @ts-ignore
            if (!error) {
                throw err;
            }

            // We got validation errors, let's return those so we can reference in our component and set form errors
            // @ts-ignore
            return rejectWithValue({ error: error.message });
        }
    }
);

export const logoutUserThunk = createAsyncThunk(
    'users/logout',
    async (_, { dispatch }) => {
        const response = await UserAPI.logout();

        dispatch(WalletActions.clearWallets());

        return { response };
    }
);

export const deleteUserThunk = createAsyncThunk(
    'users/delete',
    async (password: string, { rejectWithValue }) => {
        const response = await UserAPI.deleteAccount(password);
        const { error } = response.data.responseMeta;

        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response?.data;
    }
);
