/* eslint-disable jsx-a11y/anchor-is-valid,react/no-danger */

/**
 * Libs
 */
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { RouteE } from 'src/constants/pages.constants';

/**
 * Constants
 */
import { s7CommunityLinks, SOCIALS } from 'src/features/common/app/components/footer/footer.constants';

/**
 * Styles
 */
import { IFooterLinks } from 'src/features/common/app/components/footer/footer.types';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Grid } from 'src/ui/common/atoms/grid';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { SvgIconBold } from 'src/ui/common/atoms/svg-icon/svg-icon.types';
import { Typography } from 'src/ui/common/atoms/typography';

/**
 * Components
 */
import { PageLayout } from 'src/ui/common/layouts/page-layout';
import { Visible } from 'src/ui/common/layouts/visible';
import { Accordion } from 'src/ui/common/molecules/accordion';
import { StyledAccordionContainer, StyledFooter, StyledFooterBottom, StyledFooterFollow, StyledFooterLogo, StyledLinksBlock } from './footer.styles';


export const Footer = () => {
    const { t } = useTranslation('components');
    const { isUniverse, socialNetworks, projectName, settings } = useUniverse();
    const { isSportsMuseumTemplate } = useGetUniverseTemplate();

    const links = useMemo<IFooterLinks[]>(
        () => [
            {
                label: 'marketplace',
                links: [
                    {
                        label: 'explore',
                        url: RouteE.EXPLORE,
                    },
                    {
                        label: 'collections',
                        url: RouteE.COLLECTIONS_LIST,
                    },
                ],
            },
            {
                label: 'learn',
                links: [
                    isUniverse
                        ? null
                        : {
                              label: 'about-us',
                              url: 'https://home.spaceseven.com/about-us',
                              foreign: true,
                          },

                    {
                        label: 'faq',
                        url: RouteE.FAQ,
                    },
                    isUniverse
                        ? null
                        : {
                              label: 'contactUs',
                              url: 'mailto:support@spaceseven.com',
                          },
                ],
            },
            {
                label: 'community',
                links: isUniverse
                    ? (socialNetworks ?? []).map((social) => ({
                          foreign: true,
                          label: social.type.toLowerCase(),
                          url: social.identifier,
                      }))
                    : s7CommunityLinks,
            },
            {
                label: 'follow',
                links: SOCIALS,
            },
        ],
        [isUniverse, socialNetworks]
    );

    return (
        <StyledFooter>
            <PageLayout fullWidth={false}>
                <Grid xs={1} sm={3} md={6}>
                    <StyledFooterLogo flexDirection="column">
                        <Typography variant="title" tag="h3" semiBold uppercase>
                            {projectName}
                        </Typography>
                        <Spacing mb="48px" mbSm="50px" mbMd="0">
                            <Typography variant="text" tag="div">
                                {settings?.footerDescription || t('footer.aboutText')}
                            </Typography>
                        </Spacing>
                    </StyledFooterLogo>
                    <StyledAccordionContainer view="mobile">
                        <Accordion>
                            {links.map(({ label: section, links }) =>
                                section === 'follow' && isUniverse ? null : (
                                    <React.Fragment key={section}>
                                        <Accordion.ItemHeader itemId={section}>
                                            <Typography variant="title">{t(`footer.top.${section}`)}</Typography>
                                        </Accordion.ItemHeader>
                                        <Accordion.ItemContent itemId={section}>
                                            <FlexBox flexDirection="column">
                                                {/* @ts-ignore */}
                                                {links.filter(Boolean).map(({ url, label, foreign }) =>
                                                    foreign ? (
                                                        <Typography key={url}>
                                                            <a href={url} target="_blank" rel="noreferrer">
                                                                {t(`footer.${section}.${label}`)}
                                                            </a>
                                                        </Typography>
                                                    ) : (
                                                        <Typography key={url}>
                                                            <Link href={url} prefetch={false}>
                                                                <a>{t(`footer.${section}.${label}`)}</a>
                                                            </Link>
                                                        </Typography>
                                                    )
                                                )}
                                            </FlexBox>
                                        </Accordion.ItemContent>
                                    </React.Fragment>
                                )
                            )}
                        </Accordion>
                    </StyledAccordionContainer>
                    {links
                        .slice(0, 3)
                        .filter(Boolean)
                        .map(({ label: section, links: localLinks }) => (
                            <Visible key={section} view={['laptop', 'desktop']}>
                                <StyledLinksBlock flexDirection="column">
                                    {!!localLinks.length && (
                                        <Typography tag="h3" variant="text">
                                            {t(`footer.top.${section}`)}
                                        </Typography>
                                    )}
                                    <FlexBox flexDirection="column">
                                        {/* @ts-ignore */}
                                        {localLinks.filter(Boolean).map(({ url, label, foreign }) =>
                                            foreign ? (
                                                <a key={url} href={url} target="_blank" rel="noreferrer">
                                                    {t(`footer.${section}.${label}`)}
                                                </a>
                                            ) : (
                                                <Link key={url} href={url} prefetch={false}>
                                                    <a>{t(`footer.${section}.${label}`)}</a>
                                                </Link>
                                            )
                                        )}
                                    </FlexBox>
                                </StyledLinksBlock>
                            </Visible>
                        ))}
                    {!isUniverse && (
                        <StyledFooterFollow view={['laptop', 'desktop']}>
                            <Spacing ptSm="50px" ptMd="60px">
                                <FlexBox flexDirection="column">
                                    <Spacing mb="20px">
                                        <Typography color="white">{t('footer.top.follow')}</Typography>
                                    </Spacing>
                                    <FlexBox>
                                        {SOCIALS.map((social) => (
                                            <a href={social.url} key={social.label} target="_blank" rel="noreferrer">
                                                <SvgIcon variant="bold" icon={social.icon as SvgIconBold['icon']} />
                                            </a>
                                        ))}
                                    </FlexBox>
                                </FlexBox>
                            </Spacing>
                        </StyledFooterFollow>
                    )}
                </Grid>
            </PageLayout>
            <div>
                <PageLayout fullWidth={false}>
                    <StyledFooterBottom xs={1} sm={6}>
                        <Typography size="xs">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t(isUniverse ? 'footer.universeCopyright' : 'footer.copyright', {
                                        name: projectName,
                                    }),
                                }}
                            />
                        </Typography>
                        <FlexBox flexDirection="row">
                            <Link href={RouteE.TERMS} prefetch={false}>
                                <a>{t('footer.bottom.terms')}</a>
                            </Link>
                            <Link href={RouteE.PRIVACY} prefetch={false}>
                                <a>{t('footer.bottom.privacy')}</a>
                            </Link>
                            {isSportsMuseumTemplate && (
                                <>
                                    <Link href={RouteE.IMPRINT} prefetch={false}>
                                        <a>Imprint</a>
                                    </Link>
                                    <Link href="/tokenholder-relations" prefetch={false}>
                                        <a>Tokenholder Relations</a>
                                    </Link>
                                </>
                            )}
                        </FlexBox>
                    </StyledFooterBottom>
                </PageLayout>
            </div>
        </StyledFooter>
    );
};