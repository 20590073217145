import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ThemeTypes } from 'src/features/common/app/components/theme-provider/theme.types';
import { IRegistryNotification, NotificationTypeEnum } from 'src/services/notifications/types';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';
import { useTheme } from 'styled-components';
import { StyledCloseIcon, StyledNotification, StyledProgress, StyledText, StyledTypeIcon } from './styles';

interface IProps {
    notification: IRegistryNotification;
}

const ICONS: Record<NotificationTypeEnum, string> = {
    success: 'check',
    info: 'check',
    warning: 'check',
    error: 'cross',
};

const DEFAULT_TIMEOUT = 3000;

const NotificationComponent: React.FC<IProps> = ({ notification }) => {
    const timeout = notification.timeout || DEFAULT_TIMEOUT;

    const [timeLeft, setTimeLeft] = useState(timeout);
    const timerHideRef = useRef<any>(0);
    const progressRef = useRef<any>(0);
    const timerRemoveRef = useRef<any>(0);
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const hideNotification = useCallback(() => {
        setIsMounted(false);
        clearTimeout(timerRemoveRef.current);
        timerRemoveRef.current = setTimeout(() => {
            notification.remove();
        }, 200);
    }, [notification]);

    const stopProgressBar = useCallback(() => {
        clearTimeout(timerRemoveRef.current);
        clearTimeout(timerHideRef.current);
        clearInterval(progressRef.current);
    }, []);

    const startProgressBar = useCallback(() => {
        if (notification.timeout !== 0 && notification.closable) {
            timerHideRef.current = setTimeout(hideNotification, timeLeft);

            const intValue = 10;
            progressRef.current = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev - intValue < 0) {
                        clearInterval(progressRef.current);
                    }
                    return prev - intValue;
                });
            }, intValue);
        }
    }, [hideNotification, timeLeft, notification.closable, notification.timeout]);

    useEffect(() => {
        setTimeout(() => setIsMounted(true), 0);
        startProgressBar();

        return () => {
            clearTimeout(timerHideRef.current);
            clearInterval(progressRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { component: Component } = notification;

    const type = notification.type || NotificationTypeEnum.info;
    const theme = useTheme();
    const isLightTheme = theme.name === ThemeTypes.LIGHT;

    return (
        <StyledNotification
            onMouseEnter={stopProgressBar}
            onMouseLeave={startProgressBar}
            mounted={isMounted}
            type={type}
            onClick={notification.closable ? hideNotification : undefined}
            data-test-id="notifications-component"
            data-test-type={type}
        >
            <StyledProgress loaded={(timeLeft / timeout) * 100} />
            {Component ? (
                <Component notification={notification} />
            ) : (
                <FlexBox>
                    <StyledTypeIcon type={type}>
                        <SvgIcon variant="regular" icon={ICONS[type] as any} />
                    </StyledTypeIcon>
                    <FlexBox flexDirection="column">
                        <Typography color={isLightTheme ? 'gs100' : 'white'} semiBold>
                            {notification.text}
                        </Typography>
                        {notification.description && (
                            <StyledText color="gs200" size="xs">
                                {notification.description}
                            </StyledText>
                        )}
                    </FlexBox>
                    {notification.closable && <StyledCloseIcon width={14} icon="close" variant="regular" />}
                </FlexBox>
            )}
        </StyledNotification>
    );
};

export default NotificationComponent;