import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
    IUsersListFilter,
} from '@tacans/shared-marketplace-layer';

import { UsersSort, IUsersState } from './users.types';
import { UsersAdapter } from './users.adapter';
import { fetchUsersThunk } from './users.thunk';
import { INITIAL_FILTER_STATE } from './users.constants';

const getInitialState = () => UsersAdapter.getInitialState<IUsersState>(INITIAL_FILTER_STATE);

export const usersSlice = createSlice({
    name: 'users',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            UsersAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<IUsersListFilter>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<IUsersListFilter>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setSorting: (state, { payload }: PayloadAction<UsersSort>) => {
            state.sorting = payload;
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersThunk.fulfilled, (state, action) => {
                const { pager, list = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([
                    ...state.pages,
                    ...state.pages.concat(page)
                ])];

                if (list.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                list.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        newByPage[page].push(item.id);
                    }
                });


                UsersAdapter.addMany(state, list || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.users.list,
            }));
    },
});

export const { actions } = usersSlice;

export default usersSlice.reducer;
