import { createSlice } from '@reduxjs/toolkit';
import { fetchCartThunk } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.thunk';
import { CartItem, CartState } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.types';

const CART_INITIAL_STATE: CartState = {
    isCartOpen: false,
    cartItems: [],
};

const addCartItem = (cartItems: CartItem[], productToAdd: CartItem) => {
    const existingCartItem = cartItems.find((cartItem) => cartItem.digitalArtLotId === productToAdd.digitalArtLotId);

    if (existingCartItem) {
        return cartItems.map((cartItem) =>
            cartItem.digitalArtLotId === productToAdd.digitalArtLotId
                ? {
                      ...cartItem,
                      digitalArtLotId: cartItem.digitalArtLotId,
                      art: cartItem.art,
                      quantity: Number(cartItem.quantity) + Number(productToAdd.quantity),
                  }
                : cartItem
        );
    }

    return [...cartItems, { ...productToAdd }];
};

const removeCartItem = (cartItems: CartItem[], cartItemToRemove: CartItem) => {
    // find the cart item to remove
    const existingCartItem = cartItems.find(
        (cartItem) => cartItem.digitalArtLotId === cartItemToRemove.digitalArtLotId
    );

    // check if quantity is equal to 1, if it is remove that item from the cart
    if (existingCartItem) {
        return cartItems.filter((cartItem) => cartItem.digitalArtLotId !== cartItemToRemove.digitalArtLotId);
    }
    return cartItems;
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState: CART_INITIAL_STATE,
    reducers: {
        setIsCartOpen(state, action) {
            state.isCartOpen = action.payload;
        },
        addItemToCart(state, action) {
            state.cartItems = addCartItem(state.cartItems, action.payload);
        },
        removeItemFromCart(state, action) {
            state.cartItems = removeCartItem(state.cartItems, action.payload);
        },
        setInitialCartState(state) {
            state.cartItems = CART_INITIAL_STATE.cartItems;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCartThunk.fulfilled, (state, action) => {
            state.cartItems = action.payload;
        });
    },
});

export const { actions } = cartSlice;
export default cartSlice.reducer;