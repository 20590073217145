import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ExploreArtsAdapter } from './explore-arts.adapter';
import { INITIAL_FILTER_STATE } from './explore-arts.constants';
import { fetchArtsThunk } from './explore-arts.thunk';
import { ExploreSort, IExploreArtsState, IExploreFilters } from './explore-arts.types';

const getInitialState = () => ExploreArtsAdapter.getInitialState<IExploreArtsState>(INITIAL_FILTER_STATE);

export const exploreArtsSlice = createSlice({
    name: 'explore-arts',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            ExploreArtsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<IExploreFilters>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<IExploreFilters>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setSorting: (state, { payload }: PayloadAction<ExploreSort>) => {
            state.sorting = payload;
        },
        clearSorting: (state) => {
            state.sorting = INITIAL_FILTER_STATE.sorting;
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchArtsThunk.fulfilled, (state, action) => {
                const { pager, list = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (list.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                list.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        newByPage[page].push(item.id);
                    }
                });

                ExploreArtsAdapter.addMany(state, list || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.exploreArts,
            }));
    },
});

export const { actions } = exploreArtsSlice;

export default exploreArtsSlice.reducer;