import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { ExploreArtsAdapter } from './explore-arts.adapter';

export const exploreArtsSelect = (state: RootState) => state.features.exploreArts;

export const exploreArtsSelectors = ExploreArtsAdapter.getSelectors();

export const selectArts = createSelector(
    exploreArtsSelect,
    (state) => exploreArtsSelectors.selectAll(state)
);

export const selectArtsEntities = createSelector(
    exploreArtsSelect,
    (state) => exploreArtsSelectors.selectEntities(state)
);

export const selectFilter = createSelector(
    exploreArtsSelect,
    (state) => state.filter
);

export const selectPager = createSelector(
    exploreArtsSelect,
    (state) => state.pager
);

export const selectSorting = createSelector(
    exploreArtsSelect,
    (state) => state.sorting
);

export const selectPages = createSelector(
    exploreArtsSelect,
    (state) => state.pages
);

export const selectByPage = createSelector(
    exploreArtsSelect,
    (state) => state.byPage
);

export const selectTotal = createSelector(
    exploreArtsSelect,
    (state) => state.total
);

export const selectVisibleTotal = createSelector(
    exploreArtsSelect,
    (state) => state.visibleTotal
);

export const selectLoading = createSelector(
    exploreArtsSelect,
    (state) => state.loading
);