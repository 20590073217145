import { IExploreLaunchpadNftsState } from './explore-launchpad-nfts.types';

export const INITIAL_FILTER_STATE: IExploreLaunchpadNftsState = {
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {}
};
