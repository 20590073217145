import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { CalendarAdapter } from './calendar.adapter';
import { EventsSort, IEventsFilters, IExploreEventsState } from './calendar.types';
import { fetchEventsThunk } from './calendar.thunk';

export const INITIAL_FILTER_STATE: IExploreEventsState = {
    filter: {
        names: [],
        locations: [],
        dateFrom: '',
        dateTo: '',
    },
    sorting: EventsSort.DATE,
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    visibleTotal: 0,
    loading: false,
    pages: [],
    byPage: {},
};

const getInitialState = () => CalendarAdapter.getInitialState<IExploreEventsState>(INITIAL_FILTER_STATE);

export const EventsSlice = createSlice({
    name: 'calendar-events',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            CalendarAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<IEventsFilters>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<IEventsFilters>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setSorting: (state, { payload }: PayloadAction<EventsSort>) => {
            state.sorting = payload;
        },
        clearSorting: (state) => {
            state.sorting = INITIAL_FILTER_STATE.sorting;
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventsThunk.fulfilled, (state, action) => {
                const { pager, list = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (list.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }
                // to do (no id for now)
                // list.forEach((item) => {
                //     if (!(item.id in state.entities)) {
                //         newByPage[page].push(item.id);
                //     }
                // });

                CalendarAdapter.addMany(state, list || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.exploreArts,
            }));
    },

});


export const { actions } = EventsSlice;

export default EventsSlice.reducer;