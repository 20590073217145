import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrencyAPI } from '@tacans/shared-marketplace-layer';

export const fetchCurrencyThunk = createAsyncThunk(
    'currency/fetch',
    async (_, { rejectWithValue }) => {
        const response = await CurrencyAPI.fetchExchangeRates();
        if (response?.data?.responseMeta?.error?.messages[0]) {
            return rejectWithValue(response);
        }
        return response.data.items;
    }
);
