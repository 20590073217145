import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteE } from 'src/constants/pages.constants';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { UserProfileDesktop } from 'src/features/white-labels/techcontemporary/app/components/header/user-profile-desktop';
import { UserProfileMobile } from 'src/features/white-labels/techcontemporary/app/components/header/user-profile-mobile';
import { selectCartCount } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.selectors';
import { useFetchCartThunk } from 'src/features/white-labels/techcontemporary/cart/hooks/use-cart';
import { useTechContemporary } from 'src/hooks/use-tech-contemporary';
import { Visible } from 'src/ui/common/layouts/visible';
import { UserMenuWrapper } from './header.styles';

export const UserProfile = () => {
    const authorized = useAuthorized();
    const [init, setInit] = useState(false);
    const { isOwnerTechContemporary } = useTechContemporary();
    const cartCount = useSelector(selectCartCount);
    const router = useRouter();

    const fetchCart = useFetchCartThunk();

    useEffect(() => {
        setInit(true);
    }, []);

    useEffect(() => {
        if (authorized) {
            fetchCart();
        }
    }, [fetchCart, authorized]);

    const goToCreateArtHandler = () => router.push(RouteE.ART_CREATE);

    return (
        <UserMenuWrapper alignItems="center">
            <Visible view="desktop" display="flex">
                <UserProfileDesktop
                    init={init}
                    authorized={!!authorized}
                    isOwnerTechContemporary={isOwnerTechContemporary}
                    goToCreateArtHandler={goToCreateArtHandler}
                    cartCount={cartCount}
                />
            </Visible>
            <Visible view={['laptop', 'mobile']} display="flex">
                <UserProfileMobile
                    init={init}
                    authorized={!!authorized}
                    isOwnerTechContemporary={isOwnerTechContemporary}
                    goToCreateArtHandler={goToCreateArtHandler}
                    cartCount={cartCount}
                />
            </Visible>
        </UserMenuWrapper>
    );
};