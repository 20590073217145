import { IResponseMetaStatus, IUpdateUser, UserCurrency } from '@tacans/shared-marketplace-layer';
import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import { selectAddresses } from '../ducks/addresses/addresses.selector';
import {
    selectEmailSent,
    selectPasswordChanged,
    selectProfile,
    selectProfileAvatar,
    selectProfileAvatarLoading,
    selectProfileBanner,
    selectProfileBannerLoading,
    selectProfileError,
    selectProfileJustUpdated,
    selectProfileLoaded,
    selectUploadedProfileAvatar,
    selectUploadedProfileBanner,
} from '../ducks/profile/profile.selectors';
import { actions as ProfileActions } from '../ducks/profile/profile.slice';
import {
    changePasswordThunk,
    profileAvatarChangeThunk,
    profileAvatarDeleteThunk,
    profileBannerChangeThunk,
    profileBannerDeleteThunk,
    profileEditThunk,
    profileFetchThunk,
    updateEmailThunk,
} from '../ducks/profile/profile.thunk';

export const useProfileEdit = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        async (data: IUpdateUser) => {
            const { payload } = await dispatch(profileEditThunk(data));
            return payload as { response: AxiosResponse<IResponseMetaStatus, any> };
        },
        [dispatch]
    );
};

export const useProfileFetch = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(profileFetchThunk());
    }, [dispatch]);
};

export const useProfileClear = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(ProfileActions.clearProfile());
    }, [dispatch]);
};

export const useProfileAvatarChange = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        async (file: File | Blob) => {
            await dispatch(profileAvatarChangeThunk(file));
        },
        [dispatch]
    );
};

export const useProfileAvatarDelete = () => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        await dispatch(profileAvatarDeleteThunk());
    }, [dispatch]);
};

export const useProfileBannerChange = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        async (file: File | Blob) => {
            await dispatch(profileBannerChangeThunk(file));
        },
        [dispatch]
    );
};

export const useProfileBannerDelete = () => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        await dispatch(profileBannerDeleteThunk());
    }, [dispatch]);
};

export const useProfileJustUpdatedClear = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(ProfileActions.justUpdatedClear());
    }, [dispatch]);
};

export const useProfileErrorClear = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(ProfileActions.errorClear());
    }, [dispatch]);
};

export const useChangeEmail = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (email: string) => {
            dispatch(ProfileActions.changeEmail(email));
        },
        [dispatch]
    );
};

export const useChangePassword = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        async (password, new_password) => {
            const data = await dispatch(changePasswordThunk({ password, new_password }));
            return data;
        },
        [dispatch]
    );
};

export const useUpdateEmail = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        async (email, password) => {
            const data = await dispatch(updateEmailThunk({ email, password }));
            return data;
        },
        [dispatch]
    );
};

export const useIsNotSellerOnUniverse = () => {
    const { isUniverse } = useUniverse();
    const profile = useProfile();
    return isUniverse && !profile?.personalInfo.isSeller;
};

export const useFiatCurrency = () => {
    const profile = useProfile();
    return profile?.user?.currency ?? UserCurrency.USD;
};

export const useProfile = () => useAppSelector(selectProfile);

export const useAddresses = () => useAppSelector(selectAddresses);
export const useUploadedProfileAvatar = () => useAppSelector(selectUploadedProfileAvatar);
export const useProfileAvatar = () => useAppSelector(selectProfileAvatar);
export const useProfileBanner = () => useAppSelector(selectProfileBanner);

export const useProfileAvatarLoading = () => useAppSelector(selectProfileAvatarLoading);
export const useProfileBannerLoading = () => useAppSelector(selectProfileBannerLoading);
export const useUploadedProfileBanner = () => useAppSelector(selectUploadedProfileBanner);
export const useProfileJustUpdated = () => useAppSelector(selectProfileJustUpdated);
export const useProfileError = () => useAppSelector(selectProfileError);
export const useEmailSent = () => useAppSelector(selectEmailSent);
export const usePasswordChanged = () => useAppSelector(selectPasswordChanged);
export const useProfileLoaded = () => useAppSelector(selectProfileLoaded);