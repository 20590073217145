import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReferralsFilters } from '@tacans/shared-marketplace-layer/dist/api/referral/referral.types';
import { HYDRATE } from 'next-redux-wrapper';
import { GetAllReferralsAdapter } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.adapter';
import { INITIAL_FILTER_STATE } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.constants';
import { fetchAllReferralsThunk } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.thunk';
import { IGetAllReferralsState } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.types';

const getInitialState = () => GetAllReferralsAdapter.getInitialState<IGetAllReferralsState>(INITIAL_FILTER_STATE);

export const referralsSlice = createSlice({
    name: 'get-all-referrals',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            GetAllReferralsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<IReferralsFilters>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<IReferralsFilters>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllReferralsThunk.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllReferralsThunk.fulfilled, (state, action) => {
                const { pager, referrals = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (referrals.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                referrals.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        // @ts-ignore
                        newByPage[page].push(item.id);
                    }
                });

                GetAllReferralsAdapter.addMany(state, referrals || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
                state.loading = false;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.referrals,
            }));
    },
});

export const { actions } = referralsSlice;
export default referralsSlice.reducer;