import { RequestPagerI } from '@tacans/shared-marketplace-layer';

export const useCreateGetAllReferralsRequestPayload = ({
    pager
}: {
    pager?: RequestPagerI;
}) => ({
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});