import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useExploreTotal } from 'src/features/common/explore/hooks/use-explore-launchpads';
import { useIsSeller } from 'src/features/common/profile/hooks/use-is-seller';
import { useUniverseGetMineFetch } from 'src/features/common/universe/hooks/universe-mine.hooks';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { NavigationLinkI } from 'src/types';
import {
    DesktopLink,
    StyledBadge,
    StyledBuyScreenWrapper,
    StyledDesktopLinks,
    StyledLinksVisible,
} from './header.styles';

export const DesktopLinks = () => {
    const { pathname } = useRouter();
    const { isUniverse, isUniverseOwner } = useUniverse();
    const authorized = useAuthorized();
    const isSeller = useIsSeller();
    const initedRef = useRef(false);
    const total = useExploreTotal();
    const { t } = useTranslation('tech-contemporary');

    const locale = useMemo(
        () => ({
            artists: t('header.navigate.artists', {
                ns: 'tech-contemporary',
            }),
            art: t('header.navigate.art', {
                ns: 'tech-contemporary',
            }),
            calendar: t('header.navigate.calendar', {
                ns: 'tech-contemporary',
            }),
            about: t('header.navigate.about', {
                ns: 'tech-contemporary',
            }),
        }),
        [t]
    );
    const fetchMineUniverse = useUniverseGetMineFetch();

    const navigationLinks = useMemo<NavigationLinkI[]>(() => {
        const res = [
            { title: locale.art, href: RouteE.EXPLORE, rel: 'canonical' },
            { title: locale.calendar, href: RouteE.CALENDAR, rel: 'canonical' },
            { title: locale.about, href: RouteE.ABOUT, rel: 'canonical' },
        ];
        return res;
    }, [isUniverse, locale, total, isSeller, isUniverseOwner]);

    const initData = useCallback(() => {
        if (initedRef.current) {
            return;
        }
        initedRef.current = true;
    }, []);

    useEffect(() => {
        if (authorized) {
            fetchMineUniverse();
        }
    }, [authorized, fetchMineUniverse]);

    return (
        <>
            <StyledLinksVisible>
                <StyledDesktopLinks alignItems="center" onMouseEnter={initData}>
                    {navigationLinks.map((link) => (
                        <Link key={link.href} href={link.href} rel={link.rel} prefetch={false} passHref>
                            <DesktopLink rel="canonical" active={link.href === pathname}>
                                <span>{link.title}</span>
                            </DesktopLink>
                        </Link>
                    ))}
                    <StyledBuyScreenWrapper>
                        <Link href="https://www.anywall.io/" rel="canonical" prefetch={false} passHref>
                            <DesktopLink target="_blank" rel="canonical">
                                <span>Buy Screen</span>
                                <StyledBadge>NEW</StyledBadge>
                            </DesktopLink>
                        </Link>
                    </StyledBuyScreenWrapper>
                </StyledDesktopLinks>
            </StyledLinksVisible>
        </>
    );
};