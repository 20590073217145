import { useCallback } from 'react';

const NEXT_DATA = 'next-data';
export const useClearNextDataCache = () => useCallback(async () => {
        try {
            if (!('serviceWorker' in navigator)) {
                return;
            }
            const hasNextData = await caches.has(NEXT_DATA);

            if(!hasNextData) {
                return;
            }

            caches.delete(NEXT_DATA);
        } catch (e) {
            console.warn('useClearNextDataCache:', e);
        }
    }, []);
