import { StyledIcon } from 'src/features/common/app/components/header/header.styles';
import { Button } from 'src/ui/common/atoms/button';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import styled, { DefaultTheme } from 'styled-components';

export const StyledWrapper = styled.div<{ sticky: boolean }>`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 5px -2px rgb(0 0 0 / 10%);
    min-height: 80px;
    ${({ sticky }) =>
        sticky &&
        ` 
        position: fixed;
        z-index: 4;
        top: 0;
        width: 100%;
        transition: all 0.5s ease;
        animation: smoothScroll 1s forwards;`};

    @keyframes smoothScroll {
        0% {
            transform: translateY(-142px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    & a {
        color: ${({ theme }) => theme.colors.black};
        font-size: 16px;
        font-weight: 600;
    }

    & a > span:after {
        display: block;
        content: '';
        border-bottom: solid 2px ${({ theme }) => theme.colors.blue};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        color: ${({ theme }) => theme.colors.black};
    }

    & a:hover {
        color: ${({ theme }) => theme.colors.black}!important;
    }

    & a > span:hover:after {
        transform: scaleX(1);
    }
`;

export const StyledHeader = styled(FlexBox)`
    height: ${({ theme }: { theme: DefaultTheme }) => theme.constants.headerDskHeight}px;
`;

export const StyledLogo = styled(FlexBox)`
    width: 240px;
    height: 100%;
    user-select: none;

    & img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        max-width: calc(100% - 10px);
        max-height: calc(100% - 10px);
    }

    & a {
        display: block;
        height: 100%;
        width: 100%;
        font-size: 0;
        position: relative;
    }

    ${media.md`
        width: 240px;
  `}
`;

export const UserMenuWrapper = styled(FlexBox)`
    margin-right: 10px;

    ${media.md`
        margin-left: auto;
    
        & button {
          height: 50px;
          padding: 0 20px;
        }
  `}
`;

export const StyledLoginButton = styled(Button)`
    margin: 0 5px 0 0;

    ${media.md`
        margin: 0 10px 0 0;
  `}
`;

export const UserDropDownIcon = styled(StyledIcon)`
    background-color: ${({ theme }) => theme.colors.purple};
    border: 1px solid ${({ theme }) => theme.colors.gs300};
    border-radius: 0;

    ${media.md`
        display: none;
  `};
`;

export const StyledDropDown = styled.div<{ opened: boolean }>`
    position: fixed;
    padding: 20px;
    right: 0;
    top: ${({ theme }) => theme.constants.headerMbHeight}px;
    bottom: 0;
    z-index: 9;
    width: 100vw;
    overflow-y: auto;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    transform: ${({ opened }) => (opened ? 'translateY(0)' : 'translateY(-10%)')};
    opacity: ${({ opened }) => (opened ? '1' : '0')};
    visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
    transition: transform ${speed.content} cubic-bezier(0.4, 0, 0.6, 1) 0ms, opacity ${speed.content} ease-in,
        visibility ${speed.content} ease-in;
    box-shadow: 7px 6px 40px 0 rgb(204 204 223 / 60%);

    ${media.md`
        padding: 15px;
        top: ${({ theme }: { theme: DefaultTheme }) => theme.constants.headerDskHeight}px;
        width: 295px;
        bottom: auto;
  `}
`;
export const StyledLinksVisible = styled.div`
    height: 100%;
    display: none;

    ${media.md`
        display: block;
  `}
`;

export const DesktopLink = styled.a<{ active?: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 14px;
    user-select: none;
    transition: all ${speed.content} ease-in-out;
    border-bottom: 2px solid transparent;

    &:hover {
        color: white !important;
    }

    & + & {
        margin-left: 20px;
    }

    ${({ active, theme }) => active && `border-bottom: 2px solid ${theme.colors.blue};`}
`;

export const StyledDesktopLinks = styled(FlexBox)`
    margin: 0 20px 0 40px;
    height: 100%;

    &:hover ${DesktopLink} {
        color: ${({ theme }) => theme.colors.gs200};
    }
`;

export const StyledSocialLink = styled.a`
    color: white;
    display: block;

    & > div {
        min-width: 150px;
        border-radius: 5px;
        height: 40px;
        padding: 0 5px 0 10px;
        transition: background-color ${speed.content} ease-in-out;
    }

    & > div:hover {
        background-color: ${({ theme }) => theme.colors.gs450};

        svg {
            color: white;
        }
    }

    & svg {
        color: ${({ theme }) => theme.colors.gs400};
        transition: color ${speed.content} ease-in-out;
        margin-left: 20px;
    }
`;
