import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CollectionAPI,
    ICreateCollectionRequest,
    IUpdateCollectionRequest,
} from '@tacans/shared-marketplace-layer';

export const collectionCreateThunk = createAsyncThunk(
    'collection/create',
    async (data: ICreateCollectionRequest) => {
        const response = await CollectionAPI.create(data);

        return response;
    }
);

export const collectionFetchThunk = createAsyncThunk(
    'collection/fetch',
    async (id: string) => {
        const response = await CollectionAPI.fetchById(id);
        return response.data;
    }
);

export const collectionUpdateThunk = createAsyncThunk(
    'collection/update',
    async (data: IUpdateCollectionRequest) => {
        const response = await CollectionAPI.update(data);

        return response;
    }
);
