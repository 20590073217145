import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import useResizeObserver, { IContentRect } from 'src/hooks/use-resize-observer';
import { useLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';
import { debounce, IS_SSR, throttle } from 'src/utils/helpers.utils';
import { isShallowEqual } from 'src/utils/is-shallow-equal';
import { StyledRoot } from './dropdown.styles';
import { IDropdownProps, Position, Style } from './sropdown.types';
import { DropDownContext, useDropDownContext } from './dropdown.context';

const SPACING_M = 20;

type IDropDown<P> = React.FC<P> & {
  Content: typeof Content;
}

export const DropDown: IDropDown<IDropdownProps> = ({
  nodeRef,
  dropdownRef,
  opened,
  children,
  fullWidth,
  fitContentWidth,
  fitParentWidth,
  horizontalBindTo = 'left',
  verticalBindTo = 'bottom'
}) => {
  const initRef = useRef(false);
  const [calculatedPosition, setCalculatedPosition] = useState<Position>({});
  const [styles, setStyles] = useState<Style>({});
  const [dropdownRect, setDropdownRect] = useState<IContentRect>(
    {} as IContentRect
  );

  const resizeObserverCallback = useCallback(
    (contentRect) => {
      opened &&
        setDropdownRect((prevState: IContentRect) => {
          if (!isShallowEqual(prevState, contentRect)) {
            return { ...contentRect };
          }
          return prevState;
        });
    },
    [opened]
  );

  useResizeObserver(resizeObserverCallback, dropdownRef, 0);

  const value = React.useMemo(
    () => ({
      opened,
    }),
    [opened]
  );

  const calculatePosition = useCallback(() => {
    const { width: dropdownWidth, height: dropdownHeight } = dropdownRect;
    if (
      !IS_SSR &&
      opened &&
      nodeRef.current &&
      dropdownHeight
    ) {
      const { innerWidth: clientWidth } = window;
      const pos: Position = {};
      const style: Style = {};

      const {
        left,
        top,
        height: parentHeight,
        width: parentWidth
      } = nodeRef.current.getBoundingClientRect();

      if (verticalBindTo === 'top') {
        pos.top = top - dropdownHeight;
      } else {
        pos.top = top + parentHeight;
      }
      // if (offsetY) {
      //   pos.top += offsetY;
      // }

      if (horizontalBindTo === 'left') {
        pos.left = left;
      } else if (horizontalBindTo === 'right') {
        pos.right = clientWidth - left - parentWidth;
      }

      if (horizontalBindTo === 'center') {
        pos.left = left + parentWidth / 2 - dropdownWidth / 2;
      }

      if (fitContentWidth) {
        pos.left = left;
        pos.right = clientWidth - left - parentWidth;
      }

      if (fitParentWidth) {
        pos.left = left;
        style.width = parentWidth;
      }

      if (fullWidth) {
        pos.left = SPACING_M / 2;
        style.width = clientWidth - SPACING_M;
      }

      initRef.current = true;
      setCalculatedPosition(pos);
      setStyles(style);
    }
  }, [fitContentWidth, fitParentWidth, horizontalBindTo, verticalBindTo, dropdownRect, opened, nodeRef, fullWidth]);

  const debouncedCalculateCallback = useCallback(debounce(calculatePosition), [calculatePosition]);
  const throttledCalculateCallback = useCallback(throttle(calculatePosition), [calculatePosition]);

  useLayoutEffect(() => {
    if (opened && nodeRef.current) {
      calculatePosition();
    }
  }, [calculatePosition, nodeRef, opened]);

  useEffect(() => {
    if (opened) {
      window.addEventListener('scroll', throttledCalculateCallback);
      window.addEventListener('resize', debouncedCalculateCallback);
    } else {
      initRef.current = false;
    }
    return () => {
      window.removeEventListener('scroll', throttledCalculateCallback);
      window.removeEventListener('resize', debouncedCalculateCallback);
    };
  }, [debouncedCalculateCallback, opened, throttledCalculateCallback]);

  return (
    <DropDownContext.Provider value={value}>
      {opened ? ReactDOM.createPortal(
        <StyledRoot
          data-element="dropdown"
          style={{
            ...calculatedPosition,
            ...styles,
            visibility: initRef.current ? 'visible' : 'hidden',
            opacity: initRef.current ? '1' : '0'
          }}
          ref={dropdownRef}
        >
          {children}
        </StyledRoot>,
        document.body
      ) : null}
    </DropDownContext.Provider>
  );
};

interface IContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: ReactNode;
}

function Content({ children, className, onClick }: IContentProps) {
  const { opened } = useDropDownContext();

  if (!opened) {
    return null;
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
}

DropDown.Content = Content;
