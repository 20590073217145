import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { fetchByUserAddressesThunk } from 'src/features/common/profile/ducks/addresses/addresses.thunk';

export interface IUserAddress {
    userId: number;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    country: string;
    city: string;
    postalCode: number;
    streetAddress: string;
    typeAddress: string;
}

interface IState {
    addresses: IUserAddress[];
}

const INITIAL_STATE: IState = {
    addresses: [],
};

export const addressesSlice = createSlice({
    name: 'addresses',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchByUserAddressesThunk.fulfilled, (state, action: any) => {
                state.addresses = action.payload.data.addresses;
            })
            .addCase(HYDRATE, (state, action) => {
                // @ts-ignore
                const { addresses } = action.payload.features.profile.addresses;
                if (addresses) {
                    state.addresses = addresses;
                }
            });
    },
});

export const { actions } = addressesSlice;

export default addressesSlice.reducer;