import { createAsyncThunk } from '@reduxjs/toolkit';
import { DashboardAPI, TopCollectionsRequestI } from '@tacans/shared-marketplace-layer';

export const topCollectionsFetchThunk = createAsyncThunk(
    'topCollections/fetch',
    async (request: TopCollectionsRequestI) => {
        const response = await DashboardAPI.fetchTopCollections(request);
        return response.data;
    }
);
