import { IReferralsFilters } from '@tacans/shared-marketplace-layer/dist/api/referral/referral.types';
import { IReferralFilters } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.types';

export const createFilterFieldsPayload = (meta: IReferralFilters): IReferralsFilters => {
    const fields: IReferralsFilters = {};

    meta = meta || {};

    if (meta.blockchainId?.length) {
        fields.blockchainIds = meta.blockchainId.map((id) => Number(id));
    }

    if (meta.status) {
        fields.status = meta.status;
    }

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    return fields;
};