import { ESortBy, IListFilter } from '@tacans/shared-marketplace-layer';
import { ExploreSort, IExploreFilters } from './ducks/explore-arts/explore-arts.types';

export const createFilterFieldsPayload = (meta: IExploreFilters): IListFilter => {
    const fields: IListFilter  = {};

    meta = meta || {};

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    if (meta.ownerIds?.length) {
        fields.ownerIds = meta.ownerIds;
    }

    if (meta.priceRange?.from) {
        fields.priceFrom = { value: +meta.priceRange.from };
    }

    if (meta.priceRange?.to) {
        fields.priceTo = { value: +meta.priceRange.to };
    }


    if (meta.artistIds?.length) {
        fields.artistIds = meta.artistIds;
    }

    if (meta.isPhysical?.length) {
        // @ts-ignore
        fields.isPhysical = meta.isPhysical;
    }

    return fields;
};

export const mapSorting = (sort: ExploreSort | null) => {
    switch(sort) {
        case ExploreSort.PRICE_ASC:
            return {
                sortBy: ESortBy.Price,
                ascending: true
            };
        case ExploreSort.PRICE_DESC:
            return {
                sortBy: ESortBy.Price, 
                ascending: false
            };
        case ExploreSort.NAME_ASC:
            return {
                sortBy: ESortBy.Name,
                ascending: true
            };
        case ExploreSort.NAME_DESC:
            return {
                sortBy: ESortBy.Name,
                ascending: false
            };
        default: 
            return {
                sortBy: ESortBy.Date, 
                ascending: false
            };
    }
};