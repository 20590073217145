import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';

import { CollectionsAdapter } from './collections.adapter';

export const collectionsSelect = (state: RootState) => state.features.collections.explore;

export const collectionsSelectors = CollectionsAdapter.getSelectors();

export const selectCollections = createSelector(
    collectionsSelect,
    (state) => collectionsSelectors.selectAll(state)
);

export const selectCollectionsEntities = createSelector(
    collectionsSelect,
    (state) => collectionsSelectors.selectEntities(state)
);

export const selectFilter = createSelector(
    collectionsSelect,
    (state) => state.filter
);

export const selectPager = createSelector(
    collectionsSelect,
    (state) => state.pager
);

export const selectSorting = createSelector(
    collectionsSelect,
    (state) => state.sorting
);

export const selectPages = createSelector(
    collectionsSelect,
    (state) => state.pages
);

export const selectByPage = createSelector(
    collectionsSelect,
    (state) => state.byPage
);

export const selectTotal = createSelector(
    collectionsSelect,
    (state) => state.total
);

export const selectLoading = createSelector(
    collectionsSelect,
    (state) => state.loading
);
