import styled from 'styled-components';

export const StyledSeenOn = styled.div`
    & h3 {
        position: relative;
        padding-bottom: 7px;
        &::after {
            content: '';
            position: absolute;
            background-color: white;
            width: 40px;
            height: 3px;
            left: 0;
            bottom: 0;
        }
    }
`;
