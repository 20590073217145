import { ModalVariantT, ModalRegistryEntityItem, ModalRegistryItem } from '../modal.types';
import { ModalEntity } from './modal.entity';

type Listener = (modals: ModalRegistryEntityItem[]) => void;

class ModalRegistry {
  private modals: Map<string, ModalRegistryEntityItem> = new Map();

  private opened: Map<string, boolean> = new Map();

  private listeners: Listener[] = [];

  onModalStateChanged(id: ModalVariantT, state = false) {
    const modal = this.get(id);
    if (modal) {
      this.opened.set(id, state);
      this.emit();
    }
  }

  register<T>(modalArgs: ModalRegistryItem) {
    const modal = new ModalEntity<T>({
      ...modalArgs,
      onOpen: () => this.onModalStateChanged(modalArgs.id, true),
      onClose: () => this.onModalStateChanged(modalArgs.id, false),
    });
    this.modals.set(modal.id, modal);
    this.emit();
    return modal;
  }

  emit() {
    this.listeners.forEach(cb => cb([...this.modals.values()].map(modal => ({
      ...modal,
      opened: !!this.opened.get(modal.id),
    }))));
  }

  subscribe(cb: Listener) {
    this.listeners.push(cb);
  }

  unsubscribe(cb: Listener) {
    this.listeners.filter(l => l !== cb);
  }

  get(id: ModalVariantT) {
    return this.modals.get(id);
  }
}

export const modalRegistry = new ModalRegistry();
