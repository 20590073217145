import { createSlice } from '@reduxjs/toolkit';

import { TrendingNftsAdapter } from './trending-nfts.adapter';
import { trendingNftsFetchThunk } from './trending-nfts.thunk';

const getInitialState = () => TrendingNftsAdapter.getInitialState({
    isLoading: false,
    pager: {},
});

export const trendingNftsSlice = createSlice({
    name: 'nfts/trendingNfts',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
    },
    extraReducers: (builder) => {
        builder
            .addCase(trendingNftsFetchThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(trendingNftsFetchThunk.fulfilled, (state, action) => {
                TrendingNftsAdapter.setMany(state, action.payload.list || []);
                state.isLoading = false;
                state.pager = action.payload.pager;
            })
            .addCase(trendingNftsFetchThunk.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const { actions } = trendingNftsSlice;

export default trendingNftsSlice.reducer;
