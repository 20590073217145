import React from 'react';

export const enum NotificationTypeEnum {
    success = 'success',
    info = 'info',
    warning = 'warning',
    error = 'error',
}

export interface INotification {
    timeout?: number;
    type?: NotificationTypeEnum;
    title?: string;
    text?: string;
    description?: string;
    component?: React.FC<any>;
    closable?: boolean;
}

export interface IRegistryNotification extends INotification {
    id: number;
    remove: () => void;
}
