import UserAvatar from 'src/features/common/user/components/user-avatar';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.gs400};
    padding-top: 20px;
    margin-top: 20px;
`;

export const AccountMenu = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.gs400};
    padding-bottom: 20px;
    margin-bottom: 20px;

    & > div:first-child + * {
        margin-top: 20px;
    }

    ${media.md`
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      
        & > div:first-child + * {
          margin-top: 0;
        }
  `}
`;

export const StyledUserWrapper = styled(FlexBox)`
    & button {
        margin: 0 10px;
    }

    & svg {
        margin-left: auto;
        margin-right: 12px;
    }
`;

export const StyledUserAvatar = styled(UserAvatar)`
    background-color: ${({ theme }) => theme.colors.warmBlack};
`;

export const StyledAccordionContent = styled.div<{ opened: boolean }>`
    transition: max-height ${speed.content} ease-in-out 0s;
    overflow: hidden;
    max-height: ${({ opened }) => (opened ? '100vh' : '0')};

    & a:first-child {
        margin-top: 25px;
    }

    & a:last-child {
        margin-bottom: 20px;
    }

    & a {
        color: white;
        margin-top: 5px;
        margin-left: 10px;
        line-height: 30px;
        height: 30px;
        display: flex;
        align-items: center;
    }
`;
