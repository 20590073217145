/**
 * Libs
 */
import React, { useCallback } from 'react';
import { useHasNotifications } from 'src/features/common/notifications/hooks/notifications.hooks';

/**
 * Hooks
 */
import { useRenderNotificationsSidebar } from 'src/features/common/notifications/hooks/use-render-notifications-sidebar';

/**
 * Components
 */
import { IconBox } from 'src/ui/common/atoms/icon-box';

/**
 * Styles
 */
import { StyledHeaderNotifications, StyledNotificationFlag } from './header-notifications.styles';

export const HeaderNotifications = () => {
    const renderNotificationsSidebar = useRenderNotificationsSidebar();
    const hasNotifications = useHasNotifications();

    const onNotificationsClick = useCallback(() => {
        renderNotificationsSidebar();
    }, [renderNotificationsSidebar]);

    return (
        <StyledHeaderNotifications>
            <IconBox icon="bell" variant="light" onClick={onNotificationsClick} />
            {hasNotifications && <StyledNotificationFlag />}
        </StyledHeaderNotifications>
    );
};
