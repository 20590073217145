export const SOCIALS = [
    {
        label: 'twitter',
        icon: 'twitter',
        url: 'https://twitter.com/spaceseven_nft',
        foreign: true,
    },
    {
        label: 'facebook',
        icon: 'facebook-light',
        url: 'https://www.facebook.com/SpaceSeven-108739658150141',
        foreign: true
    },
    {
        label: 'medium',
        icon: 'medium',
        url: 'https://medium.com/space-seven',
        foreign: true
    },
    {
        label: 'instagram',
        icon: 'instagram',
        url: 'https://www.instagram.com/spaceseven_nft/',
        foreign: true
    }
];

export const s7CommunityLinks = [{
    label: 'discord',
    url: 'https://discord.gg/mZ9MDdJahH'
}, {
    label: 'telegram',
    url: 'https://t.me/spaceseven_nft'
},
{
    label: 'telegram-announcement',
    url: 'https://t.me/spaceseven_official'
}];
