import { IUsersListFilter, RequestPagerI } from '@tacans/shared-marketplace-layer';
import { mapUsersSorting, createUsersFilterFieldsPayload } from 'src/features/common/explore/users-api.utils';
import { UsersSort } from '../ducks/users/users.types';

export const useCreateUsersRequestPayload = ({
    sort,
    filter,
    pager,
}: {
    sort: UsersSort;
    filter: IUsersListFilter;
    pager?: RequestPagerI;
}) => ({
    sorting: mapUsersSorting(sort),
    filter: createUsersFilterFieldsPayload(filter),
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});
