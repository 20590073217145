import { useCallback } from 'react';
import { selectUniverseInfo } from 'src/features/common/universe/ducks/universe-info/universe-info.selectors';
import { fetchUniverseInfoThunk } from 'src/features/common/universe/ducks/universe-info/universe-info.thunk';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';

export const useFetchUniverseInfoThunk = () => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
      await dispatch(fetchUniverseInfoThunk());
    }, [dispatch]);
};

export const useUniverseInfo = () => useAppSelector(selectUniverseInfo);
