import { useMemo } from 'react';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';

export const useUniversePermissions = () => {
    const { isUniverse } = useUniverse();
    const profile = useProfile();

    const canCreate = useMemo(() => {
        if (!isUniverse) {
            return true;
        }
        return !!profile?.personalInfo?.isSeller;
    }, [isUniverse, profile?.personalInfo?.isSeller]);

    return {
        canCreate,
    };
};
