import { WalletProviderE } from '@tacans/shared-marketplace-layer';
import Router from 'next/router';
import { useCallback } from 'react';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useConnectWallet } from 'src/features/common/wallet/hooks/wallet.hooks';

export const useConnectMetamaskAction = () => {
    const authorized = useAuthorized();
    const connectWallet = useConnectWallet();

    return useCallback(
        (openAuth?: boolean) => {
            if (authorized) {
                connectWallet(WalletProviderE.Ethereum);
                sessionStorage.removeItem('triggerAction');
            } else if (openAuth) {
                sessionStorage.setItem('triggerAction', 'connect-metamask');
                Router.push('/login');
            }
        },
        [authorized, connectWallet]
    );
};
