import styled, { css } from 'styled-components';
import { media } from 'src/ui/common/mixins/media';
import { TypographyPropsWithRef } from 'src/ui/common/atoms/typography/typography.types';
import {
    getFontSize,
    getFontWeight,
} from 'src/ui/common/atoms/typography/typography.utils';

const overflowMixin = css<TypographyPropsWithRef>`
  max-width: 100%;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${({ overflowLines }) => overflowLines ? 'normal' : undefined};
  -webkit-box-orient: ${({ overflowLines }) => overflowLines ? 'vertical' : undefined};
  -webkit-line-clamp: ${({ overflowLines }) => overflowLines || undefined};
`;

export const StyledTypography = styled.span<TypographyPropsWithRef>`
  line-height: 120%;
  font-family: ${({ theme, variant }) => variant === 'title' ? theme.fonts.title : theme.fonts.text};
  text-transform: ${({ uppercase, capitalize }) => uppercase && 'uppercase' || capitalize && 'capitalize' || undefined};
  white-space: ${({ breakSpaces }) => breakSpaces ? 'break-spaces' : undefined};
  color: ${({ color, theme }) => theme.colors[color]};
  
  font-size: ${({ size }) => getFontSize(size)};
  ${({ sizeSm }) => sizeSm && media.sm`font-size: ${getFontSize(sizeSm)}`};
  ${({ sizeMd }) => sizeMd && media.md`font-size: ${getFontSize(sizeMd)}`};
  ${({ sizeLg }) => sizeLg && media.lg`font-size: ${getFontSize(sizeLg)}`};
  
  ${({ center }) => center && `
    -webkit-box-pack: center;
    text-align: center;
  `};
  
  word-break: break-word;
  -webkit-text-size-adjust: none;
  
  ${({ bold, semiBold, medium }) => getFontWeight({ bold, semiBold, medium })};
  ${overflowMixin}
  
  ${media.sm`
    line-height: 130%;
  `}
`;

