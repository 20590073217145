import { useState } from 'react';
import { ISidebarContext } from 'src/features/common/app/components/sidebar/sidebar.context';

export const useSidebarContextState = () => {
    const [title, setTitle] = useState<ISidebarContext['title']>(null);
    const [content, setContent] = useState<ISidebarContext['content']>(null);

    return {
        title,
        setTitle,
        content,
        setContent
    };
};
