import { createAsyncThunk } from '@reduxjs/toolkit';
import { NftAPI } from '@tacans/shared-marketplace-layer';
import { selectPager } from 'src/features/common/explore/ducks/explore-launchpad-nfts/explore-launchpad-nfts.selectors';
import { RootState } from 'src/store/store';

export const fetchLaunchpadNftsThunk = createAsyncThunk(
    'explore-launchpad-nfts/fetch',
    async (launchpadId: string, thunkApi) => {
        const rootState = thunkApi.getState() as RootState;
        const pager = selectPager(rootState);
        const payload = {
            launchpadId,
            pager: {
                page: pager?.page ?? 0,
                limit: pager?.limit ?? 20,
            },
        };
        const { data } = await NftAPI.fetchByLaunchpad(payload);

        return data;
    }
);
