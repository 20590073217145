import { createSlice } from '@reduxjs/toolkit';
import { UniversesItem } from '@tacans/shared-marketplace-layer';
import { universeGetMineThunk } from './universe-mine.thunk';

interface IState {
    universe: UniversesItem | null;
    loading: boolean;
    loaded: boolean;
}

const INITIAL_STATE: IState = {
    universe: null,
    loading: false,
    loaded: false,
};

export const universeMineSlice = createSlice({
    name: 'universe-mine',
    initialState: INITIAL_STATE,
    reducers: {
        clear: () => INITIAL_STATE,
        update: (state, action) => {
            const { universe } = action.payload.data;
            if (universe) {
                state.universe = {
                    ...universe,
                    affiliateProgram: !!universe.affiliateProgram,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(universeGetMineThunk.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(universeGetMineThunk.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(universeGetMineThunk.fulfilled, (state, action) => {
                const { universe } = action.payload.data;
                if (universe) {
                    state.universe = {
                        ...universe,
                        affiliateProgram: !!universe.affiliateProgram,
                    };
                }
                state.loaded = true;
                state.loading = false;
            });
    },
});

export const { actions } = universeMineSlice;

export default universeMineSlice.reducer;