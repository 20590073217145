import { NFTI, LotI, WalletProviderE, TransactionMethod } from '@tacans/shared-marketplace-layer';

export enum ITransactionStatusE {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    RejectedByTTL = 'RejectedByTTL',
    Success = 'Success'
}

export interface ITransaction {
    status: ITransactionStatusE;
    txHash: string;
    token: NFTI;
    lot: LotI;
    contractMethod: TransactionMethod,
    trMethodName: TransactionMethod,
    walletProvider: WalletProviderE;
    ttlToReject?: number; // in ms.
}

export interface IRegistryTransaction extends ITransaction {
    id: number;
    isDeleted?: boolean;
    remove: () => void;
    updateStatus: (status: ITransactionStatusE) => void;
}
