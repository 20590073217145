import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { RouteE } from 'src/constants/pages.constants';
import { CreateNftStoreBtn } from 'src/features/common/app/components/create-nft-store-btn';
import { HeaderNotifications } from 'src/features/common/app/components/header-notifications';
import { useAuthorized } from 'src/features/common/auth/hooks/auth.hooks';
import { useProfile } from 'src/features/common/profile/hooks/profile.hooks';
import { useUniversePermissions } from 'src/features/common/universe/hooks/use-universe-permissions';
import UserAvatar from 'src/features/common/user/components/user-avatar';
import { useDisabledWallets } from 'src/features/common/wallet/hooks/use-disabled-wallets';
import { useRenderConnectWalletSidebar } from 'src/features/common/wallet/hooks/use-render-connect-wallet-sidebar';
import { useWalletsLoaded } from 'src/features/common/wallet/hooks/wallet.hooks';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Visible } from 'src/ui/common/layouts/visible';
import { IS_SSR } from 'src/utils/helpers.utils';
import { StyledDropDown, StyledLoginButton, UserDropDownIcon, UserMenuWrapper } from './header.styles';

const HeaderUserMenu = dynamic<any>(() => import('../header-user-menu').then((m) => m.HeaderUserMenu) as any, {
    ssr: false,
});

export const UserProfile = () => {
    const renderConnectWalletSidebar = useRenderConnectWalletSidebar();
    const router = useRouter();
    const [init, setInit] = useState(false);
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation(['actions']);
    const authorized = useAuthorized();
    const profile = useProfile();
    const disabledWallets = useDisabledWallets();
    const walletsLoaded = useWalletsLoaded();
    const { canCreate } = useUniversePermissions();

    const locale = useMemo(
        () => ({
            login: t('auth.login', { ns: 'actions' }),
            connect: t('common.connect', { ns: 'actions' }),
            create: t('crud.create', { ns: 'actions' }),
        }),
        [t]
    );

    const showConnectButton = useMemo(() => Object.values(disabledWallets).every((item) => !item), [disabledWallets]);

    const toggle = useCallback(() => setOpened((s) => !s), []);

    const close = useCallback((e) => {
        e?.stopPropagation?.();
        setOpened(false);
        window.removeEventListener('scroll', close);
    }, []);

    const login = useCallback(() => {
        router.push('/login');
    }, []);

    const handleConnectClick = useCallback(() => {
        renderConnectWalletSidebar();
    }, [renderConnectWalletSidebar]);

    useEffect(() => {
        setInit(true);
        if (!isMobileOnly && opened) {
            window.addEventListener('scroll', close);
        }
        if (isMobileOnly && opened) {
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
    }, [close, opened]);

    useEffect(() => {
        router.events.on('routeChangeStart', close);

        return () => {
            router.events.off('routeChangeStart', close);
        };
    }, [router, close]);

    return (
        <UserMenuWrapper justifyContent="flex-end">
            <FlexBox alignItems="center">
                <Spacing margin="0 20px 0 0">
                    <Link href="https://discord.gg/wxMqPyAUvY">
                        <a target="_blank">
                            <svg
                                fill="#228BE6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24px"
                                height="24px"
                            >
                                <path d="M19.98,5.69c-1.68-1.34-4.08-1.71-5.12-1.82h-0.04c-0.16,0-0.31,0.09-0.36,0.24c-0.09,0.23,0.05,0.48,0.28,0.52 c1.17,0.24,2.52,0.66,3.75,1.43c0.25,0.15,0.31,0.49,0.11,0.72c-0.16,0.18-0.43,0.2-0.64,0.08C15.56,5.38,12.58,5.3,12,5.3 S8.44,5.38,6.04,6.86C5.83,6.98,5.56,6.96,5.4,6.78C5.2,6.55,5.26,6.21,5.51,6.06c1.23-0.77,2.58-1.19,3.75-1.43 c0.23-0.04,0.37-0.29,0.28-0.52c-0.05-0.15-0.2-0.24-0.36-0.24H9.14C8.1,3.98,5.7,4.35,4.02,5.69C3.04,6.6,1.09,11.83,1,16.46 c0,0.31,0.08,0.62,0.26,0.87c1.17,1.65,3.71,2.64,5.63,2.78c0.29,0.02,0.57-0.11,0.74-0.35c0.01,0,0.01-0.01,0.02-0.02 c0.35-0.48,0.14-1.16-0.42-1.37c-1.6-0.59-2.42-1.29-2.47-1.34c-0.2-0.18-0.22-0.48-0.05-0.68c0.18-0.2,0.48-0.22,0.68-0.04 c0.03,0.02,2.25,1.91,6.61,1.91s6.58-1.89,6.61-1.91c0.2-0.18,0.5-0.16,0.68,0.04c0.17,0.2,0.15,0.5-0.05,0.68 c-0.05,0.05-0.87,0.75-2.47,1.34c-0.56,0.21-0.77,0.89-0.42,1.37c0.01,0.01,0.01,0.02,0.02,0.02c0.17,0.24,0.45,0.37,0.74,0.35 c1.92-0.14,4.46-1.13,5.63-2.78c0.18-0.25,0.26-0.56,0.26-0.87C22.91,11.83,20.96,6.6,19.98,5.69z M8.89,14.87 c-0.92,0-1.67-0.86-1.67-1.91c0-1.06,0.75-1.92,1.67-1.92c0.93,0,1.67,0.86,1.67,1.92C10.56,14.01,9.82,14.87,8.89,14.87z M15.11,14.87c-0.93,0-1.67-0.86-1.67-1.91c0-1.06,0.74-1.92,1.67-1.92c0.92,0,1.67,0.86,1.67,1.92 C16.78,14.01,16.03,14.87,15.11,14.87z" />
                            </svg>
                        </a>
                    </Link>
                </Spacing>
                <Spacing margin="0 20px 0 0">
                    <Link href="https://www.instagram.com/nftsuperlab/">
                        <a target="_blank">
                            <svg
                                fill="#228BE6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                            >
                                <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z" />
                            </svg>
                        </a>
                    </Link>
                </Spacing>
            </FlexBox>

            {init && !authorized && (
                <StyledLoginButton variant="white" onClick={login}>
                    {locale.login}
                </StyledLoginButton>
            )}
            {init && authorized && (
                <>
                    <HeaderNotifications />
                    <Spacing mr="5px" mrMd="10px">
                        <CreateNftStoreBtn />
                    </Spacing>
                    {!showConnectButton && walletsLoaded && canCreate && (
                        <Link href={RouteE.COLLECTIBLE_CREATE} prefetch={false}>
                            <StyledLoginButton variant="gradient">{locale.create}</StyledLoginButton>
                        </Link>
                    )}
                    {showConnectButton && walletsLoaded && (
                        <StyledLoginButton variant="gradient" onClick={handleConnectClick}>
                            {locale.connect}
                        </StyledLoginButton>
                    )}
                    <Visible view="desktop" display="flex">
                        <UserAvatar onClick={toggle} size={50} variant="square" user={profile?.user} />
                    </Visible>
                </>
            )}
            <Visible view={['laptop', 'mobile']} display="flex">
                <UserDropDownIcon onMouseDown={toggle} alignItems="center" justifyContent="center">
                    <SvgIcon variant="regular" icon={opened ? 'close' : 'hamburger'} />
                </UserDropDownIcon>
            </Visible>
            {IS_SSR
                ? null
                : ReactDOM.createPortal(
                      <OutsideClickHandler disabled={isMobileOnly || !opened} onOutsideClick={close}>
                          <StyledDropDown opened={opened}>
                              {opened && (
                                  // @ts-ignore
                                  <HeaderUserMenu onClose={close} />
                              )}
                          </StyledDropDown>
                      </OutsideClickHandler>,
                      document.body
                  )}
        </UserMenuWrapper>
    );
};
