import dynamic from 'next/dynamic';
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';

const AcceptedTermsModalComponent = dynamic(
    () => import('./accepted-terms-modal-component').then((d) => d.AcceptedTermsModalComponent) as any,
    {
        ssr: true,
    }
);

export const AcceptedTermsModal = modalRegistry.register({
    id: 'AcceptedTermsModal',
    component: AcceptedTermsModalComponent,
});