import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import {
    useResetSearchKeyword,
    useSearchKeyword,
    useSetSearchKeyword,
} from 'src/features/common/search/hooks/search.hooks';
import { useInputSearch } from 'src/features/common/search/hooks/use-input-search';
import { Search } from 'src/features/white-labels/ocean/app/components/header-search/search';

export const HeaderSearch = () => {
    const { query } = useRouter();
    const setSearchKeyword = useSetSearchKeyword();
    const resetSearchKeyword = useResetSearchKeyword();
    const searchKeyword = useSearchKeyword();
    const inputSearch = useInputSearch();

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            inputSearch(searchKeyword);
        },
        [inputSearch, searchKeyword]
    );

    useEffect(() => {
        query.query && setSearchKeyword(query.query as string);
    }, [query, setSearchKeyword]);

    const handleChange = useCallback((e) => setSearchKeyword(e.target.value), [setSearchKeyword]);

    return (
        <Search onSubmit={onSubmit} searchValue={searchKeyword} onChange={handleChange} onClear={resetSearchKeyword} />
    );
};
