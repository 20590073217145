import React, { createContext } from 'react';

export const DropDownContext = createContext({
    opened: false,
});

export const useDropDownContext = () => {
    const context = React.useContext(DropDownContext);
    if (!context) {
        throw new Error(
            'DropDown compound components cannot be rendered outside the DropDown component'
        );
    }
    return context;
};
