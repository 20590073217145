import { ICollectionsListFilter, RequestPagerI } from '@tacans/shared-marketplace-layer';
import {
    createCollectionsFilterFieldsPayload,
    mapCollectionsSorting,
} from 'src/features/common/explore/collections-api.utils';
import { CollectionsSort } from 'src/features/common/explore/ducks/collections/collections.types';

export const useCreateCollectionsRequestPayload = ({
    sort,
    filter,
    pager,
}: {
    sort: CollectionsSort;
    filter: ICollectionsListFilter;
    pager?: RequestPagerI;
}) => ({
    sorting: mapCollectionsSorting(sort),
    filter: createCollectionsFilterFieldsPayload(filter),
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});
