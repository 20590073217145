import { RefObject, useCallback, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'src/utils/helpers.utils';

export interface IContentRect {
    left: number;
    top: number;
    width: number;
    height: number;
}

type Callback = (arg0: IContentRect) => void;

const useResizeObserver = (cb: Callback, refference: RefObject<any>, debounceTime: number) => {
    const debouncedCallback = useCallback(debounce(cb, debounceTime), [cb]);
    useEffect(() => {
        const ref = refference;
        const observer = new ResizeObserver((entries) => {
            const { left, top, width, height } = entries[0].contentRect;
            debouncedCallback({ left, top, width, height });
        });
        ref.current && observer.observe(ref.current);
        return () => ref.current && observer.unobserve(ref.current);
    });
};

export default useResizeObserver;