import { RequestPagerI, IUsersListFilter, IUser } from '@tacans/shared-marketplace-layer';

export interface IUsersFilter {
    filter: IUsersListFilter;
    sorting: UsersSort;
    pager: RequestPagerI;
}

export enum UsersSort {
    LATEST = 'latest',
    OLDEST = 'oldest',
    NAME_ASC = 'name_asc',
    NAME_DESC = 'name_desc',
}


export interface IUsersState extends IUsersFilter {
    loading: boolean;
    total: number;
    /**
     *  when infinite scroll disabled - prevent loading new, set limited total
     */
    pages: number[];
    byPage: Record<number, IUser['id'][]>
}
