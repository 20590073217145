import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { RouteE } from 'src/constants/pages.constants';
import { useUniverseMine, useUniverseMineLoading } from 'src/features/common/universe/hooks/universe-mine.hooks';
import { useUniverse } from 'src/features/common/universe/hooks/use-universe';
import { useScreenDetect } from 'src/hooks/useScreenDetect';
import { Button } from 'src/ui/common/atoms/button';

export const CreateNftStoreBtn = () => {
    const { t } = useTranslation('common');
    const universeMineLoading = useUniverseMineLoading();
    const universeMine = useUniverseMine();
    const { isUniverse } = useUniverse();
    const { xs } = useScreenDetect();

    if (isUniverse || universeMine || universeMineLoading) {
        return null;
    }

    const label = xs ? t('header.menu.createUniverseShort') : t('header.menu.createUniverse');

    return (
        <Link href={RouteE.UNIVERSE_WIZARD} prefetch={false}>
            <a target="_blank" rel="canonical">
                <Button variant="grey">
                    <span>{label}</span>
                </Button>
            </a>
        </Link>
    );
};
