// eslint-disable-next-line no-restricted-imports
import React from 'react';
import { Logo } from 'src/features/white-labels/techcontemporary/app/components/logo';
import { DesktopLinks } from './desktop-links';
import { StyledHeader } from './header.styles';
import { UserProfile } from './user-profile';

export const Header = () => (
    <StyledHeader alignItems="center" data-test-id="header">
        <Logo />
        <DesktopLinks />
        <UserProfile />
    </StyledHeader>
);