import type { StompSubscription } from '@stomp/stompjs';
import { StompClient, StompMessage } from '../stomp';
import { ITransaction, IRegistryTransaction, ITransactionStatusE } from './types';

const DEFAULT_TTL = 5 * 60 * 1000; // in ms

class TransactionsRegistry {
    private transactions: IRegistryTransaction[] = [];

    private listeners: any[] = [];

    private subscriptions: Map<string, StompSubscription> = new Map<string, StompSubscription>();

    stompCallback = (message: StompMessage) => {
        try {
            const { nft_id: tokenId, transaction_hash: trHash } = JSON.parse(message.body);
            if (trHash) {
                transactionsRegistry.updateStatusByTrHash(
                    trHash,
                    ITransactionStatusE.Success,
                );
            } else {
                transactionsRegistry.updateStatusByTokenId(
                    String(tokenId),
                    ITransactionStatusE.Success,
                );
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Cant parse msg body', message, err);
        }
    };

    rejectByTTL = (id: number) => {
        const index = this.transactions.findIndex(el => el.id === id);
        const transaction = this.transactions[index];
        const channel = `nft.${transaction.token.tokenId}`;
        const aliveTransaction = this.transactions.find(transaction =>
            transaction.status !== ITransactionStatusE.Success &&
            transaction.status !== ITransactionStatusE.RejectedByTTL
        );

        if (!aliveTransaction) {
            this.subscriptions.get(channel)?.unsubscribe();
        }

        if (transaction && transaction.status !== ITransactionStatusE.Success) {
            transaction.updateStatus(ITransactionStatusE.RejectedByTTL);
            const { token, lot, ...rest } = transaction;
            throw new Error(`TransactionRejected by TTL ${JSON.stringify({
                ...rest,
                tokenId: token?.id,
                lotId: lot?.id
            })}`);
        }
    }

    add(transaction: ITransaction): number {
        const id = Date.now();
        const ttlToReject = transaction.ttlToReject || DEFAULT_TTL;
        const channel = `nft.${transaction.token.tokenId}`;
        this.transactions.push({
            ...transaction,
            id,
            ttlToReject: transaction.ttlToReject || DEFAULT_TTL,
            status: transaction.status || ITransactionStatusE.Pending,
            remove: () => this.remove(id),
            updateStatus: (status: ITransactionStatusE) => this.updateStatus(id, status)
        });

        StompClient.subscribe(channel, this.stompCallback)
            .then(subscription=>this.subscriptions.set(channel, subscription))
            // eslint-disable-next-line no-console
            .catch((reason) => console.warn(reason));

        setTimeout(() => this.rejectByTTL(id), ttlToReject);

        this.emit();

        return id;
    }

    updateStatus(id: number, status: ITransactionStatusE) {
        const index = this.transactions.findIndex(el => el.id === id);
        const transaction = this.transactions[index];

        if (transaction && transaction.status !== ITransactionStatusE.Success) {
            const newTransactions = [...this.transactions];
            newTransactions[index].status = status;
            this.transactions = newTransactions;
            this.emit();
        }
    }

    updateStatusByTokenId(tokenId: string, status: ITransactionStatusE) {
        const transaction = this.transactions.find(el => el.token.tokenId === tokenId);
        if (transaction) {
            this.updateStatus(transaction.id, status);
        }
    }

    updateStatusByTrHash(trHash: string, status: ITransactionStatusE) {
        const transaction = this.transactions.find(el => el.txHash === trHash);
        if (transaction) {
            this.updateStatus(transaction.id, status);
        }
    }

    remove(id: number) {
        const index = this.transactions.findIndex(el => el.id === id);
        const transaction = this.transactions[index];
        if(!transaction) {
            return;
        }
        const newTransactions = [...this.transactions];
        newTransactions[index].isDeleted = true;
        this.transactions = newTransactions;
        this.emit();
    }

    emit() {
        this.listeners.forEach((cb) => cb([...this.transactions]));
    }

    subscribe(cb: any) {
        this.listeners.push(cb);
    }

    unsubscribe(cb: any) {
        this.listeners = this.listeners.filter(listener => listener !== cb);
    }

    getAll(): IRegistryTransaction[] {
        return this.transactions;
    }
}

const transactionsRegistry = new TransactionsRegistry();

export default transactionsRegistry;
