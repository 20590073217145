import { ESortEventBy, IListEventFilter } from '@tacans/shared-marketplace-layer';
import { EventsSort, IEventsFilters } from './ducks/calendar.types';


export const createFilterFieldsPayload = (meta: IEventsFilters): IListEventFilter => {
    const fields: IListEventFilter  = {};

    meta = meta || {};

    if (meta.names) {
        fields.names = meta.names;
    }

    if (meta.locations) {
        fields.locations = meta.locations;
    }

    if (meta.dateFrom) {
        fields.dateFrom = meta.dateFrom;
    }

    if (meta.dateTo) {
        fields.dateTo = meta.dateTo;
    }

    return fields;
};

export const mapSorting = (sort: EventsSort | null) => {
    switch(sort) {
        case EventsSort.NAME:
            return {
                sortBy: ESortEventBy.Name,
                ascending: true
            };
        case EventsSort.LOCATION:
            return {
                sortBy: ESortEventBy.Location, 
                ascending: true
            };
        default: 
            return {
                sortBy: ESortEventBy.Date,
                ascending: false
            };
    }
};