import { createSlice } from '@reduxjs/toolkit';
import { AuthTokenCookieService } from '@tacans/shared-marketplace-layer';

import { setNewPasswordThunk } from 'src/features/common/auth/ducks/auth/auth.thunk';
import { UserAdapter } from './user.adapter';
import {
    deleteUserThunk,
    loginUserThunk,
    logoutUserThunk,
} from './user.thunk';

interface UserStateI {
    loaded?: boolean;
    loggedIn?: boolean;
    loggingIn: boolean;
    accountDeleted?: boolean;
    error?: string;
    newPasswordSet?: boolean;
}

const getEmptyState = () => UserAdapter.getInitialState<UserStateI>({
    error: '',
    loaded: false,
    loggingIn: false
});

const initialState = UserAdapter.getInitialState<UserStateI>({
    loggedIn: !!AuthTokenCookieService.getToken('access') || !!AuthTokenCookieService.getToken('refresh'),
    loaded: false,
    loggingIn: false
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUser: () => getEmptyState(),
        clearError: (state) => ({
            ...state,
            error: '',
        }),
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUserThunk.pending, (state) => {
                state.loggingIn = true;
            })
            .addCase(loginUserThunk.fulfilled, (state) => {
                state.loggingIn = false;
                state.loggedIn = true;
            })
            .addCase(loginUserThunk.rejected, (state, action) => {
                state.loggingIn = false;
                // @ts-ignore
                state.error = action.payload.error;
            })
            .addCase(logoutUserThunk.fulfilled, (state) => {
                UserAdapter.removeAll(state);
                state.loggedIn = false;
            })
            .addCase(setNewPasswordThunk.fulfilled, (state) => {
                state.newPasswordSet = true;
            })
            .addCase(setNewPasswordThunk.rejected, (state, { payload }) => {
                state.error = payload as string;
            })
            .addCase(deleteUserThunk.pending, (state) => {
                state.error = '';
            })
            .addCase(deleteUserThunk.rejected, (state, { payload }) => {
                state.error = payload as string;
            })
            .addCase(deleteUserThunk.fulfilled, (state) => {
                state.accountDeleted = true;
            });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = userSlice;

export default userSlice.reducer;
