import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserAPI, NftAPI, SearchTypeE, CollectionAPI, ISearch } from '@tacans/shared-marketplace-layer';

interface SearchFetchI {
    data: ISearch;
    type: SearchTypeE;
}

export const searchFetchThunk = createAsyncThunk(
    'search/fetch',
    async ({ data, type }: SearchFetchI, { rejectWithValue }) => {
        let apiQuery = null;

        switch (type) {
            case SearchTypeE.User:
                apiQuery = UserAPI.search;
                break;
            case SearchTypeE.Collection:
                apiQuery = CollectionAPI.search;
                break;
            default:
                apiQuery = NftAPI.search;
        }
        const response = await apiQuery(data as ISearch);

        if (response?.data?.responseMeta?.error) {
            return rejectWithValue(response?.data?.responseMeta.error.messages[0]);
        }

        return response;
    }
);
