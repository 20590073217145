import { IS_SSR } from 'src/utils/helpers.utils';

interface ValidateImageI {
    url: string;
    cb: (er?: any) => void;
}

export const sizeToBytes = (val: string) => {
    const [n, abbreviation] = val.split(/\s+/);

    const size = parseInt(n, 10);

    const printPower = (n: number, power: number) => (n * Math.pow(1024, power));

    switch (abbreviation) {
        case 'Kb':
            return printPower(size, 1);
        case 'Mb':
            return printPower(size, 2);
        case 'Gb':
            return printPower(size, 3);
        case 'Tb':
            return printPower(size, 4);
        default:
            return size;
    }
};

export const bytesToSize = (bytes: number, decimals = 2): string => {
    if (!bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const validateImage = ({ url, cb }: ValidateImageI) => {
    const img = new Image();
    if (!url) {
        cb(new Error('no image'));
        return;
    }

    img.src = url;
    img.onload = () => cb();
    img.onerror = () => cb(new Error('image loading failed'));
};

export function readImageFromFile(
    { file, onLoadCallBack, onProgressCallBack }: {
        file: File; onLoadCallBack: Function; onProgressCallBack?: (progressPercent: number) => void;
    }
): Promise<void> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            try {
                onProgressCallBack && onProgressCallBack(100);
                onLoadCallBack(e.target?.result);
                resolve();
            } catch (error) {
                onLoadCallBack(null);
                reject();
            }
        };
        if (onProgressCallBack) {
            reader.onprogress = ({ total, loaded }: ProgressEvent) => {
                const progressPercent = total === loaded ? 99 : Math.floor(loaded * 100 / total);
                onProgressCallBack(progressPercent);
            };
        }
    });
}

export function loadScript(src: string) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.onload = resolve;
        script.src = src;

        document.head.appendChild(script);
    });
}

export function isWebpSupported() {
    if (IS_SSR) {
        return true;
    }
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
}

export function readFileAsText(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve,reject) => {
        const fileredr = new FileReader();
        fileredr.onload = () => resolve(fileredr.result);
        fileredr.onerror = () => reject(fileredr);
        fileredr.readAsText(file);
    });
}
