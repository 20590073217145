import { MutableRefObject, useEffect, useRef } from 'react';

export function useDebouncedEffect(callback: Function, delay: number, deps: Array<any> = []) {
    const data: MutableRefObject<{firstTime: boolean, clearFunc?: Function}> = useRef({ firstTime: true });

    useEffect(() => {
        const { firstTime, clearFunc } = data.current;

        if (firstTime) {
            data.current.firstTime = false;
            return;
        }

        const handler = setTimeout(() => {
            if (clearFunc && typeof clearFunc === 'function') {
                clearFunc();
            }
            data.current.clearFunc = callback();
        }, delay);

        // eslint-disable-next-line consistent-return
        return () => {
            clearTimeout(handler);
        };
    }, [delay, ...deps],);
}
