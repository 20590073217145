import { createAsyncThunk } from '@reduxjs/toolkit';
import { ReferralApi } from '@tacans/shared-marketplace-layer';
import { RootState } from 'src/store';
import { useGetTransactionsRequestPayload } from '../../hooks/use-get-transactions-request-payload';
import { selectPager } from './get-transactions.selectors';

export const fetchTransactionsThunk = createAsyncThunk(
    'get-transactions/fetch',
    async (referralLink: string, thunkApi) => {
        const rootState = thunkApi.getState() as RootState;
        const pager = selectPager(rootState);

        const payload = useGetTransactionsRequestPayload({
            referralLink,
            pager: {
                page: pager?.page ?? 0,
                limit: 100,
            },
        });
        const { data } = await ReferralApi.fetchTransactions(payload);
        return data;
    }
);