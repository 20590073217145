import styled  from 'styled-components';
import { ISvgIcon } from 'src/ui/common/atoms/svg-icon/svg-icon.types';

export const StyledSvg = styled.svg<ISvgIcon>`
  color: ${({ theme, color }) => color || theme.colors.text};
  flex: ${({ width }) => `0 0 ${width}px`};
  
  &:hover {
    color: ${({ theme, color }) => color || theme.colors.text}
  }
`;

