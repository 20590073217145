import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NFTI } from '@tacans/shared-marketplace-layer';

import { NftSaleDetails } from 'src/features/common/collectible/collectible.typing';
import { NftSalesAdapter } from './nft-sales.adapter';
import { getNftSalesThunk } from './nft-sales.thunk';

export const nftSalesSlice = createSlice({
    name: 'nft-sales-slice',
    initialState: NftSalesAdapter.getInitialState(),
    reducers: {
        clear: () => NftSalesAdapter.getInitialState(),
        startFetching: (state, action: PayloadAction<NFTI['id'][]>) => {
            const dataToSet: NftSaleDetails[] = action.payload
                .filter(nftId => !state.entities[nftId])
                .map((nftId) => ({
                    nftId,
                    status: 'fetching',
                    isOnSale: false,
                    copiesOnSale: 0,
                    activeLotsCount: 0,
                    nanoPrice: 0,
                }));
            NftSalesAdapter.setMany(state, dataToSet);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNftSalesThunk.fulfilled, (state, action) => {
                NftSalesAdapter.setMany(state, action.payload.map(item => ({
                    ...item,
                    status: 'completed',
                })));
            });
    },
});

export const { actions } = nftSalesSlice;

export default nftSalesSlice.reducer;
