import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDevicePixelRatio } from 'src/hooks/use-device-pixel-ratio';
import { useIsMounted } from 'src/hooks/useIsMounted';
import { StyledImage, StyledRatio, StyledWrapper, VideoInnerWrapper } from './lazy-load-image.styles';
import { IProps } from './lazy-load-image.types';

export const LazyLoadMedia: React.VFC<IProps> = ({
    onContextMenu,
    src,
    retinaSrc,
    videoSrc,
    retinaVideoSrc,
    width,
    height,
    alt,
    className,
    ratio,
}) => {
    const inView = useInView();
    const isMounted = useIsMounted();
    const [loaded, setLoaded] = useState(false);
    const devicePixelRatio = useDevicePixelRatio();

    const srcSet = src && retinaSrc ? `${src} 1x, ${retinaSrc} 2x` : undefined;

    const video = devicePixelRatio > 1 && retinaVideoSrc ? retinaVideoSrc : videoSrc;

    useEffect(() => {
        if (!loaded && inView && src && !videoSrc) {
            const img = new Image();
            img.src = src;
            img.onload = () => isMounted(() => setLoaded(true));
        }
    }, [videoSrc, inView, isMounted, loaded, src]);

    return (
        <StyledWrapper style={{ width, height }} className={className}>
            <StyledRatio ratio={ratio} />
            {inView && loaded && !video && (
                <StyledImage
                    onContextMenu={onContextMenu}
                    src={src}
                    srcSet={srcSet}
                    alt={alt}
                    width={width}
                    height={height}
                />
            )}
            {inView && video && (
                <VideoInnerWrapper>
                    <video
                        muted
                        onContextMenu={onContextMenu}
                        autoPlay
                        src={video}
                        playsInline
                        loop
                        controls={false}
                        controlsList="nodownload"
                    >
                        <source src={video} type="video/mp4" />
                        <track kind="captions" srcLang="en" label="english_captions" />
                    </video>
                </VideoInnerWrapper>
            )}
        </StyledWrapper>
    );
};