import styled from 'styled-components';
import { media } from 'src/ui/common/mixins/media';

export const StyledGrid = styled.div<{
  rowGap?: number;
  rowGapSm?: number;
  rowGapMd?: number;
  rowGapLg?: number;
  rowGapXl?: number;
  columnGap?: number;
  columnGapSm?: number;
  columnGapMd?: number;
  columnGapLg?: number;
  columnGapXl?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}>`
  width: 100%;
  display: grid;

  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px`};

  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px`};
   
  ${({ xs }) => xs && media.xs`
    grid-template-columns: repeat(${xs}, 1fr);
  `};

  ${({ rowGapSm }) => rowGapSm && media.sm`grid-row-gap: ${rowGapSm}px`};
  ${({ rowGapMd }) => rowGapMd && media.md`grid-row-gap: ${rowGapMd}px`};
  ${({ rowGapLg }) => rowGapLg && media.lg`grid-row-gap: ${rowGapLg}px`};
  ${({ rowGapXl }) => rowGapXl && media.xl`grid-row-gap: ${rowGapXl}px`};
  
  ${({ columnGapSm }) => columnGapSm && media.sm`grid-column-gap: ${columnGapSm}px`};
  ${({ columnGapMd }) => columnGapMd && media.md`grid-column-gap: ${columnGapMd}px`};
  ${({ columnGapLg }) => columnGapLg && media.lg`grid-column-gap: ${columnGapLg}px`};
  ${({ columnGapXl }) => columnGapXl && media.xl`grid-column-gap: ${columnGapXl}px`};
  
  ${({ sm }) => sm && media.sm`
    grid-template-columns: repeat(${sm}, 1fr);
  `};

  ${({ md }) => md && media.md`
    grid-template-columns: repeat(${md}, 1fr);
  `};

  ${({ lg }) => lg && media.lg`
    grid-template-columns: repeat(${lg}, 1fr);
  `};

  ${({ xl }) => xl && media.xl`
    grid-template-columns: repeat(${xl}, 1fr);
  `};
`;
