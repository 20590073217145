import dynamic from 'next/dynamic';
import { modalRegistry } from 'src/features/common/modal/providers/modal.registry';
import { ConnectWalletModalProps } from './connect-wallet-modal.types';

const ConnectWalletModalComponent = dynamic(() => import('./connect-wallet-modal'), {
    ssr: false,
});

export const ConnectWalletModal = modalRegistry.register<ConnectWalletModalProps>({
    id: 'ConnectWallet',
    component: ConnectWalletModalComponent,
});
