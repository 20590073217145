import React, { useMemo } from 'react';
import { useActiveAuctionsHooks } from 'src/features/common/notifications/hooks/use-active-auctions.hooks';
import { NotificationsContext } from 'src/features/common/notifications/notifications.context';

export const NotificationsContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { count } = useActiveAuctionsHooks();

    const contextValue = useMemo(() => ({ activeAuctionsCount: count }), [count]);

    return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};
