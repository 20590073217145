import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import type { IGetWallet, IUser } from '@tacans/shared-marketplace-layer';

import { fetchUsersByWalletsThunk, fetchUsersByIdsThunk } from './user-list.thunk';

type State = Record<string, IUser>;

const INITIAL_STATE = {} as State;

export const userListSlice = createSlice({
    name: 'user-list',
    initialState: INITIAL_STATE,
    reducers: {
        startFetchingByWallets: (state, action: PayloadAction<IGetWallet[]>) => {
            const dataToSet = action.payload
                .filter(wallet => !state[wallet.address])
                .reduce((acum, wallet) => {
                    // @ts-ignore
                    acum[wallet.address] = { status: 'fetching' };
                    return acum;
                }, {} as State);
            state = { ...state, ...dataToSet };
        },
        startFetchingByUserIds: (state, action: PayloadAction<IUser['id'][]>) => {
            const dataToSet = action.payload
                .filter(id => !state[id])
                .reduce((acum, id) => {
                    // @ts-ignore
                    acum[id] = { status: 'fetching' };
                    return acum;
                }, {} as State);
            state = { ...state, ...dataToSet };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.global.userList,
            }))
            .addCase(fetchUsersByIdsThunk.fulfilled, (state, action) => {
                action.payload?.forEach(item => {
                    state[item.id] = item;
                });
            })
            .addCase(fetchUsersByWalletsThunk.fulfilled, (state, action) => {
                action.payload?.forEach(item => {
                    state[item.wallet.address] = item.user;
                });
            });
    },
});

// Action creators are generated for each case reducer function
export const { actions } = userListSlice;

export default userListSlice.reducer;
