import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { artFetchThunk } from 'src/features/white-labels/techcontemporary/collectible/ducks/collectible/collectible.thunk';
import { ICollectibleState } from './collection.types';

const INITIAL_STATE: ICollectibleState = {
    art: null,
    created: false,
    error: '',
};

export const artCollectibleSlice = createSlice({
    name: 'collectible',
    initialState: INITIAL_STATE,
    reducers: {
        clearCollectible: () => INITIAL_STATE,
        errorClear: (state) => {
            state.error = '';
        },
        setCollectible: (state, action) => {
            state.art = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.arts.art,
            }))
            .addCase(artFetchThunk.fulfilled, (state, action) => {
                if (action.payload.digitalArt) {
                    state.art = action.payload.digitalArt;
                }
            });
    },
});

export const { actions } = artCollectibleSlice;

export default artCollectibleSlice.reducer;