import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export const userSelect = (state: RootState) => state.global.user;

export const selectUserLoggedIn = createSelector(userSelect, (user) => user.loggedIn);

export const selectUserError = createSelector(userSelect, (user) => user.error);

export const selectNewPasswordSet = createSelector(userSelect, (user) => user.newPasswordSet);

export const selectUserDeleted = createSelector(userSelect, (user) => user.accountDeleted);

export const selectUserLoggingIn = createSelector(userSelect, (user) => user.loggingIn);
