import React, { useMemo } from 'react';
import { useImageUrlCheck } from 'src/hooks/useImageUrlCheck';
import { LazyLoadMedia } from 'src/ui/common/organisms/lazy-load-media';
import { StyledAvatar, StyledName } from './avatar.styles';
import { IAvatar } from './avatar.types';

export const Avatar: React.VFC<IAvatar> = ({ onClick, name, src, size, variant, className, borderRadius }) => {
    const url = useImageUrlCheck({ imageUrl: src });

    const content = useMemo(() => {
        if (url) {
            return <LazyLoadMedia src={url} width={size} height={size} alt={name} />;
        }
        if (name) {
            const parts = name.split(/(\s+)/);
            const initials = parts.map((w) => (w ? w[0].trim() : ''));
            return <StyledName size={size}>{initials.join('')}</StyledName>;
        }
        return null;
    }, [name, size, url]);

    return (
        <StyledAvatar
            onClick={onClick}
            variant={variant}
            borderRadius={borderRadius}
            size={size}
            alignItems="center"
            justifyContent="center"
            className={className}
        >
            {content}
        </StyledAvatar>
    );
};
