import { AnalyticsType, Subscriber } from 'src/services/analytics/analytics.types';
import dataLayerActions from 'src/services/analytics/data-layer.actions';
import { IS_SSR } from 'src/utils/helpers.utils';

export const SUBSCRIBERS_BY_TYPE: Record<AnalyticsType, Subscriber[]> = {
    [AnalyticsType.BROADCAST_USER]: [dataLayerActions.broadcastUser],
    [AnalyticsType.NFT_VIEW]: [],
    [AnalyticsType.NFT_BUYING]: [dataLayerActions.logBuyFixedClick],
    [AnalyticsType.NFT_CLICK]: [dataLayerActions.logNftClick],
    [AnalyticsType.LOT_PLACE_BID]: [dataLayerActions.logPlaceBid],
    [AnalyticsType.CONTACT_SELLER]: [dataLayerActions.logContactSeller],
    [AnalyticsType.NFT_SUCCESS_CREATE]: [dataLayerActions.logSuccessCreateNft]
};

export const logger = (str: string, ...rest: any) => {
    if (!IS_SSR && localStorage.getItem('debug')) {
        console.info(`%c ${str}`, 'background: #222; color: #bada55', ...rest);
    }
};
