import styled from 'styled-components';
import { speed } from 'src/ui/common/config/animations';

export const StyledAccordionHeader = styled.div<{ isOpened: boolean }>`
    cursor: pointer;
    padding: 35px 0;
    size: 20px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.fonts.text};
    color: ${({ theme }) => theme.colors.text};
`;

export const StyledAccordionContent = styled.div<{ isOpened: boolean }>`
    position: relative;
    transition: max-height ${speed.content} ease 0s;
    max-height: 1px;
    overflow: hidden;

    ${({ isOpened }) => isOpened && 'max-height: 100vh'};

    &::after {
        content: "";
        width: 100%;
        height: 1px;
        display: block;
        background: ${({ isOpened, theme }) => isOpened ? 'linear-gradient(to left, #FF77FC, #FFE0D9)' : theme.colors.gs400};
        position: absolute;
        bottom: 0;
    }  

    [data-scrollable] {
        max-height: 100vh;
        overflow-y: auto;
    }
`;

export const StyledContentWrapper = styled.div`
    padding-bottom: 35px;
    size: 20px;
    line-height: 24px;
    font-family: ${({ theme }) => theme.fonts.text};
    color: ${({ theme }) => theme.colors.gs200};
`;
