import { AnalyticsType, IBroadCastUser } from 'src/services/analytics/analytics.types';
import { SUBSCRIBERS_BY_TYPE } from 'src/services/analytics/analytics.config';

const sendAnalytics = (type: AnalyticsType, payload?: any) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    const subscribers = SUBSCRIBERS_BY_TYPE[type];
    subscribers.forEach(sb => {
        try {
            sb(payload);
        } catch (err) {
            console.warn('sendAnalytics', type, err);
        }
    });
};

export const broadcastUser = (payload: IBroadCastUser) => sendAnalytics(AnalyticsType.BROADCAST_USER, { ...payload });

export const logNftClick = (payload: any) => sendAnalytics(AnalyticsType.NFT_CLICK, { ...payload });

export const logBuyingFixed = (payload: any) => sendAnalytics(AnalyticsType.NFT_BUYING, { ...payload });

export const logPlaceBid = (payload: any) => sendAnalytics(AnalyticsType.LOT_PLACE_BID, { ...payload });

export const logContactSeller = () => sendAnalytics(AnalyticsType.CONTACT_SELLER);

export const logSuccessCreateNft = (payload: any) => sendAnalytics(AnalyticsType.NFT_SUCCESS_CREATE, { ...payload });
