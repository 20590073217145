import { ILaunchpadsFilter } from '@tacans/shared-marketplace-layer';
import { useCallback, useMemo } from 'react';
import {
    selectByPage,
    selectFilter,
    selectPages,
    selectSorting,
    selectTotal,
    selectLoading,
    selectLaunchpads,
    selectLaunchpadsSelectEntities,
    selectPager,
    selectExploreTotal,
} from 'src/features/common/explore/ducks/launchpads/launchpads.selectors';
import { fetchLaunchpadsThunk } from 'src/features/common/explore/ducks/launchpads/launchpads.thunk';
import { LaunchpadsSort } from 'src/features/common/explore/ducks/launchpads/launchpads.types';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import { actions } from '../ducks/launchpads/launchpads.slice';

export const useSetPage = () => {
    const dispatch = useAppDispatch();
    return useCallback((page: number) => dispatch(actions.setPage(page)), [dispatch]);
};

export const useIsFiltersApplied = () => {
    const filters = useFilters();
    return useMemo<boolean>(() => !filters.ownerId, [filters]);
};

export const useIsSomeFilterApplied = () => {
    const filters = useFilters();
    const isFiltersApplied = useIsFiltersApplied();

    return useMemo<boolean>(() => isFiltersApplied || !!filters.keywords, [filters, isFiltersApplied]);
};

export const useSetFilters = () => {
    const dispatch = useAppDispatch();
    return useCallback((filters: ILaunchpadsFilter) => dispatch(actions.setFilter(filters)), [dispatch]);
};

export const useUpdateFilters = () => {
    const dispatch = useAppDispatch();
    return useCallback((filters: ILaunchpadsFilter) => dispatch(actions.updateFilter(filters)), [dispatch]);
};

export const useClearItems = () => {
    const dispatch = useAppDispatch();
    return useCallback(() => dispatch(actions.clearItems()), [dispatch]);
};

export const useSetSorting = () => {
    const dispatch = useAppDispatch();
    return useCallback((sorting: LaunchpadsSort) => dispatch(actions.setSorting(sorting)), [dispatch]);
};

export const useFetchLaunchpads = () => {
    const dispatch = useAppDispatch();

    return useCallback(async () => {
        try {
            dispatch(actions.setLoading(true));
            const res = await dispatch(fetchLaunchpadsThunk());
            return res;
        } finally {
            dispatch(actions.setLoading(false));
        }
    }, [dispatch]);
};

export const useFilters = () => useAppSelector(selectFilter);
export const useExploreLaunchpads = () => useAppSelector(selectLaunchpads);
export const useLaunchpadsEntities = () => useAppSelector(selectLaunchpadsSelectEntities);
export const usePages = () => useAppSelector(selectPages);
export const useLaunchpadsByPage = () => useAppSelector(selectByPage);
export const useLoading = () => useAppSelector(selectLoading);
export const useTotal = () => useAppSelector(selectTotal);
export const useExploreTotal = () => useAppSelector(selectExploreTotal);
export const useSorting = () => useAppSelector(selectSorting);
export const usePager = () => useAppSelector(selectPager);
