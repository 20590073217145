import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledFooter = styled.div`
    padding: 80px 10px 32px;
    background-color: ${({ theme }) => `rgba(${theme.colors.gs800rgb}, .1)`};
    position: relative;

    ${media.sm`
    padding: 80px 40px 32px;
  `}
    ${media.lg`
    padding: 112px 0 32px;
  `}
  & > div:first-child {
        padding: 0 0 48px;

        ${media.lg`
      padding: 0 0 112px;
    `}
    }

    & > div:last-child {
        border-top: 1px solid ${({ theme }) => theme.colors.gs400};
        padding: 32px 0 0;
    }

    a {
        margin: 0;
        padding: 0;
        text-align: unset;
    }
`;
