import { RequestPagerI } from '@tacans/shared-marketplace-layer';
import { EventsSort, IEventsFilters } from '../calendar.types';
import { createFilterFieldsPayload, mapSorting } from '../../events-api.utils';


export const useCreateFetchEventsRequestPayload = ({ sort, filter, pager }: {
    sort: EventsSort | null,
    filter: IEventsFilters,
    pager?: RequestPagerI,
}) => ({
    sorting: mapSorting(sort),
    filter: createFilterFieldsPayload(filter),
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});

