/**
 * Libs
 */
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { useCallback, useMemo } from 'react';

/**
 * Hooks
 */
import { useSidebar } from 'src/features/common/app/hooks/use-sidebar';

const AuctionApprovalsSidebarContent = dynamic(
    () => import('src/features/common/notifications/containers/notifications-sidebar-content'),
    { ssr: false }
);

export const useRenderNotificationsSidebar = () => {
    const { t } = useTranslation(['common']);
    const { render, opened, close } = useSidebar();

    const locale: { title: string } = useMemo(
        () => ({
            ...t('sidebar.auctionApprove', { returnObjects: true }),
        }),
        [t]
    );

    return useCallback(() => {
        opened
            ? close()
            : render(
                  locale.title,
                  // @ts-ignore
                  <AuctionApprovalsSidebarContent />
              );
    }, [close, locale.title, opened, render]);
};
