import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    UniverseAPI,
} from '@tacans/shared-marketplace-layer';

export const fetchUniverseInfoThunk = createAsyncThunk(
    'universe/get-info',
    async () => {
        const response = await UniverseAPI.getInfo();

        return response;
    }
);
