import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchAdapter } from './search.adapter';

const initialState = SearchAdapter.getInitialState({
    keywords: '',
});

export const searchSlice = createSlice({
    name: 'active-modal-frame',
    initialState,
    reducers: {
        // @ts-ignore
        setSearchKeyword: (state, action: PayloadAction<string>) => {
            state.keywords = action.payload;
        },
        resetSearchKeyword: (state) => {
            state.keywords = '';
        },
    },
});

// Action creators are generated for each case reducer function
export const { actions } = searchSlice;

export default searchSlice.reducer;
