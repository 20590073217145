import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { IGetAllReferralsState } from 'src/features/common/affiliate-program/ducks/get-all-referrals/get-all-referrals.types';
import { GetInfluencersReferralsAdapter } from './get-influencers-referrals.adapter';
import { INITIAL_FILTER_STATE } from './get-influencers-referrals.constants';
import { fetchAllInfluencerReferralsThunk } from './get-influencers-referrals.thunk';

const getInitialState = () =>
    GetInfluencersReferralsAdapter.getInitialState<IGetAllReferralsState>(INITIAL_FILTER_STATE);

export const influencersReferralsSlice = createSlice({
    name: 'get-influencers-referrals',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            GetInfluencersReferralsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
        restoreState: (state, { payload }) => {
            const pages = Array.from({ length: payload.page + 1 }).map((el, ind) => ind);

            state.total = payload.total;
            state.visibleTotal = payload.visibleTotal;
            state.pages = pages;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllInfluencerReferralsThunk.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAllInfluencerReferralsThunk.fulfilled, (state, action) => {
                const {
                    pager,
                    referrals = [],
                    totalInfluenceStatistic,
                    monthlyInfluenceStatistic,
                } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (referrals.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                referrals.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        // @ts-ignore
                        newByPage[page].push(item.id);
                    }
                });

                GetInfluencersReferralsAdapter.addMany(state, referrals || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
                state.statistic = totalInfluenceStatistic;
                state.monthStatistic = monthlyInfluenceStatistic;
                state.loading = false;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.influencerRefferals,
            }));
    },
});

export const { actions } = influencersReferralsSlice;
export default influencersReferralsSlice.reducer;