import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import {
  categoryFetchThunk,
  selectCategories
} from '../ducks/category';

export const useCategoriesFetch = () => {
  const categories = useCategories();
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (!categories.length) {
      dispatch(categoryFetchThunk());
    }
  }, [categories.length, dispatch]);
};

export const useCategories = () => useAppSelector(selectCategories);
