import { ReactNode, useCallback, useContext } from 'react';
import { SidebarContext } from 'src/features/common/app/components/sidebar/sidebar.context';

export const useSidebar = () => {
    const { content, setContent, title, setTitle } = useContext(SidebarContext);

    const close = useCallback(() => {
        setContent(null);
    }, [setContent]);

    const render = useCallback((title: string | ReactNode, node: ReactNode) => {
        setTitle(title);
        setContent(node);
    }, [setContent, setTitle]);

    const opened = !!content;

    return {
        opened,
        content,
        close,
        render,
        title
    };
};
