import React, { useCallback } from 'react';
import { useGetUniverseTemplate } from 'src/hooks/use-get-universe-template';
import { StyledInput as OceanStyledInput } from 'src/ui/white-labels/ocean/atoms/text-input/text-input.styles';
import { StyledInput } from './text-input.styles';
import { ITextInput } from './text-input.types';

export const TextInput = React.forwardRef<HTMLInputElement, ITextInput>((props, ref) => {
    const { isOceanTemplate } = useGetUniverseTemplate();
    const handlePriceKeyPress = useCallback((e) => {
        if (!/[0-9.]/.test(e.key)) {
            e.preventDefault();
        }
    }, []);

    const preventScroll = useCallback((e) => {
        (e.target as HTMLElement).blur();
    }, []);

    if (isOceanTemplate) {
        return (
            <OceanStyledInput
                ref={ref}
                onWheel={props.type === 'number' ? preventScroll : undefined}
                onKeyPress={props.type === 'number' ? handlePriceKeyPress : undefined}
                {...props}
            />
        );
    }

    return (
        <StyledInput
            ref={ref}
            onWheel={props.type === 'number' ? preventScroll : undefined}
            onKeyPress={props.type === 'number' ? handlePriceKeyPress : undefined}
            {...props}
        />
    );
});

TextInput.displayName = 'TextInput';