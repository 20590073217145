import { IUniversesState, UniversesSort } from './universes.types';

export const INITIAL_FILTER_STATE: IUniversesState = {
    filter: {
        keywords: ''
    },
    sorting: UniversesSort.LATEST,
    pager: {
        page: 0,
        limit: 20,
    },
    total: 0,
    loading: false,
    pages: [],
    byPage: {}
};
