import { useMemo } from 'react';
import { IUser } from '@tacans/shared-marketplace-layer';

export const getUserDisplayName = (user?: IUser, showNameFirst?: boolean ): string => {
    if (!user) {
        return '';
    }
    if (user.displayName && !showNameFirst) {
        return user.displayName;
    }
    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
    }
    if (user.firstName) {
        return user.firstName;
    }
    if (user.lastName) {
        return user.lastName;
    }
    if (user.displayName && showNameFirst) {
        return user.displayName;
    }
    return user.login;
};

export const useDisplayName = (user?: IUser | null, showNameFirst?: boolean) => useMemo(
    (): string => user ? getUserDisplayName(user, showNameFirst) : '',
    [user, showNameFirst]
);
