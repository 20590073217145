import { ICollectionsListSorting, RequestPagerI, UniversesFilterRequest } from '@tacans/shared-marketplace-layer';
import { UniversesSort } from 'src/features/common/explore/ducks/universes/universes.types';

export const createUniversesFilterFieldsPayload = (meta: UniversesFilterRequest = {}): UniversesFilterRequest => {
    const fields: UniversesFilterRequest = {};

    if (meta.keywords) {
        fields.keywords = meta.keywords.trim();
    }

    return fields;
};

export const mapUniversesSorting = (sort: UniversesSort): ICollectionsListSorting => {
    switch (sort) {
        case UniversesSort.LATEST:
            return {
                sortBy: 'Date',
                ascending: false,
            };
        case UniversesSort.OLDEST:
            return {
                sortBy: 'Date',
                ascending: true,
            };
        case UniversesSort.NAME_ASC:
            return {
                sortBy: 'Name',
                ascending: true,
            };
        case UniversesSort.NAME_DESC:
            return {
                sortBy: 'Name',
                ascending: false,
            };
        default:
            return {
                sortBy: 'Date',
                ascending: false,
            };
    }
};

export const useCreateUniversesRequestPayload = ({
    sort,
    filter,
    pager,
}: {
    sort: UniversesSort;
    filter: UniversesFilterRequest;
    pager?: RequestPagerI;
}) => ({
    sorting: mapUniversesSorting(sort),
    filter: createUniversesFilterFieldsPayload(filter),
    pager: {
        page: pager?.page ?? 0,
        limit: pager?.limit ?? 20,
    },
});
