import React, { useCallback, useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRouter } from 'next/router';

import { useSidebar } from 'src/features/common/app/hooks/use-sidebar';
import { Typography } from 'src/ui/common/atoms/typography';
import { StyledClose, StyledContentWrapper, StyledOverlay, StyledTitle, StyledWrapper } from './sidebar.styles';

export const SideBar: React.VFC = () => {
    const timerRef = useRef<any>();
    const router = useRouter();
    const [topOffset, setOffsetTop] = useState(0);
    const [opened, setOpened] = useState(false);
    const { title, content, close } = useSidebar();

    const handleClose = useCallback(() => {
        if (document.querySelector('[data-component="modal-component-wrapper"]')) {
            return;
        }
        setOpened(false);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(close, 225);
    }, [close]);

    useEffect(() => {
        if (content) {
            setOffsetTop(document?.scrollingElement?.scrollTop ?? 0);
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => setOpened(true), 225);
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
        }
    }, [content]);

    useEffect(() => {
        router.events.on('routeChangeStart', close);

        return () => {
            router.events.off('routeChangeStart', close);
        };
    }, [close, router]);

    if (!content) {
        return null;
    }

    return (
        <>
            <StyledOverlay topOffset={topOffset} opened={opened} />
            <OutsideClickHandler onOutsideClick={handleClose}>
                <StyledWrapper topOffset={topOffset} opened={opened}>
                    {title && (
                        <StyledTitle alignItems='center'>
                            {typeof title === 'string' ? (
                                <Typography variant='title' size='base'>
                                    {title}
                                </Typography>
                            ) : title}
                        </StyledTitle>
                    )}
                    <StyledClose variant='regular' icon='close' onClick={handleClose} />
                    <StyledContentWrapper>
                        {content}
                    </StyledContentWrapper>
                </StyledWrapper>
            </OutsideClickHandler>
        </>
    );
};
