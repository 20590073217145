import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    ArtistAPI,
} from '@tacans/shared-marketplace-layer';

export const artistFetchThunk = createAsyncThunk(
    'artist/fetch',
    async (artistId: string) => {
        const response = await ArtistAPI.fetchById(artistId);

        return { response };
    }
);
