import Router, { useRouter } from 'next/router';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { RouteE } from 'src/constants/pages.constants';
import { techTheme } from 'src/features/common/app/components/theme-provider/theme';
import { useUserLogout } from 'src/features/common/user/hooks/user.hooks';
import { AuthModal } from 'src/features/white-labels/techcontemporary/app/components/auth';
import {
    ProfileDropdownMenu,
    ProfileDropdownOption,
    StyledButton,
    StyledCartTotal,
    StyledIconButton,
    StyledProfileWrapper,
    StyledWrapper,
} from 'src/features/white-labels/techcontemporary/app/components/header/header.styles';
import { CartDropDown } from 'src/features/white-labels/techcontemporary/cart/components/cart-dropdown/cart-dropdown';
import {
    selectCartItems,
    selectIsCartOpen,
} from 'src/features/white-labels/techcontemporary/cart/ducks/cart.selectors';
import { actions as CartActions } from 'src/features/white-labels/techcontemporary/cart/ducks/cart.slice';
import { Spacing } from 'src/ui/common/atoms/spacing';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { Typography } from 'src/ui/common/atoms/typography';
import { Button } from 'src/ui/white-labels/techcontemporary/atoms/button';

interface IProps {
    init: boolean;
    authorized: boolean;
    isOwnerTechContemporary: boolean;
    goToCreateArtHandler: () => void;
    cartCount: number;
}

export const UserProfileDesktop: FC<IProps> = ({
    init,
    authorized,
    isOwnerTechContemporary,
    goToCreateArtHandler,
    cartCount,
}) => {
    const logout = useUserLogout();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const isCartOpen = useSelector(selectIsCartOpen);
    const cartItems = useSelector(selectCartItems);
    const router = useRouter();

    const { t } = useTranslation('tech-contemporary');
    const locale = useMemo(
        () => ({
            signIn: t('header.user-profile.sign-in', {
                ns: 'tech-contemporary',
            }),
            signOut: t('header.user-profile.sign-out', {
                ns: 'tech-contemporary',
            }),
            myProfile: t('header.user-profile.my-profile', {
                ns: 'tech-contemporary',
            }),
            profile: t('header.user-profile.profile', {
                ns: 'tech-contemporary',
            }),
            shoppingCart: t('cartMenu.shoppingCart', {
                ns: 'tech-contemporary',
            }),
            checkout: t('cartMenu.checkout', {
                ns: 'tech-contemporary',
            }),
            subtotal: t('cartMenu.subtotal', {
                ns: 'tech-contemporary',
            }),
        }),
        [t]
    );

    const handleAuthModalOpen = useCallback((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        AuthModal.open({
            type: 'Login',
            fullScreen: true,
            stickyRoot: true,
        });
    }, []);

    const onLogoutClick = useCallback(async () => {
        await logout();
        await dispatch(CartActions.setInitialCartState());
        setOpen(false);
        router.push(RouteE.MARKETPLACE);
    }, [logout]);

    const handleToggleProfile = () => {
        setOpen(!open);
    };

    const handleToggleCart = useCallback(() => {
        if (authorized && init) {
            dispatch(CartActions.setIsCartOpen(!isCartOpen));
        }
    }, [init, authorized, isCartOpen]);

    const onClickOutside = useCallback((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setOpen(false);
    }, []);

    const onClickCartOutside = useCallback(() => {
        dispatch(CartActions.setIsCartOpen(false));
    }, []);

    const handleOpenProfilePage = () => {
        Router.push('/profile/profile-information');
    };

    return (
        <>
            {init && !authorized && (
                <StyledButton size="lg" variant="white" onClick={handleAuthModalOpen}>
                    <StyledWrapper>
                        <Typography size="xs">{locale.signIn}</Typography>
                    </StyledWrapper>
                </StyledButton>
            )}
            {init && authorized && (
                <>
                    {isOwnerTechContemporary && (
                        <Spacing mr="12px">
                            <Button variant="white" size="md" onClick={goToCreateArtHandler}>
                                Create Art
                            </Button>
                        </Spacing>
                    )}

                    <StyledProfileWrapper>
                        <OutsideClickHandler onOutsideClick={onClickOutside}>
                            <StyledButton size="md" variant="text" onClick={handleToggleProfile} isIcon>
                                <SvgIcon variant="regular" icon="user-profile" />
                            </StyledButton>
                            <ProfileDropdownMenu open={open}>
                                <ProfileDropdownOption onClick={handleOpenProfilePage}>
                                    <SvgIcon color="white" variant="regular" icon="user" />
                                    <Typography size="sm">{locale.profile}</Typography>
                                </ProfileDropdownOption>
                                <ProfileDropdownOption onClick={onLogoutClick}>
                                    <SvgIcon color={techTheme.colors.red400} variant="regular" icon="logout" />
                                    <Typography size="sm">{locale.signOut}</Typography>
                                </ProfileDropdownOption>
                            </ProfileDropdownMenu>
                        </OutsideClickHandler>
                    </StyledProfileWrapper>
                    {!isOwnerTechContemporary && (
                        <OutsideClickHandler onOutsideClick={onClickCartOutside}>
                            <StyledIconButton size="md" type="button" variant="text" onClick={handleToggleCart} isIcon>
                                <SvgIcon variant="regular" icon="cart" />
                                {cartCount > 0 && init && authorized && <StyledCartTotal />}
                            </StyledIconButton>
                            <CartDropDown locale={locale} cartOpened={isCartOpen} cartItems={cartItems} />
                        </OutsideClickHandler>
                    )}
                </>
            )}
        </>
    );
};