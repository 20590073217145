import { WalletProviderE } from '@tacans/shared-marketplace-layer';
import getConfig from 'next/config';
import { useCallback } from 'react';
import { useCreateOrder } from 'src/features/common/order/hooks/order.hooks';
import { useGetDefaultWallet } from 'src/features/common/wallet/hooks/wallet.hooks';
import { useNotifications } from 'src/services/notifications/use-notifications';
import { loadScript } from 'src/utils/file.utils';

const { publicRuntimeConfig } = getConfig();

export const useOpenSwitchere = () => {
    const getDefaultWallet = useGetDefaultWallet();
    const createOrder = useCreateOrder();
    const { error } = useNotifications();

    return useCallback(async () => {
        const wallet = getDefaultWallet(WalletProviderE.Concordium);

        if (!wallet?.address) {
            error({
                text: 'Please connect your wallet on Edit profile page',
                timeout: 10000,
            });
            return;
        }
        const order = await createOrder({
            address: wallet.address,
            payoutCurrency: 'CCD',
            payinCurrency: 'EUR',
            successReturnUrl: window.location.pathname,
            failedReturnUrl: window.location.pathname,
        });

        if (!order) {
            return;
        }

        const { id, signature } = order;

        await loadScript(`${publicRuntimeConfig.NEXT_PUBLIC_SWITCHERE_HOST}/js/sdk-builder.js`);

        (window as any).switchereSdk.init({
            canChange: 'payin_currency',
            dstAddress: wallet.address,
            memo: id,
            partnerKey: publicRuntimeConfig.NEXT_PUBLIC_SWITCHERE_PARTNER_KEY,
            partnerOrderId: id,
            payinCurrency: 'EUR',
            payinGroup: 'card',
            payoutCurrency: 'CCD',
            payoutGroup: 'crypto',
            closeButton: true,
            closeButtonCallback: () => {},
            signature,
            mode: 'modal',
        });
    }, [createOrder, error, getDefaultWallet]);
};
