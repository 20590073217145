import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledHeaderNotifications = styled.div`
    position: relative;
    margin: 0 5px 0 0;

    ${media.md`
        margin: 0 10px 0 0;
  `}

    ${media.md`
        & > div:first-child {
          width: 50px;
          height: 50px;
          margin: 0;
    }
  `}
  
    //icon background
    & > div {
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 0;
    }

    & > div:hover {
        background-color: ${({ theme }) => theme.colors.blue};
        border-radius: 0;

        & svg {
            color: ${({ theme }) => theme.colors.white};
        }
    }

    & > div > svg {
        color: ${({ theme }) => theme.colors.black};
    }
`;

export const StyledNotificationFlag = styled.div`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 13px;
    right: 22px;
    background-color: ${({ theme }) => theme.colors.pink500};

    ${media.md`
        top: 17px;
        right: 17px;
  `}
`;
