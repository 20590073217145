import styled from 'styled-components';

export const PageLayout = styled.div<{ fullWidth?: boolean }>`
    flex: 1;
    ${({ fullWidth }) =>
        !fullWidth &&
        `
         max-width: 1200px;
         width: 100%;
         margin: 0 auto;
`}
`;

PageLayout.displayName = 'PageLayout';
