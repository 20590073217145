import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICollectionsListFilter } from '@tacans/shared-marketplace-layer';
import { HYDRATE } from 'next-redux-wrapper';
import { INITIAL_FILTER_STATE } from 'src/features/common/explore/ducks/collections/collections.constants';
import { CollectionsAdapter } from './collections.adapter';
import { fetchCollectionsThunk } from './collections.thunk';
import { CollectionsSort, ICollectionsState } from './collections.types';

const getInitialState = () => CollectionsAdapter.getInitialState<ICollectionsState>(INITIAL_FILTER_STATE);

export const collectionsSlice = createSlice({
    name: 'explore-collections',
    initialState: getInitialState(),
    reducers: {
        clear: () => getInitialState(),
        clearItems: (state) => {
            CollectionsAdapter.removeAll(state);
            state.pages = [];
            state.byPage = {};
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        clearFilter: (state) => {
            state.filter = { ...INITIAL_FILTER_STATE.filter };
            state.pager = { ...INITIAL_FILTER_STATE.pager };
        },
        setFilter: (state, { payload }: PayloadAction<ICollectionsListFilter>) => {
            state.filter = payload;
        },
        updateFilter: (state, { payload }: PayloadAction<ICollectionsListFilter>) => {
            state.filter = {
                ...state.filter,
                ...payload,
            };
        },
        setSorting: (state, { payload }: PayloadAction<CollectionsSort>) => {
            state.sorting = payload;
        },
        setPage: (state, { payload }) => {
            state.pager.page = payload ?? 0;
        },
        setLoading: (state, { payload }) => {
            state.loading = !!payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCollectionsThunk.fulfilled, (state, action) => {
                const { pager, collections = [] } = action.payload || {};
                const total = +(pager?.totalCount || 0);
                const page = +(pager?.page ?? 0);

                const newByPage = { ...state.byPage };
                const newPages = [...new Set([...state.pages, ...state.pages.concat(page)])];

                if (collections.length) {
                    if (!newByPage[page]) {
                        newByPage[page] = [];
                    }
                }

                collections.forEach((item) => {
                    if (!(item.id in state.entities)) {
                        newByPage[page].push(item.id);
                    }
                });

                CollectionsAdapter.addMany(state, collections || []);
                state.total = total;
                state.byPage = newByPage;
                state.pages = newPages;
            })
            .addCase(HYDRATE, (state, action) => ({
                ...state,
                // @ts-ignore
                ...action.payload.features.collections.explore,
            }));
    },
});

export const { actions } = collectionsSlice;

export default collectionsSlice.reducer;
