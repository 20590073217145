import { createAsyncThunk } from '@reduxjs/toolkit';
import { CartApi, IAddArtToCartPayload } from '@tacans/shared-marketplace-layer';

export const fetchCartThunk = createAsyncThunk('cart/fetch', async () => {
    const res = await CartApi.getCart();

    // @ts-ignore
    return res?.data?.cart.items || [];
});

export const addToCartThunk = createAsyncThunk('cart/add', async (data: IAddArtToCartPayload) => {
    const response = await CartApi.addToCart(data);

    return { response };
});