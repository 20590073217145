/**
 * WebSocket connection to our API
 */
import { Client, Message, StompSubscription } from '@stomp/stompjs';

export type StompMessage = Message;

type SubscriptionCallback = (message: Message) => void;

export class StompClient {
    private static client: Promise<Client>;

    private static async createClient(): Promise<Client> {
        return new Promise((resolve, reject) => {
            const client = new Client({
                brokerURL: process.env.NEXT_PUBLIC_WEBSOCKET_URI,
                reconnectDelay: 10 * 1000,
                webSocketFactory: () => new WebSocket(process.env.NEXT_PUBLIC_WEBSOCKET_URI as any)
            });
            client.onConnect = () => resolve(client);
            client.onStompError = () => reject(new Error('Stomp error!'));
            client.onWebSocketError = () => reject(new Error('WebSocket error!'));
            client.activate();
        });
    }

    private static async getClient() : Promise<Client> {
        if (!StompClient.client) {
            StompClient.client = StompClient.createClient();
        }
        return StompClient.client;
    }

    static async subscribe(channel: string, callback: SubscriptionCallback): Promise<StompSubscription> {
        const client = await StompClient.getClient();
        return client?.subscribe?.(channel, callback);
    }
}
