import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { ExploreLaunchpadNftsAdapter } from './explore-launchpad-nfts.adapter';

export const exploreLaunchpadNftsSelect = (state: RootState) => state.features.exploreLaunchpadNfts;

export const exploreLaunchpadNftsSelectors = ExploreLaunchpadNftsAdapter.getSelectors();

export const selectLaunchpadNfts = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => exploreLaunchpadNftsSelectors.selectAll(state)
);

export const selectLaunchpadNftsEntities = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => exploreLaunchpadNftsSelectors.selectEntities(state)
);

export const selectPager = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => state.pager
);

export const selectPages = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => state.pages
);

export const selectByPage = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => state.byPage
);

export const selectTotal = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => state.total
);

export const selectVisibleTotal = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => state.visibleTotal
);

export const selectLoading = createSelector(
    exploreLaunchpadNftsSelect,
    (state) => state.loading
);
