import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/app';
import {
    SearchActions,
    selectSearchKeyword,
} from '../ducks/search';

export const useSetSearchKeyword = () => {
    const dispatch = useAppDispatch();

    return useCallback((keyword: string) => {
        dispatch(SearchActions.setSearchKeyword(keyword));
    }, [dispatch]);
};

export const useResetSearchKeyword = () => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        dispatch(SearchActions.resetSearchKeyword());
    }, [dispatch]);
};

export const useSearchKeyword = () => useAppSelector(selectSearchKeyword);
