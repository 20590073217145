import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { CategoryAdapter } from './category.adapter';

export const categorySelect = (state: RootState) => state.features.category;

export const categorySelectors = CategoryAdapter.getSelectors();

export const selectCategories = createSelector(categorySelect, (state) =>
  categorySelectors.selectAll(state)
);
export const selectCategory = createSelector(
  selectCategories,
  (category) => category[0]
);

export const selectCategoryError = createSelector(
  categorySelect,
  (category) => category.error
);
