import { IUserAddressPayload } from '@tacans/shared-marketplace-layer';
import { useCallback } from 'react';
import { fetchByUserAddressesThunk } from 'src/features/common/profile/ducks/addresses/addresses.thunk';
import { useAppDispatch } from 'src/hooks/app';

export const useAddressesFetch = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (data: IUserAddressPayload) => {
            dispatch(fetchByUserAddressesThunk(data));
        },
        [dispatch]
    );
};