/**
 * Libs
 */
import { WalletProviderE } from '@tacans/shared-marketplace-layer';
import { useMemo } from 'react';

/**
 * Hooks
 */
import { useBlockChains, useWallets } from 'src/features/common/wallet/hooks/wallet.hooks';

export const useDisabledWallets = () => {
    const wallets = useWallets();
    const blockchains = useBlockChains();

    return useMemo<Record<WalletProviderE, boolean>>(
        () =>
            blockchains.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.name as WalletProviderE]: !!wallets.find((w) => w.blockchainId === curr.id),
                }),
                {} as Record<WalletProviderE, boolean>
            ),
        [blockchains, wallets]
    );
};
