import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    UserAPI,
    IUpdateUser,
} from '@tacans/shared-marketplace-layer';

export const profileEditThunk = createAsyncThunk(
    'profile/edit',
    async (data: IUpdateUser) => {
        const response = await UserAPI.updateUserProfile(data);

        return { response };
    }
);

export const updateEmailThunk = createAsyncThunk<
    any,
    { email: string; password: string },
    any
>('profile/change-email', async ({ email, password }, { rejectWithValue }) => {
    const response = await UserAPI.changeUserEmail(email, password);
    const { error } = response.data.responseMeta;
    if (error) {
        return rejectWithValue(error.messages[0]);
    }

    return response;
});

export const changePasswordThunk = createAsyncThunk<
    any,
    { new_password: string; password: string },
    any
    >('profile/change-password', async ({ new_password, password }, { rejectWithValue }) => {
    const response = await UserAPI.changeUserPassword(password, new_password);
    const { error } = response.data.responseMeta;
    if (error) {
        return rejectWithValue(error.messages[0]);
    }

    return response;
});

export const profileFetchThunk = createAsyncThunk(
    'profile/fetch',
    async (_, { rejectWithValue }) => {
        const response = await UserAPI.getUserProfile();
        const { error } = response.data.responseMeta;
        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);

export const profileAvatarChangeThunk = createAsyncThunk(
    'profile/avatar-change',
    async (file: File | Blob, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('file', file);

        const response = await UserAPI.changeUserAvatar(formData);
        const { error } = response.data.responseMeta;

        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);

export const profileAvatarDeleteThunk = createAsyncThunk(
    'profile/avatar-change',
    async (_, { rejectWithValue }) => {

        const response = await UserAPI.removeUserAvatar();
        const { error } = response.data.responseMeta;

        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);

export const profileBannerChangeThunk = createAsyncThunk(
    'profile/banner-change',
    async (file: File | Blob, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('file', file);
        const response = await UserAPI.changeUserBanner(formData);
        const { error } = response.data.responseMeta;
        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);

export const profileBannerDeleteThunk = createAsyncThunk(
    'profile/banner-change',
    async (_, { rejectWithValue }) => {

        const response = await UserAPI.removeUserBanner();
        const { error } = response.data.responseMeta;
        if (error) {
            return rejectWithValue(error.messages[0]);
        }

        return response;
    }
);
