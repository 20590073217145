import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'src/store';
import { NftListAdapter } from './nft-list.adapter';

export const nftListSelect = (state: RootState) => state.global.nftList;

export const nftListSelectors = NftListAdapter.getSelectors();

export const selectNftList = createSelector(
    nftListSelect,
    (state) => nftListSelectors.selectAll(state)
);

export const selectNftListEntities = createSelector(
    nftListSelect,
    (state) => nftListSelectors.selectEntities(state)
);