import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { speed } from 'src/ui/common/config/animations';
import { media } from 'src/ui/common/mixins/media';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.gs400};
    border-radius: 5px;
`;

export const StyledContent = styled.div`
    padding: 15px 15px 30px 15px;
    font-weight: 400;
    font-size: 14px;

    ${media.sm`
    padding: 20px;
    font-size: 16px;
  `}
`;

export const StyledHeading = styled(FlexBox)<{ opened: boolean }>`
    height: 46px;
    padding: 0 15px;
    background-color: ${({ theme }) => theme.colors.gs500};
    border-radius: ${({ opened }) => (opened ? '5px 5px 0 0' : '5px')};
    font-family: ${({ theme }) => theme.fonts.title};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 500;
    font-size: 14px;
    width: 100%;

    ${media.sm`
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
  `}
    & > * {
        width: 100%;
    }
`;

export const SeeMoreIcon = styled(SvgIcon)<{ opened: boolean }>`
    cursor: pointer;
    user-select: none;
    width: 16px;
    height: 16px;
    transition: transform ${speed.content};

    ${media.sm`
    height: 18px;
    width: 18px;
  `}
`;