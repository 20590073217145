import Link from 'next/link';
import React, { HTMLAttributeAnchorTarget } from 'react';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';
import { StyledArrow, StyledNavItem, StyledNavItemWrapper } from './header-user-menu.styles';

interface IProps {
    title: string;
    icon: string;
    iconArrow?: any;
    link?: string;
    external?: boolean;
    target?: HTMLAttributeAnchorTarget;
    onClick?: () => void;
    inverse?: boolean;
}

export const NavItem: React.VFC<IProps> = ({
    onClick,
    iconArrow,
    link,
    title,
    icon,
    external,
    target,
    inverse = false,
}) => {
    let content = (
        <FlexBox alignItems="center">
            <SvgIcon variant="regular" icon={icon as any} width={20} height={20} />
            <StyledNavItem>{title}</StyledNavItem>
            <StyledArrow variant="regular" icon={iconArrow ?? 'chevronRight'} width={16} height={16} />
        </FlexBox>
    );

    if (external) {
        content = (
            <a href={link} target="_blank" rel="noreferrer">
                {content}
            </a>
        );
    }

    if (link) {
        content = (
            <Link href={link} rel="canonical" prefetch={false}>
                <a target={target}>{content}</a>
            </Link>
        );
    }

    return (
        <StyledNavItemWrapper onClick={onClick} inverse={inverse}>
            {content}
        </StyledNavItemWrapper>
    );
};
