import React from 'react';
import styled from 'styled-components';
import { media } from 'src/ui/common/mixins/media';

type ViewT = 'desktop' | 'mobile' | 'laptop';

interface IProps {
    className?: string;
    view: ViewT | ViewT[];
    display?: string;
    fullWidth? : boolean;
}

const Styled = styled.div<{ view: ViewT[], display: IProps['display'] }>`
  display: ${({ view, display }) => view.includes('mobile') ? (display ?? 'block') : 'none'};
  
  ${media.sm`
    display: ${({ view, display }: any) => view.includes('laptop') ? (display ?? 'block') : 'none'};
  `}
  
  ${media.md`
    display: ${({ view, display }: any) => view.includes('desktop') ? (display ?? 'block') : 'none'};
  `}
`;

export const Visible: React.FC<IProps> = ({ display, view, className, children , fullWidth }) => (
    <Styled style={fullWidth ? { width: '100%' }: {}} className={className} display={display} view={typeof view === 'string' ? [view] : view}>
        {children}
    </Styled>
);
