/* eslint-disable jsx-a11y/anchor-is-valid,react/no-danger */

/**
 * Libs
 */
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { RouteE } from 'src/constants/pages.constants';

/**
 * Styles
 */
import { IFooterLinks } from 'src/features/common/app/components/footer/footer.types';
import { FlexBox } from 'src/ui/common/atoms/flex-box';
import { Grid } from 'src/ui/common/atoms/grid';
import { SvgIcon } from 'src/ui/common/atoms/svg-icon';

/**
 * Components
 */
import { StyledFooter, StyledFooterContainer, StyledFooterLink, StyledFooterText, StyledFooterTitle, StyledLinksWrapper } from './footer.styles';


export const Footer = () => {
    const { t } = useTranslation('tech-contemporary');

    const links = useMemo<IFooterLinks[]>(
        () => [
            {
                label: 'navigate',
                links: [
                    {
                        id: 2,
                        label: 'art',
                        url: RouteE.EXPLORE,
                        target: '_self',
                    },
                    {
                        id: 3,
                        label: 'calendar',
                        url: RouteE.CALENDAR,
                        target: '_self',
                    },
                    {
                        id: 4,
                        label: 'about',
                        url: RouteE.ABOUT,
                        target: '_self',
                    },
                    {
                        id: 5,
                        label: 'buyScreen',
                        url: 'https://www.anywall.io/',
                        target: '_blank',
                    },
                ],
            },
            {
                label: 'visitUs',
                links: [
                    {
                        id: 5,
                        label: 'streetOne',
                        url: 'https://www.google.com/maps/place/Tordenskjoldsgade+21,+1055+K%C3%B8benhavn/@55.6777826,12.586882,19z/data=!4m6!3m5!1s0x4652533d70ccfe3d:0xc05ae0ff2b6b986b!8m2!3d55.6778036!4d12.5880165!16s%2Fg%2F11b8v6tb9q?entry=ttu',
                    },
                    {
                        id: 6,
                        label: 'streetTwo',
                        url: 'https://www.google.com/maps/place/Tordenskjoldsgade+21,+1055+K%C3%B8benhavn/@55.6777826,12.586882,19z/data=!4m6!3m5!1s0x4652533d70ccfe3d:0xc05ae0ff2b6b986b!8m2!3d55.6778036!4d12.5880165!16s%2Fg%2F11b8v6tb9q?entry=ttu',
                    },
                    {
                        id: 7,
                        label: 'streetThree',
                        url: 'https://www.google.com/maps/place/Tordenskjoldsgade+21,+1055+K%C3%B8benhavn/@55.6777826,12.586882,19z/data=!4m6!3m5!1s0x4652533d70ccfe3d:0xc05ae0ff2b6b986b!8m2!3d55.6778036!4d12.5880165!16s%2Fg%2F11b8v6tb9q?entry=ttu',
                    },
                    {
                        id: 8,
                        label: 'streetFour',
                        url: 'https://www.google.com/maps/place/Tordenskjoldsgade+21,+1055+K%C3%B8benhavn/@55.6777826,12.586882,19z/data=!4m6!3m5!1s0x4652533d70ccfe3d:0xc05ae0ff2b6b986b!8m2!3d55.6778036!4d12.5880165!16s%2Fg%2F11b8v6tb9q?entry=ttu',
                    },
                ],
            },
            {
                label: 'getInTouch',
                links: [
                    {
                        id: 9,
                        label: 'phone',
                        url: 'tel:+45 31 36 16 12',
                    },
                    {
                        id: 10,
                        label: 'mail',
                        url: 'mailto:emil@techcontemporary.com',
                    },
                ],
            },
            {
                label: 'socialMedia',
                links: [
                    {
                        id: 11,
                        label: 'youtube',
                        url: RouteE.HOME,
                        svg: 'youtubeFilled',
                    },
                    {
                        id: 12,
                        label: 'instagram',
                        url: RouteE.HOME,
                        svg: 'instagram',
                    },
                ],
            },
        ],
        []
    );

    return (
        <StyledFooter>
            <StyledFooterContainer>
                <FlexBox flexDirectionSm="row" flexDirection="column-reverse">
                    <FlexBox flexDirection="column" gap="26" flex="1" alignItemsSm="flex-start" alignItems="center">
                        <Link href="/" prefetch={false}>
                            <a>
                                <img src="/techLogo.svg" alt="logo" />
                            </a>
                        </Link>
                        <StyledFooterText>{t('footer.terms.copyright')}</StyledFooterText>
                        <FlexBox
                            gap="5"
                            flexDirectionSm="row"
                            flexDirection="column"
                            alignItemsSm="flex-start"
                            alignItems="center"
                        >
                            <StyledFooterText>
                                <Link href={RouteE.TERMS}>{t('footer.terms.conditions')}</Link>
                            </StyledFooterText>
                            <StyledFooterText>
                                <Link href={RouteE.PRIVACY}>{t('footer.terms.privacy')}</Link>
                            </StyledFooterText>
                        </FlexBox>
                    </FlexBox>
                    <StyledLinksWrapper flex="1">
                        <Grid xs={2} sm={2} md={4} columnGap={22} columnGapSm={64} rowGap={24}>
                            {links.map(({ label: section, links }) => (
                                <div key={section}>
                                    <StyledFooterTitle>{t(`footer.titles.${section}`)}</StyledFooterTitle>
                                    {/* @ts-ignore */}
                                    {links.map(({ label, url, svg, id, target }) => (
                                        <StyledFooterLink target={target} href={url} key={id}>
                                            {svg && <SvgIcon variant="regular" icon={svg} color="#B2B6B5" />}
                                            {t(`footer.${section}.${label}`)}
                                        </StyledFooterLink>
                                    ))}
                                </div>
                            ))}
                        </Grid>
                    </StyledLinksWrapper>
                </FlexBox>
            </StyledFooterContainer>
        </StyledFooter>
    );
};